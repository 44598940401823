import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { toast } from "react-toastify";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const loginUser = async (email, password, remember) => {
    setIsLoading(true);
    setError(null);

    const response = await fetch(`/api/login`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password, remember, admin: false }),
    });

    const data = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setError(data.msg);
    }
    if (response.ok) {
      localStorage.setItem("user", JSON.stringify(data.user));
      toast.success("Logged in successfully !");
      dispatch({ type: "LOGIN", payload: data });
      setIsLoading(false);
    }
  };

  return { loginUser, isLoading, error };
};
