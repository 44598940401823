import { useState } from "react";

export const useRequestResetPassword = () => {
  const [loadingRequestResetPassword, setLoadingRequestResetPassword] =
    useState(false);
  const [errorRequestResetPassword, setErrorRequestResetPassword] =
    useState(false);
  const [successRequestResetPassword, setSuccessRequestResetPassword] =
    useState(false);

  const requestResetPassword = async (email) => {
    try {
      setLoadingRequestResetPassword(true);
      setErrorRequestResetPassword(null);
      setSuccessRequestResetPassword(null);

      const request = await fetch(`/api/forgot-password/${email}`, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });

      const response = await request.json();

      if (request.ok) {
        setSuccessRequestResetPassword(response.msg);
      } else {
        setErrorRequestResetPassword(response.msg);
      }
      setLoadingRequestResetPassword(false);
    } catch (err) {
      setErrorRequestResetPassword(err.message);
      setLoadingRequestResetPassword(false);
    }
  };

  return {
    requestResetPassword,
    loadingRequestResetPassword,
    errorRequestResetPassword,
    successRequestResetPassword,
  };
};
