import { useState } from "react";

export const useResetPassword = () => {
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const [errorResetPassword, setErrorResetPassword] = useState(null);
  const [successResetPassword, setSuccessResetPassword] = useState(null);

  const resetPassword = async (email, token, password) => {
    try {
      setLoadingResetPassword(true);
      setErrorResetPassword(null);
      setSuccessResetPassword(null);

      const request = await fetch("/api/reset-password", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password, token }),
      });

      const response = await request.json();

      if (request.ok) {
        setSuccessResetPassword(response.msg);
      } else {
        setErrorResetPassword(response.msg);
      }
      setLoadingResetPassword(false);
    } catch (err) {
      setLoadingResetPassword(false);
      setErrorResetPassword(err.message);
    }
  };
  return {
    resetPassword,
    loadingResetPassword,
    errorResetPassword,
    successResetPassword,
  };
};
