import { useState } from "react";

export const useGetCustomSets = () => {
  const [customTests, setCustomTests] = useState([]);
  const [customUserData, setCustomUserData] = useState();
  const [customError, setCustomError] = useState(null);
  const [loadingCustom, setLoadingCustom] = useState(true);

  const getCustomSets = async (category) => {
    setCustomError(null);
    setLoadingCustom(true);

    const response = await fetch(`/api/get_custom_set?category=${category}`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });

    const json = await response.json();

    if (response.ok) {
      setCustomTests(json.customsets);
      setCustomUserData(json.user);
    } else {
      setCustomError(json.msg);
    }

    setLoadingCustom(false);
  };

  return {
    getCustomSets,
    customTests,
    customUserData,
    customError,
    loadingCustom,
    setLoadingCustom,
  };
};
