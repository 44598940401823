import { useState } from "react";

export const useGetNewsletter = () => {
  const [newsletterData, setNewsletterData] = useState(null);
  const [newsletterLoading, setNewsletterLoading] = useState(true);
  const [newsState, setNewsState] = useState(false);
  const [alertsState, setAlertsState] = useState(false);
  const [noticesState, setNoticesState] = useState(false);
  const [newTestsState, setNewTestsState] = useState(false);

  const getNewsletter = async () => {
    const response = await fetch(`/api/get_newsletter`, {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });

    const json = await response.json();

    if (response.ok) {
      if (json.newsletter != null) {
        setNewsletterData(json.newsletter);
        setNewsState(json.newsletter.news);
        setAlertsState(json.newsletter.alerts);
        setNoticesState(json.newsletter.notices);
        setNewTestsState(json.newsletter.newTests);
      }
    }

    setNewsletterLoading(false);
  };

  return {
    getNewsletter,
    newsletterData,
    newsletterLoading,
    setNewsletterLoading,
    newsState,
    setNewsState,
    alertsState,
    setAlertsState,
    noticesState,
    setNoticesState,
    newTestsState,
    setNewTestsState,
  };
};
