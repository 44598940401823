import Slider from "react-slick";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";
import {
  ChevronLeft,
  MoveUpRight,
  ChevronRight,
  ChevronDown,
} from "lucide-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import AuthContext from "../../Contexts/AuthContext";
import Sidebar from "../../Components/Sidebar/Sidebar";
import TestCard from "../../Components/resuable/TestCard";
// import { useGetNotices } from "../../Hooks/useGetNotices";
import ellipseImg from "../../Assets/img/dashboard/e2.png";
import { useGetUserData } from "../../Hooks/useGetUserData";
import characterImg from "../../Assets/img/dashboard/char.png";
import halfEllipseImg from "../../Assets/img/dashboard/e1.png";
import { useDeleteCustomTest } from "../../Hooks/useDeleteCustomTest";
import { useAddBookmarkTest } from "../../Hooks/bookmark/useAddBookmarkTest";
import { useRemoveBookmarkTest } from "../../Hooks/bookmark/useRemoveBookmarkTest";
import Advertisement from "../../Components/Advertisement/Advertisement";
import { LoadingContexts } from "../../Contexts/LoadingContext";

const Dashboard = () => {
  const { getUserData, errorUserData, loadingUserData, userData } =
    useGetUserData();

  // const { getNotices, loadingNotices, errorLoadingNotices, notices } =
  //   useGetNotices();

  const {
    addBookmarkTest,
    loadingAddBookmarkTest,
    errorAddBookmarkTest,
    successAddBookmarkTest,
  } = useAddBookmarkTest();

  const {
    removeBookmarkTest,
    loadingRemoveBookmarkTest,
    errorRemoveBookmarkTest,
    successRemoveBookmarkTest,
  } = useRemoveBookmarkTest();

  const {
    deleteCustomTest,
    loadingDeleteCustomTest,
    errorDeleteCustomTest,
    successDeleteCustomTest,
  } = useDeleteCustomTest();

  const [performanceSelectTab, setPerformanceSelectTab] = useState("score");

  const [isMobileView, setIsMobileView] = useState(false);

  const [activeTestDetailsTab, setActiveTestDetailsTab] = useState("progress");

  const [averageTime, setAverageTime] = useState(0);

  const [averageScore, setAverageScore] = useState(0);

  const [averageTestsCount, setAverageTestsCount] = useState(0);

  const [performanceProgress, setPerformanceProgress] = useState({
    scoreProgress: "positive",
    timeProgress: "positive",
  });

  const { isLoadingSomething, setIsLoadingSomething } =
    useContext(LoadingContexts);

  const { user } = useContext(AuthContext);

  const progressTestsRef = useRef(null);
  const completedTestsRef = useRef(null);
  const bookmarkedTestsRef = useRef(null);

  const toastTriggered = useRef(false);

  const checkRan = useRef(true);

  const location = useLocation();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    adaptiveHeight: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const SliderWrap = styled.div`
    .slick-slider {
      -webkit-user-select: text;
      -khtml-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;

      .slick-list {
        overflow: hidden !important;
        .slick-track {
          display: flex !important;

          .slick-slide {
            height: inherit !important;
            margin-left: 1rem !important;
            margin-right: 1rem !important;
            // border-bottom: 3px solid #e5e9ec !important;
            // border-radius: 18px !important;
          }

          .slick-slide:first-child {
            margin-left: 0rem !important;
            margin-right: ${({ userData }) =>
              userData &&
              userData.activePublicTests.length +
                userData.activeCustomTests.length >
                2
                ? "1rem"
                : "1rem"} !important;
          }

          .slick-slide:last-child {
            margin-left: ${({ userData }) =>
              userData &&
              userData.activePublicTests.length +
                userData.activeCustomTests.length >
                2
                ? "1rem"
                : "1rem"} !important;
            margin-right: 0rem !important;
          }

          .slick-slide > div:first-child,
          .slick-slide > div:first-child > div:first-child {
            height: 100% !important;
          }

          @media only screen and (max-width: 768px) {
            .slick-slide {
              margin-left: 0rem !important;
              margin-right: 0rem !important;
            }
          }
        }
      }
    }
  `;

  const calculateScoreProgress = (userScore, otherUserScore) => {
    if (userScore !== 0 && otherUserScore === 0) {
      return 100;
    }

    if (userScore === 0 || otherUserScore === 0) {
      return 0;
    }

    const scoreDifference = userScore - otherUserScore;
    const scoreProgress = (scoreDifference / otherUserScore) * 100;

    return scoreProgress;
  };

  const calculateTimeProgress = (userTime, otherUserTime) => {
    if (userTime !== 0 && otherUserTime === 0) {
      return 100;
    }

    if (userTime === 0 || otherUserTime === 0) {
      return 0;
    }

    const timeDifference = otherUserTime - userTime;
    const timeProgress = (timeDifference / otherUserTime) * 100;

    return Math.max(-100, Math.min(timeProgress, 100));
  };

  const parseTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);

    const minutes = Math.floor((seconds % 3600) / 60);

    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes}:${remainingSeconds}`;
  };

  const handleNextSlide = () => {
    if (activeTestDetailsTab === "progress")
      progressTestsRef.current.slickNext();
    else if (activeTestDetailsTab === "completed")
      completedTestsRef.current.slickNext();
    else if (activeTestDetailsTab === "bookmarked")
      bookmarkedTestsRef.current.slickNext();
  };

  const handlePrevSlide = () => {
    if (activeTestDetailsTab === "progress")
      progressTestsRef.current.slickPrev();
    else if (activeTestDetailsTab === "completed")
      completedTestsRef.current.slickPrev();
    else if (activeTestDetailsTab === "bookmarked")
      bookmarkedTestsRef.current.slickPrev();
  };

  useEffect(() => {
    if (
      (loadingUserData ||
        loadingAddBookmarkTest ||
        loadingRemoveBookmarkTest ||
        loadingDeleteCustomTest) &&
      !isLoadingSomething
    ) {
      setIsLoadingSomething(true);
    }

    if (
      !loadingUserData &&
      !loadingAddBookmarkTest &&
      !loadingRemoveBookmarkTest &&
      !loadingDeleteCustomTest &&
      isLoadingSomething
    ) {
      setIsLoadingSomething(false);
    }
  }, [
    loadingAddBookmarkTest,
    loadingRemoveBookmarkTest,
    loadingUserData,
    loadingDeleteCustomTest,
  ]);

  useEffect(() => {
    if (successAddBookmarkTest) {
      toast.success(successAddBookmarkTest.msg);
      getUserData();
    }
  }, [successAddBookmarkTest]);

  useEffect(() => {
    if (errorAddBookmarkTest) {
      toast.error(errorAddBookmarkTest);
    }
  }, [errorAddBookmarkTest]);

  useEffect(() => {
    if (successRemoveBookmarkTest) {
      toast.success(successRemoveBookmarkTest);
      getUserData();
    }
  }, [successRemoveBookmarkTest]);

  useEffect(() => {
    if (errorRemoveBookmarkTest) {
      toast.error(errorRemoveBookmarkTest);
    }
  }, [errorRemoveBookmarkTest]);

  useEffect(() => {
    if (successDeleteCustomTest) {
      toast.success(successDeleteCustomTest);
      getUserData();
    }
  }, [successDeleteCustomTest]);

  useEffect(() => {
    if (errorDeleteCustomTest) {
      toast.error(errorDeleteCustomTest);
    }
  }, [errorDeleteCustomTest]);

  useEffect(() => {
    if (userData) {
      setAverageTime(userData.averageTime);
      setAverageScore(userData.averageScore);
      setAverageTestsCount(userData.averageTestsCount);
    }
  }, [userData]);

  useEffect(() => {
    const scoreProgress = calculateScoreProgress(
      averageScore,
      userData && userData.otherAverageScore
    ).toFixed(2);
    const timeProgress = calculateTimeProgress(
      averageTime,
      userData && userData.otherAverageTime
    ).toFixed(2);

    if (scoreProgress < 0) {
      setPerformanceProgress((prev) => {
        return {
          ...prev,
          scoreProgress: "negative",
        };
      });
    } else if (scoreProgress > 0) {
      setPerformanceProgress((prev) => {
        return {
          ...prev,
          scoreProgress: "positive",
        };
      });
    }

    if (timeProgress < 0) {
      setPerformanceProgress((prev) => {
        return {
          ...prev,
          timeProgress: "negative",
        };
      });
    } else if (timeProgress > 0) {
      setPerformanceProgress((prev) => {
        return {
          ...prev,
          timeProgress: "positive",
        };
      });
    }
  }, [averageTime, averageScore]);

  useEffect(() => {
    if (checkRan.current) {
      getUserData();
      // getNotices();
    }
    checkRan.current = false;
  }, []);

  useEffect(() => {
    if (!toastTriggered.current) {
      const toastMsg = searchParams.get("toastMsg");
      const toastType = searchParams.get("toastType")
        ? searchParams.get("toastType")
        : "info";
      if (toastMsg) {
        toast(toastMsg, {
          type: toastType,
        });
        navigate("/dashboard");
      }
      toastTriggered.current = true;
    }
  }, [searchParams]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, []);

  return (
    <div className="flex items-start justify-center w-full pb-[80px] lg:pb-0">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-10 lg:gap-10 py-8">
          <div className="hidden lg:block lg:col-span-3 relative">
            <Sidebar active="dashboard" />
          </div>
          <div className="col-span-1 lg:col-span-6 flex flex-col items-start justify-start gap-10 overflow-y-scroll">
            <div className="bg-primaryGreen flex items-center justify-between rounded-2xl relative overflow-hidden">
              <div className="flex flex-col items-start justify-center pe-10 lg:pe-0 ps-10 py-10">
                <div className="text-gray-800 font-[600] text-[28px]">
                  Welcome! {user.name}
                </div>
                <div className="mt-2 text-gray-800 font-[Poppins] text-[14px] z-[2] font-[500] leading-6">
                  Your Ultimate Mock Test Preparation Platform! Ace Your Exams
                  with Tailored Practice and Expert Insights!
                </div>
                <Link
                  to="/courses"
                  className="z-[2] mt-4 flex items-center justify-start gap-1 text-gray-800 font-[Poppins] font-[500] text-[14px] hover:-translate-y-[1px] transition-all ease-in-out duration-300"
                >
                  <u>Start Learning</u>
                  <MoveUpRight size={14} />
                </Link>
              </div>
              <div className="z-[3]">
                <img
                  src={characterImg}
                  alt="character"
                  className="w-[520px] hidden lg:block "
                />
              </div>
              <div className="absolute bottom-0 right-0 max-w-[200px] z-[1]">
                <img src={halfEllipseImg} alt="" className="w-full h-full" />
              </div>
              <div className="absolute bottom-3 left-0 max-w-[125px] z-[1] opacity-75">
                <img src={ellipseImg} alt="" className="w-full h-full" />
              </div>
            </div>
            <div className="flex flex-col items-start justify-center w-full">
              <div className="text-[#242424] font-[600] text-[20px]">
                Your Test Details
              </div>
              <div className="mt-4 flex flex-col lg:flex-row items-start lg:items-center justify-between w-full gap-4 lg:gap-0">
                <div className="flex items-start justify-center gap-6">
                  <button
                    onClick={() => setActiveTestDetailsTab("progress")}
                    className={`text-[#3A3A3A] font-[Poppins] text-[15px] border-b-[3px] border-b-transparent py-1 ${
                      activeTestDetailsTab === "progress" &&
                      "!border-b-primaryBlue"
                    }`}
                  >
                    In Progress
                  </button>
                  <button
                    onClick={() => setActiveTestDetailsTab("completed")}
                    className={`text-[#3A3A3A] font-[Poppins] text-[15px] border-b-[3px] border-b-transparent py-1 ${
                      activeTestDetailsTab === "completed" &&
                      "!border-b-primaryBlue"
                    }`}
                  >
                    Completed
                  </button>
                  <button
                    onClick={() => setActiveTestDetailsTab("bookmarked")}
                    className={`text-[#3A3A3A] font-[Poppins] text-[15px] border-b-[3px] border-b-transparent py-1 ${
                      activeTestDetailsTab === "bookmarked" &&
                      "!border-b-primaryBlue"
                    }`}
                  >
                    Bookmarked
                  </button>
                </div>
                <div className="flex items-start justify-center gap-4">
                  <button
                    onClick={() => handlePrevSlide()}
                    className="border-b-2 border-primaryDarker rounded-[14px] hover:border-b-0 transition-all ease-in-out duration-300"
                  >
                    <div className="p-1 border-2 border-primaryDark rounded-xl">
                      <ChevronLeft />
                    </div>
                  </button>
                  <button
                    onClick={() => handleNextSlide()}
                    className="border-b-2 border-primaryDarker rounded-[14px] hover:border-b-0 transition-all ease-in-out duration-300"
                  >
                    <div className="p-1 border-2 border-primaryDark rounded-xl">
                      <ChevronRight />
                    </div>
                  </button>
                </div>
              </div>
              <div className="w-full mt-8">
                {activeTestDetailsTab === "progress" && (
                  <motion.div
                    initial={{ y: 300 }}
                    animate={{ y: 0 }}
                    transition={{ duration: 0.25 }}
                  >
                    <SliderWrap userData={userData}>
                      <Slider {...settings} ref={progressTestsRef}>
                        {userData &&
                          userData.activeCustomTests.length !== 0 &&
                          userData.activeCustomTests.map((data, index) => {
                            if (
                              data.currentTime !== 0 ||
                              data.currentScore !== 0 ||
                              data.currentQuestion !== 0
                            ) {
                              return (
                                <TestCard
                                  testData={data}
                                  userData={userData}
                                  testType="custom"
                                  addBookmarkTest={addBookmarkTest}
                                  removeBookmarkTest={removeBookmarkTest}
                                  loadingAddBookmarkTest={
                                    loadingAddBookmarkTest
                                  }
                                  loadingRemoveBookmarkTest={
                                    loadingRemoveBookmarkTest
                                  }
                                  deleteCustomTest={deleteCustomTest}
                                  loadingDeleteCustomTest={
                                    loadingDeleteCustomTest
                                  }
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                        {userData &&
                          userData.activePublicTests.length !== 0 &&
                          userData.activePublicTests.map((data, index) => {
                            if (
                              data.currentTime !== 0 ||
                              data.currentScore !== 0 ||
                              data.currentQuestion !== 0
                            ) {
                              return (
                                <TestCard
                                  testData={data}
                                  userData={userData}
                                  addBookmarkTest={addBookmarkTest}
                                  removeBookmarkTest={removeBookmarkTest}
                                  loadingAddBookmarkTest={
                                    loadingAddBookmarkTest
                                  }
                                  loadingRemoveBookmarkTest={
                                    loadingRemoveBookmarkTest
                                  }
                                  testType={"public"}
                                  deleteCustomTest={deleteCustomTest}
                                  loadingDeleteCustomTest={
                                    loadingDeleteCustomTest
                                  }
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                        {userData &&
                          userData.activeCustomTests.filter(
                            (el) =>
                              el.currentScore !== 0 ||
                              el.currentQuestion !== 0 ||
                              el.currentTime !== 0
                          ).length +
                            userData.activePublicTests.filter(
                              (el) =>
                                el.currentScore !== 0 ||
                                el.currentQuestion !== 0 ||
                                el.currentTime !== 0
                            ).length ===
                            1 &&
                          !isMobileView && <div></div>}
                      </Slider>
                    </SliderWrap>
                    {userData &&
                      userData.activePublicTests.filter(
                        (el) =>
                          el.currentScore !== 0 ||
                          el.currentQuestion !== 0 ||
                          el.currentTime !== 0
                      ).length +
                        userData.activeCustomTests.filter(
                          (el) =>
                            el.currentScore !== 0 ||
                            el.currentQuestion !== 0 ||
                            el.currentTime !== 0
                        ).length ===
                        0 && (
                        <div className="text-[15px] text-gray-500">
                          No incomplete tests found,{" "}
                          <Link
                            to="/courses"
                            className="text-primaryDarker text-[14px] underline"
                          >
                            start taking tests !
                          </Link>
                        </div>
                      )}
                  </motion.div>
                )}
                {activeTestDetailsTab === "bookmarked" && (
                  <motion.div
                    initial={{ y: 300 }}
                    animate={{ y: 0 }}
                    transition={{ duration: 0.25 }}
                  >
                    <SliderWrap userData={userData}>
                      <Slider {...settings} ref={bookmarkedTestsRef}>
                        {userData &&
                          userData.bookmarkedTests.length !== 0 &&
                          userData.bookmarkedTests.map((data, index) => (
                            <TestCard
                              testData={data}
                              userData={userData}
                              testType={
                                data.testType === "publicset"
                                  ? "public"
                                  : "custom"
                              }
                              addBookmarkTest={addBookmarkTest}
                              removeBookmarkTest={removeBookmarkTest}
                              loadingAddBookmarkTest={loadingAddBookmarkTest}
                              loadingRemoveBookmarkTest={
                                loadingRemoveBookmarkTest
                              }
                              deleteCustomTest={deleteCustomTest}
                              loadingDeleteCustomTest={loadingDeleteCustomTest}
                            />
                          ))}
                        {userData &&
                          userData.bookmarkedTests.length === 1 &&
                          !isMobileView && <div></div>}
                      </Slider>
                    </SliderWrap>
                    {userData && userData.bookmarkedTests.length === 0 && (
                      <div className="text-[15px] text-gray-500">
                        No bookmarked tests found,{" "}
                        <Link
                          to="/courses"
                          className="text-primaryDarker text-[14px] underline"
                        >
                          browse available tests !
                        </Link>
                      </div>
                    )}
                  </motion.div>
                )}
                {activeTestDetailsTab === "completed" && (
                  <motion.div
                    initial={{ y: 300 }}
                    animate={{ y: 0 }}
                    transition={{ duration: 0.25 }}
                  >
                    <SliderWrap userData={userData}>
                      <Slider {...settings} ref={completedTestsRef}>
                        {userData &&
                          userData.activePublicTests.length !== 0 &&
                          userData.activePublicTests.map((data, index) => {
                            if (data.selectedAnswers.finished === true)
                              return (
                                <TestCard
                                  testData={data}
                                  userData={userData}
                                  testType={"public"}
                                  addBookmarkTest={addBookmarkTest}
                                  removeBookmarkTest={removeBookmarkTest}
                                  loadingAddBookmarkTest={
                                    loadingAddBookmarkTest
                                  }
                                  loadingRemoveBookmarkTest={
                                    loadingRemoveBookmarkTest
                                  }
                                  deleteCustomTest={deleteCustomTest}
                                  loadingDeleteCustomTest={
                                    loadingDeleteCustomTest
                                  }
                                />
                              );
                          })}
                        {userData &&
                          userData.activeCustomTests.length !== 0 &&
                          userData.activeCustomTests.map((data, index) => {
                            if (data.selectedAnswers.finished === true)
                              return (
                                <TestCard
                                  testData={data}
                                  userData={userData}
                                  testType={"custom"}
                                  addBookmarkTest={addBookmarkTest}
                                  removeBookmarkTest={removeBookmarkTest}
                                  loadingAddBookmarkTest={
                                    loadingAddBookmarkTest
                                  }
                                  loadingRemoveBookmarkTest={
                                    loadingRemoveBookmarkTest
                                  }
                                  deleteCustomTest={deleteCustomTest}
                                  loadingDeleteCustomTest={
                                    loadingDeleteCustomTest
                                  }
                                />
                              );
                          })}
                        {userData &&
                          userData.activePublicTests.filter(
                            (el) => el.selectedAnswers.finished === true
                          ).length +
                            userData.activeCustomTests.filter(
                              (el) => el.selectedAnswers.finished === true
                            ).length ===
                            1 &&
                          !isMobileView && <div></div>}
                      </Slider>
                    </SliderWrap>
                    {userData &&
                      userData.activePublicTests.filter(
                        (el) => el.selectedAnswers.finished === true
                      ).length +
                        userData.activeCustomTests.filter(
                          (el) => el.selectedAnswers.finished === true
                        ).length ===
                        0 && (
                        <div className="text-[15px] text-gray-500">
                          No completed tests found,{" "}
                          <Link
                            to="/courses"
                            className="text-primaryDarker text-[14px] underline"
                          >
                            start taking tests !
                          </Link>
                        </div>
                      )}
                  </motion.div>
                )}
              </div>
            </div>
          </div>
          <div className="col-span-1 lg:col-span-3 flex flex-col items-start justify-start w-full gap-10">
            {user && user.type === "free" && <Advertisement />}
            <div className="border-b-[2px] border-b-primaryLightest rounded-[20px]">
              <div className="border-2 border-primaryLightest rounded-2xl p-5 flex flex-col w-full items-start justify-between min-h-[226px]">
                <div className="flex items-center justify-between w-full">
                  <div className="text-[#242424] font-[500] text-[24px]">
                    Your Overall Performance
                  </div>
                  <div className="border-2 border-primaryLightest rounded-xl text-[14px] font-[500] outline-none relative">
                    <select
                      className="border-none outline-none ps-3 py-2 pe-6 bg-transparent"
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "none",
                        appearance: "none",
                      }}
                      defaultValue={performanceSelectTab}
                      onChange={(e) => {
                        setPerformanceSelectTab(e.target.value);
                      }}
                    >
                      <option value={"score"}>Score</option>
                      <option value={"time"}>Time</option>
                    </select>
                    <ChevronDown
                      size={16}
                      className="absolute right-1 top-[50%] -translate-y-[50%]"
                    />
                  </div>
                </div>
                <div className="mt-4 bg-[#F3F3F3] rounded-[8px] w-full py-4 grid grid-cols-3">
                  <div className="flex flex-col items-center justify-start gap-2">
                    <div className="text-[#7A7A7A] font-[Poppins] font-[500] text-[12px]">
                      Total Tests
                    </div>
                    <div className="text-[#191919] font-[600] text-[16px] font-[Poppins]">
                      {averageTestsCount}
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-start gap-2">
                    <div className="text-[#7A7A7A] font-[Poppins] font-[500] text-[12px] capitalize">
                      Avg {performanceSelectTab}
                    </div>
                    <div className="text-[#191919] font-[600] text-[16px] font-[Poppins]">
                      {performanceSelectTab === "score" ? (
                        <>{Number(averageScore).toFixed(2)} pts</>
                      ) : (
                        <>{parseTime(Math.trunc(parseInt(averageTime)))} hr/s</>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-start gap-2">
                    <div className="text-[#7A7A7A] font-[Poppins] font-[500] text-[12px]">
                      Progress
                    </div>
                    <div
                      className={`${
                        performanceSelectTab === "score"
                          ? performanceProgress.scoreProgress === "positive"
                            ? "text-green-500"
                            : "text-red-500"
                          : performanceProgress.timeProgress === "positive"
                          ? "text-green-500"
                          : "text-red-500"
                      } text-[#191919] font-[600] text-[16px] font-[Poppins] flex flex-col items-center justify-center w-full`}
                    >
                      {performanceSelectTab === "score" ? (
                        <>
                          {calculateScoreProgress(
                            averageScore,
                            userData && userData.otherAverageScore
                          ).toFixed(2)}
                          %
                        </>
                      ) : (
                        <>
                          {calculateTimeProgress(
                            averageTime,
                            userData && userData.otherAverageTime
                          ).toFixed(2)}
                          %
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <span className="text-[12px] font-[400] text-black w-full text-center mt-4 font-[Poppins]">
                  Other users average {performanceSelectTab} is{" "}
                  <span className="font-[600]">
                    {performanceSelectTab === "score"
                      ? Number(userData && userData.otherAverageScore).toFixed(
                          2
                        ) + " pts"
                      : parseTime(
                          Math.trunc(userData && userData.otherAverageTime)
                        ) + " hr/s"}
                  </span>
                </span>
              </div>
            </div>
            {user && user.type === "free" && (
              <div className="border-b-[2px] border-b-primaryLightest rounded-[20px] w-full">
                <div className="border-2 border-primaryLightest rounded-2xl p-5 w-full">
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="bg-gradient-to-r from-[#268AFF] to-[#FC55FF] w-fit px-3 py-[6px] text-white font-[600] font-[Poppins] text-[13px] rounded-lg flex items-center justify-center">
                        <span className="pt-[2px]">Go Premium</span>
                      </div>
                      <div className="text-[#242424] text-[18px] font-[600] mt-6 lg:max-w-[300px]">
                        Maximize Your Study Potential!
                      </div>
                      <div className="text-[#3A3A3A] font-[Poppins] font-[400] text-[14px] mt-4 lg:max-w-[300px] leading-6">
                        Access to unlimited tests, detailed result analytics,
                        and exclusive content awaits you !
                      </div>
                    </div>
                  </div>
                  <Link
                    to="/purchase-subscription"
                    state={{
                      previousLocation: location,
                    }}
                    className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-6"
                  >
                    Upgrade Account
                  </Link>
                </div>
              </div>
            )}
            {user && user.type === "paid" && (
              <div className="border-b-[2px] border-b-primaryLightest rounded-[20px] w-full">
                <div className="border-2 border-primaryLightest rounded-2xl p-5 w-full">
                  <div className="flex flex-col items-center justify-between">
                    <div>
                      <div className="bg-gradient-to-r from-[#268AFF] to-[#FC55FF] w-fit px-3 py-[6px] text-white font-[600] font-[Poppins] text-[13px] rounded-lg flex items-center justify-center">
                        <span className="pt-[2px]">Premium Active</span>
                      </div>
                      <div className="text-[#242424] text-[18px] font-[600] mt-6 lg:max-w-[300px]">
                        Enjoy Exclusive Benefits !
                      </div>
                      <div className="text-[#3A3A3A] font-[Poppins] font-[400] text-[14px] mt-4 lg:max-w-[300px] leading-6">
                        You have access to unlimited tests, detailed result
                        analytics, and exclusive content !
                      </div>
                    </div>
                  </div>
                  <Link
                    to="/courses"
                    className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-6"
                  >
                    Explore Courses
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
