import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { useRegister } from "../../Hooks/useRegister";
import { toast } from "react-toastify";
import { Eye, EyeOff } from "lucide-react";

import loginBg from "../../Assets/img/auth/lbg.png";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [validationError, setValidationError] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);

  const { registerUser, isLoadingRegister, errorRegister, registerMessage } =
    useRegister();

  useEffect(() => {
    if (errorRegister) {
      toast.error(errorRegister);
    }
  }, [errorRegister]);

  useEffect(() => {
    if (validationError) {
      toast.error(validationError);
    }
  }, [validationError]);

  useEffect(() => {
    if (registerMessage) {
      toast.info(registerMessage);

      setName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setPhone("");
    }
  }, [registerMessage]);

  const handleRegister = (e) => {
    e.preventDefault();

    setValidationError(null);

    if (!validateName(name.trim())) {
      setValidationError("Please enter a valid name !");
      return;
    }

    if (!validateEmail(email.trim())) {
      setValidationError("Please enter a valid email address !");
      return;
    }

    if (!validatePhone(phone.trim())) {
      setValidationError("Please enter a valid phone number !");
      return;
    }

    if (password === "" || confirmPassword === "") {
      setValidationError("Please enter a password !");
      return;
    }

    if (password !== confirmPassword) {
      setValidationError("Passwords do not match !");
      return;
    }

    if (!validatePassword(password)) {
      setValidationError("Passwords must be minimum 8 characters !");
      return;
    }

    if (!agreedTerms) {
      setValidationError("Please agree to terms & conditions !");
      return;
    }

    registerUser(name, email, password, phone);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePhone = (phone) => {
    return String(phone).match(/^(97|98)\d{8}$/);
  };

  const validatePassword = (password) => {
    return String(password).match(/^.{8,}$/);
  };

  const validateName = (name) => {
    return String(name).match(/^[a-zA-Z]+(?: [a-zA-Z]+)+(?: [a-zA-Z]+)?$/);
  };

  return (
    <div className="min-h-screen flex items-center justify-center w-full">
      <div className="container w-full">
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6 w-full">
          <div className="flex flex-col items-center justify-center w-full py-20">
            <Link
              to="/"
              className="flex flex-col items-start justify-center leading-7"
            >
              <div className="text-[40px] font-[600] text-primaryBlue">
                mero
                <span className="text-primaryDark">
                  test<span className="text-primaryGreen">s.</span>
                </span>
                <span className="px-[6px] py-[2px] text-[10px] bg-gradient-to-r from-primaryBlue to-primaryGreen font-[Poppins] text-white rounded-md ms-1">
                  BETA
                </span>
              </div>
              <div className="font-[Poppins] text-[10.5px] font-[500]">
                from{" "}
                <span className="bg-gradient-to-l from-[#48bdc3] to-[#0e62ac] text-white px-1 py-[2px] font-[600] rounded-[2px]">
                Binary Entrance
                </span>
              </div>
            </Link>
            <form
              onSubmit={handleRegister}
              className="w-full flex flex-col items-center justify-center font-[Poppins]"
            >
              <div className="w-full lg:w-[75%] mt-10 relative">
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className="w-full border-2 border-primaryLightest rounded-xl px-4 pt-[14px] pb-[14px] bg-transparent outline-none focus:border-primaryLight hover:border-primaryLighter transition-all ease-in-out duration-300"
                  placeholder="Full Name"
                />
              </div>
              <div className="w-full lg:w-[75%] mt-8 relative">
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="w-full border-2 border-primaryLightest rounded-xl px-4 pt-[14px] pb-[14px] bg-transparent outline-none focus:border-primaryLight hover:border-primaryLighter transition-all ease-in-out duration-300"
                  placeholder="Email Address"
                />
              </div>
              <div className="w-full lg:w-[75%] mt-8 relative">
                <input
                  type={passwordVisible ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  className="w-full border-2 border-primaryLightest rounded-xl px-4 pt-[14px] pb-[14px] bg-transparent outline-none focus:border-primaryLight hover:border-primaryLighter transition-all ease-in-out duration-300"
                  placeholder="Password"
                />
                <div
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  className="absolute right-4 top-[50%] -translate-y-[50%] cursor-pointer"
                >
                  {passwordVisible ? (
                    <EyeOff color="#7a8b94" />
                  ) : (
                    <Eye color="#7a8b94" />
                  )}
                </div>
              </div>
              <div className="w-full lg:w-[75%] mt-8 relative">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  className="w-full border-2 border-primaryLightest rounded-xl px-4 pt-[14px] pb-[14px] bg-transparent outline-none focus:border-primaryLight hover:border-primaryLighter transition-all ease-in-out duration-300"
                  placeholder="Confirm Password"
                />
                <div
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }
                  className="absolute right-4 top-[50%] -translate-y-[50%] cursor-pointer"
                >
                  {confirmPasswordVisible ? (
                    <EyeOff color="#7a8b94" />
                  ) : (
                    <Eye color="#7a8b94" />
                  )}
                </div>
              </div>
              <div className="w-full lg:w-[75%] mt-8 relative">
                <input
                  type="number"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  className="w-full border-2 border-primaryLightest rounded-xl px-4 pt-[14px] pb-[14px] bg-transparent outline-none focus:border-primaryLight hover:border-primaryLighter transition-all ease-in-out duration-300"
                  placeholder="Phone Number"
                />
              </div>
              <div className="mt-4 text-[15px] text-gray-500 w-full lg:w-[75%] flex items-center justify-start gap-2 ps-1">
                <input
                  type="checkbox"
                  className="w-[15px] h-[15px] accent-primaryBlue"
                  defaultChecked={agreedTerms}
                  onChange={(e) => setAgreedTerms(e.target.value)}
                />
                <span className="pt-1">
                  I agree to your terms and privacy policy.
                </span>
              </div>
              <div className="w-full lg:w-[75%] mt-8 relative">
                <button
                  disabled={isLoadingRegister}
                  className="bg-primaryBlue text-white px-5 py-[14px] rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter disabled:text-white hover:border-b-[0px] transition-all ease-in-out duration-300 text-[15px] font-[600] flex items-center justify-center gap-3 w-full"
                >
                  Register Now
                </button>
              </div>
            </form>
            <div className="w-full lg:w-[75%] mt-4 relative">
              <div className="text-[#7A7A7A] font-[400] font-[Poppins] text-center w-full">
                Already have an account ?{" "}
                <Link to="/login" className="font-[600] text-[#1A1A1A]">
                  Sign In
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`hidden lg:flex flex-col bg-blue-500 min-h-[80vh] rounded-2xl bg-cover bg-no-repeat items-start justify-start pl-10 pt-8`}
            style={{ backgroundImage: `url(${loginBg})` }}
          >
            <div className="font-[600] text-[42px] max-w-[375px] leading-[54px]">
              Sharpen your{" "}
              <span className="text-primaryBlue">skills with us !</span>
            </div>
            <div className="max-w-[400px] font-[Poppins] font-[500] text-[#3A3A3A] text-[18px] mt-2">
              Join today and start your journey to master exams with confidence
              and achieve your goals!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
