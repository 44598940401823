import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useVerify } from "../../Hooks/useVerify";
import successLogo from "../../Assets/img/done.png";
import errorLogo from "../../Assets/img/error.png";
import Modal from "react-modal";
import { X } from "lucide-react";

const Verify = () => {
  const effectRan = useRef(false);
  const { VerifyUser, errorVerifyData, verifyData } = useVerify();
  const { id, token } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (effectRan.current === false) {
      VerifyUser(id, token);
      effectRan.current = true;
    }
  }, [id, token, VerifyUser]);

  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: "1050",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-40%",
      transform: "translate(-50%, -50%)",
      border: "none",
      borderRadius: "16px",
      padding: "0px",
      maxWidth: "500px",
      overflow: "hidden",
      width: "85%",
    },
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Modal
      isOpen={true}
      style={modalStyles}
      closeTimeoutMS={250}
      onRequestClose={() => navigate("/")}
    >
      <div className="p-6 w-full flex flex-col items-center justify-center max-h-[90vh] overflow-scroll">
        {verifyData && (
          <div className="flex flex-col items-center justify-center w-full relative">
            <button
              onClick={() => {
                navigate("/");
              }}
              className="h-[30px] w-[30px] rounded-[50%] bg-primaryLightest flex items-center justify-center absolute top-0 right-0"
            >
              <X />
            </button>
            <img
              src={successLogo}
              alt="success"
              className="max-w-[150px] ms-4"
            />
            <div className="h-[1px] bg-primaryLighter w-full my-6"></div>
            <div className="text-[#08BD80] font-[600] text-[32px]">
              Success !
            </div>
            <div className="text-[#3A3A3A] text-center w-full mt-3 leading-7">
              Welcome aboard! You're all set to start your journey towards
              mastering your exams with us.
            </div>
            <button
              onClick={() => navigate("/")}
              className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-6"
            >
              Continue
            </button>
          </div>
        )}
        {errorVerifyData && (
          <div className="flex flex-col items-center justify-center w-full relative">
            <button
              onClick={() => {
                navigate("/");
              }}
              className="h-[30px] w-[30px] rounded-[50%] bg-primaryLightest flex items-center justify-center absolute top-0 right-0"
            >
              <X />
            </button>
            <img src={errorLogo} alt="success" className="max-w-[125px]" />
            <div className="h-[1px] bg-primaryLighter w-full my-6"></div>
            <div className="text-[#BD0808] font-[600] text-[32px]">Error !</div>
            <div className="text-[#3A3A3A] text-center w-full mt-3 leading-7">
              Oops! It looks like the link you tried is either broken or has
              expired. Please recheck the link and try again.
            </div>
            <button
              onClick={() => navigate("/")}
              className="bg-red-600 text-white px-5 py-3 rounded-2xl border-b-[4px] border-b-red-300 disabled:bg-red-300 disabled:border-b-red-200 hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-6"
            >
              Continue
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Verify;
