import { useState } from "react";

export const useGetUserData = () => {
  const [userData, setUserData] = useState();
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [errorUserData, setErrorUserData] = useState(null);

  const getUserData = async () => {
    try {
      setErrorUserData(null);
      setLoadingUserData(true);

      const response = await fetch(`/api/get_user_data`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });

      const json = await response.json();

      if (response.ok) {
        setUserData(json.user);
      } else {
        setErrorUserData(json.msg);
      }

      setLoadingUserData(false);
    } catch (err) {
      setErrorUserData(err.message);
    }
  };

  return {
    getUserData,
    userData,
    loadingUserData,
    errorUserData,
  };
};
