import { useAuthContext } from "./useAuthContext";

export const useValidate = () => {
  const { dispatch } = useAuthContext();

  const validateUser = async () => {
    const response = await fetch(`/api/validate`, {
      method: "POST",
      credentials: 'include',
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      localStorage.removeItem("user");
      dispatch({ type: "VALIDATE", payload: { user: null } });
    }

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem("user", JSON.stringify(data.user));
      dispatch({ type: "VALIDATE", payload: data });
    }
  };
  return { validateUser };
};
