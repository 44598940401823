import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ChevronLeft, Dot, MoveRight, MoveLeft, Check, X } from "lucide-react";
import ProgressBar from "@ramonak/react-progress-bar";
import { baseUrl } from "../../Utils/BaseUrl";

import { useGetResult } from "../../Hooks/useGetResult";

const Result = ({ type }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [range, setRange] = useState({
    startIndex: 0,
    endIndex: 20,
  });

  function checkRange(currentQuestion) {
    let startIndex, endIndex;
    if (currentQuestion < 20) {
      startIndex = 0;
      endIndex = 20;
    } else if (currentQuestion < 40) {
      startIndex = 20;
      endIndex = 40;
    } else if (currentQuestion < 60) {
      startIndex = 40;
      endIndex = 60;
    } else if (currentQuestion < 80) {
      startIndex = 60;
      endIndex = 80;
    } else if (currentQuestion < 100) {
      startIndex = 80;
      endIndex = 100;
    }
    return { startIndex, endIndex };
  }

  useEffect(() => {
    const { startIndex, endIndex } = checkRange(currentQuestion);
    setRange({ startIndex, endIndex });
  }, [currentQuestion]);

  const { getResult, loadingResult, errorResult, resultData } = useGetResult();

  useEffect(() => {
    getResult(id, type);
  }, []);

  const isSelectedAnswer = (answerId) => {
    const userAnswers = resultData.result.answers.map(
      (answer) => answer.answer
    );
    return userAnswers.includes(answerId);
  };

  const isQuestionAnswered = (questionId) => {
    const userAnswers = resultData.result.answers.map(
      (answer) => answer.question
    );
    return userAnswers.includes(questionId);
  };

  useEffect(() => {
    if (resultData) {
      if (typeof window?.MathJax !== "undefined") {
        window?.MathJax.typesetClear();
        window?.MathJax.typeset();
      }
    }
  }, [resultData, currentQuestion]);

  const handleKeyPress = useCallback(
    (e) => {
      console.log(e.key);
      if (e.key === "p") {
        if (currentQuestion > 0) {
          setCurrentQuestion(currentQuestion - 1);
        }
      } else if (e.key === "n") {
        if (currentQuestion < resultData.testData.questions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
        } else {
          navigate(`/course/${resultData.testData.category.name}`);
        }
      }
    },
    [currentQuestion, resultData]
  );

  const keyEventRef = useRef(null);

  useEffect(() => {
    if (keyEventRef.current) {
      window.removeEventListener("keypress", keyEventRef.current);
    }

    if (resultData) {
      window.addEventListener("keypress", handleKeyPress);
    }

    keyEventRef.current = handleKeyPress;

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [resultData, handleKeyPress]);

  return (
    <div>
      {!loadingResult && !errorResult && resultData && (
        <div className="min-h-screen flex flex-col items-center justify-between">
          <div className="h-full flex flex-col items-center justify-evenly flex-grow w-full">
            <div className="container w-full mt-10 lg:mt-12 max-w-[1300px]">
              <div className="font-[Poppins] text-primaryGreen font-[600] text-[14px] text-center w-full mb-2">
                Viewing question no. {currentQuestion + 1} out of{" "}
                {resultData.testData.questions.length}
              </div>
              <ProgressBar
                completed={
                  ((currentQuestion + 1) /
                    resultData.testData.questions.length) *
                  100
                }
                height="16px"
                bgColor="#08BD80"
                isLabelVisible={false}
              />
            </div>
            <div className="container grid grid-cols-5 w-full py-10 lg:py-12 gap-10 lg:gap-0 max-w-[1300px]">
              <div className="flex flex-col items-start justify-center col-span-5 lg:col-span-3">
                <button
                  onClick={() => navigate("/dashboard")}
                  className="text-[#7A7A7A] font-[400] font-[Poppins] text-[14px] flex items-center justify-center gap-1 hover:text-primaryDark transition-all ease-in-out duration-300 group"
                >
                  <ChevronLeft color="#7A7A7A" size={20} strokeWidth={1} />
                  Return To Dashboard{" "}
                </button>
                <div className="border-b-[4px] border-b-primaryLighter rounded-[18px] mt-8">
                  <div className="text-[14px] font-[500] capitalize bg-primaryLight text-[#FFFFFF] px-4 py-[6px] rounded-2xl">
                    {resultData.testData?.questions[
                      currentQuestion
                    ].linkedSubject?.name
                      .split("-")
                      .splice(1)
                      .join(" ")}
                  </div>
                </div>
                <div className="text-[#242424] font-[600] text-[24px] mt-4 max-w-[650px]">
                  {resultData.testData.questions[currentQuestion].questionTitle}
                </div>
                {resultData.testData.questions[currentQuestion].questionDiagram && (
                <div className="max-h-[200px] overflow-hidden my-3 w-full lg:max-w-[80%] flex items-center justify-start">
                  <img
                    src={`${baseUrl}/question/${resultData.testData.questions[currentQuestion].questionDiagram}`}
                    alt="question"
                    className="w-auto h-auto object-contain object-left"
                  />
                </div>
              )}
                <div className={`mt-2 text-[14px]`}>
                  ( the result has been highlighted below )
                </div>
                <div className="flex flex-col items-start justify-center gap-5 mt-8 w-full">
                  {resultData.testData.questions[
                    currentQuestion
                  ].answerOptions.map((data, index) => (
                    <div
                      key={data._id}
                      className={`${
                        data.isCorrect && isSelectedAnswer(data._id)
                          ? "!border-b-blueLight"
                          : data.isCorrect
                          ? "!border-b-blueLight"
                          : isSelectedAnswer(data._id)
                          ? "!border-b-red-300"
                          : ""
                      } group w-full border-b-[4px] border-b-primaryLightest rounded-[20px] transition-all ease-in-out duration-200 focus-within:border-b-[0px] min-w-full lg:min-w-[500px] lg:max-w-[500px]`}
                    >
                      <button
                        id={`answer-${index + 1}`}
                        className={`w-full border-[2px] border-primaryLighter px-3 py-[10px] rounded-2xl font-[Poppins] text-[14px] font-[500] flex items-center justify-start gap-3 group transition-all ease-in-out duration-300 min-w-[200px] text-start ${
                          data.isCorrect && isSelectedAnswer(data._id)
                            ? "text-primaryBlue !border-primaryBlue !bg-[rgba(2,103,255,0.05)]"
                            : data.isCorrect
                            ? "text-primaryBlue !border-primaryBlue !bg-[rgba(2,103,255,0.05)]"
                            : isSelectedAnswer(data._id)
                            ? "!border-red-600 text-red-600 !bg-[rgba(220,38,38,0.05)]"
                            : ""
                        }`}
                        disabled={true}
                      >
                        <span
                          className={`border-[1.5px] border-primaryLighter text-primaryLight px-3 py-1 rounded-xl font-[Poppins] flex items-center justify-center gap-3 text-[12px] transition-all ease-in-out duration-300 ${
                            data.isCorrect && isSelectedAnswer(data._id)
                              ? "!text-primaryBlue !border-primaryBlue"
                              : data.isCorrect
                              ? "!text-primaryBlue !border-primaryBlue"
                              : isSelectedAnswer(data._id)
                              ? "!text-red-600 !border-red-600"
                              : ""
                          }`}
                        >
                          {index + 1}
                        </span>
                        {data.answer}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="border-b-[4px] border-b-primaryLightest col-span-5 lg:col-span-2 w-full rounded-[20px] flex items-end justify-center">
                <div className="p-10 rounded-2xl border-primaryLighter border-[2px] w-full flex flex-col items-start justify-start gap-6 h-fit">
                  <div className="flex flex-col items-start justify-start gap-[6px]">
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[500]">
                      Test Name:
                    </div>
                    <div className="text-[#242424] font-[600] text-[16px]">
                      {resultData.testData.title}
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-[6px]">
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[500]">
                      Score Achieved:
                    </div>
                    <div className="text-[#242424] font-[600] text-[16px]">
                      {resultData.resultStats.score.toFixed(2)} pts
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-[6px]">
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[500]">
                      Time Taken:
                    </div>
                    <div className="text-[#242424] font-[600] text-[16px]">
                      {new Date(1000 * resultData.resultStats.time)
                        .toISOString()
                        .substr(11, 8)}
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-[6px] w-full mt-4">
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[500]">
                      Total Questions:
                    </div>
                    <div className="grid grid-cols-5 lg:grid-cols-8 gap-4 w-full">
                      {resultData.testData.questions
                        .slice(range.startIndex, range.endIndex)
                        .map((data, index) => (
                          <div
                            key={range.startIndex + index}
                            className={`${
                              isQuestionAnswered(data._id)
                                ? isSelectedAnswer(
                                    data.answerOptions.filter(
                                      (el) => el.isCorrect
                                    )[0]._id
                                  )
                                  ? "!border-b-greenDark"
                                  : "!border-b-red-300"
                                : "!border-b-primaryLightest"
                            } border-b-[3px] rounded-[10px] w-fit`}
                          >
                            <div
                              className={`${
                                isQuestionAnswered(data._id)
                                  ? isSelectedAnswer(
                                      data.answerOptions.filter(
                                        (el) => el.isCorrect
                                      )[0]._id
                                    )
                                    ? "!bg-primaryGreen !text-white !border-primaryGreen"
                                    : "!bg-red-600 !text-white !border-red-600"
                                  : "!border-primaryLightest !text-primaryLight"
                              } border-[1.5px] border-primaryLighter flex items-center justify-center w-[40px] h-[40px] px-2 py-1 rounded-lg text-[#7A7A7A] font-[Poppins] font-[500]`}
                            >
                              {isQuestionAnswered(data._id) ? (
                                isSelectedAnswer(
                                  data.answerOptions.filter(
                                    (el) => el.isCorrect
                                  )[0]._id
                                ) ? (
                                  <Check />
                                ) : (
                                  <X />
                                )
                              ) : (
                                <Dot />
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-start justify-start gap-8 mt-4 w-full">
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[400] flex items-center justify-center gap-2">
                      <div className="border-b-[3px] border-b-greenDark rounded-[10px]">
                        <div className="bg-primaryGreen text-white border-primaryGreen border-[1.5px] flex items-center justify-center w-[25px] h-[25px] rounded-lg font-[Poppins] font-[500]">
                          <Check size={14} strokeWidth={3} />
                        </div>
                      </div>
                      Correct Answers:{" "}
                      <span className="text-primaryDark font-[500]">
                        {resultData.resultStats.correctAnswers}
                      </span>
                    </div>
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[400] flex items-center justify-center gap-2">
                      <div className="border-b-[3px] border-b-red-300 rounded-[10px]">
                        <div className="bg-red-600 text-white border-red-600 border-[1.5px] flex items-center justify-center w-[25px] h-[25px] rounded-lg font-[Poppins] font-[500]">
                          <Check size={14} strokeWidth={3} />
                        </div>
                      </div>
                      Wrong Answers:{" "}
                      <span className="text-primaryDark font-[500]">
                        {resultData.result.answers.length -
                          resultData.resultStats.correctAnswers}
                      </span>
                    </div>
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[400] flex items-center justify-center gap-2">
                      <div className="border-b-[3px] border-b-primaryLightest rounded-[10px]">
                        <div className="bg-transparent text-primaryLight border-primaryLight border-[1.5px] flex items-center justify-center w-[25px] h-[25px] rounded-lg font-[Poppins] font-[500]">
                          <Dot size={24} strokeWidth={3} />
                        </div>
                      </div>
                      Unanswered:{" "}
                      <span className="text-primaryDark font-[500]">
                        {resultData.testData.questions.length -
                          resultData.result.answers.length}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-wrap items-start justify-start gap-8 mt-2 w-full">
                    <span className="text-[12px] text-primaryLight group-hover:text-primaryDark transition-all ease-in-out duration-300">
                      (the score and the result are calculated based on the
                      answers you provided !)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`py-10 border-t-2 border-t-[rgba(0,0,0,0.05)] w-full flex items-center justify-center`}
          >
            <div className="container flex flex-col gap-6 lg:gap-0 lg:flex-row items-center justify-between w-full max-w-[1300px]">
              <button
                onClick={() => {
                  if (currentQuestion > 0) {
                    setCurrentQuestion(currentQuestion - 1);
                    if (window.innerWidth < 768) {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }
                  }
                }}
                disabled={currentQuestion === 0}
                className="w-full lg:w-fit border-b-2 border-b-primaryLighter disabled:border-b-primaryLightest rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300 order-2 lg:order-1"
              >
                <div
                  className={`${
                    currentQuestion === 0 &&
                    "!border-primaryLightest !text-primaryLighter"
                  } text-gray-500 px-5 py-3 rounded-2xl border-2 border-primaryLighter text-[14px] font-[600] flex items-center justify-center gap-3 w-full lg:w-fit`}
                >
                  <MoveLeft size={18} strokeWidth={2.5} />
                  Prev Question
                  <span
                    className={`${
                      currentQuestion === 0 &&
                      "!border-primaryLightest !text-primaryLighter"
                    } border-[1.5px] border-primaryLighter text-primaryLight group-hover:text-primaryBlue group-hover:border-primaryBlue px-[10px] py-[2px] rounded-lg font-[Poppins] flex items-center justify-center gap-3 text-[12px] transition-all ease-in-out duration-300`}
                  >
                    p
                  </span>
                </div>
              </button>
              <div
                className={`${
                  isQuestionAnswered(
                    resultData.testData.questions[currentQuestion]._id
                  )
                    ? isSelectedAnswer(
                        resultData.testData.questions[
                          currentQuestion
                        ].answerOptions.filter((el) => el.isCorrect)[0]._id
                      )
                      ? "!text-primaryGreen"
                      : "!text-red-600"
                    : "!text-primaryDark"
                } font-[600] text-[18px] text-center order-1 lg:order-2`}
              >
                {isQuestionAnswered(
                  resultData.testData.questions[currentQuestion]._id
                )
                  ? isSelectedAnswer(
                      resultData.testData.questions[
                        currentQuestion
                      ].answerOptions.filter((el) => el.isCorrect)[0]._id
                    )
                    ? "Your answer was correct !"
                    : "You answer was incorrect !"
                  : "You didn't answer this question !"}
              </div>
              <button
                onClick={() => {
                  if (
                    currentQuestion <
                    resultData.testData.questions.length - 1
                  ) {
                    setCurrentQuestion(currentQuestion + 1);
                    if (window.innerWidth < 768) {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }
                  } else {
                    navigate(`/course/${resultData.testData.category.name}`);
                  }
                }}
                className={`${
                  resultData.testData.questions.length - 1 ===
                    currentQuestion && "!border-b-blueLight border-b-4"
                } w-full lg:w-fit border-b-2 border-b-primaryLighter disabled:border-b-primaryLightest rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300 order-3 lg:order-3`}
              >
                <div
                  className={`${
                    resultData.testData.questions.length - 1 ===
                      currentQuestion &&
                    "!bg-primaryBlue !border-primaryBlue !text-white"
                  } text-gray-500 px-5 py-3 rounded-2xl border-2 border-primaryLighter text-[14px] font-[600] flex items-center justify-center gap-3 w-full lg:w-fit`}
                >
                  <span
                    className={`${
                      resultData.testData.questions.length - 1 > currentQuestion
                        ? ""
                        : "!text-white !border-white"
                    } border-[1.5px] border-primaryLighter text-primaryLight group-hover:text-primaryBlue group-hover:border-primaryBlue px-[10px] py-[2px] rounded-lg font-[Poppins] flex items-center justify-center gap-3 text-[12px] transition-all ease-in-out duration-300`}
                  >
                    n
                  </span>
                  {resultData.testData.questions.length - 1 > currentQuestion
                    ? "Next Question"
                    : "Finish Viewing"}
                  <MoveRight size={18} strokeWidth={2.5} />
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Result;
