import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { io } from "socket.io-client";
import TestFinished from "./components/TestFinished";
import TestStart from "./components/TestStart";
import TestActive from "./components/TestActive";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { baseUrl } from "../../Utils/BaseUrl";

import { useGetTest } from "../../Hooks/useGetTest";
import { useValidate } from "../../Hooks/useValidate";
import { LoadingContexts } from "../../Contexts/LoadingContext";

const ActiveTest = (props) => {
  const effectRan = useRef(true);
  const timerRan = useRef(false);
  const intervalRef = useRef();
  const [socketId, setSocketId] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [testFinishedUpdated, setTestFinishedUpdated] = useState(false);

  const timerRef = useRef(false);

  const { id } = useParams();
  const [startTest, setStartTest] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [selected, setSelected] = useState(null);
  const [answerSelected, setAnswerSelected] = useState(false);
  const [finishTest, setFinishTest] = useState(false);
  const [nextQuestionPressed, setNextQuestionPressed] = useState(false);

  const [answerData, setAnswerData] = useState({
    question: "",
    answer: "",
  });

  const {
    getTest,
    testData,
    currentQuestion,
    setCurrentQuestion,
    currentTime,
    setCurrentTime,
    deadline,
    loading,
    setLoading,
    error,
  } = useGetTest();

  const { isLoadingSomething, setIsLoadingSomething } =
    useContext(LoadingContexts);

  useEffect(() => {
    if (loading && !isLoadingSomething) {
      setIsLoadingSomething(true);
    }

    if (!loading && isLoadingSomething) {
      setIsLoadingSomething(false);
    }
  }, [loading]);

  const { validateUser } = useValidate();

  const socketRef = useRef(null);

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    getTest(id, props.type);
  }, [id, props.type]);

  const handleAnswerSubmit = async (question, answer) => {
    effectRan.current = false;

    setAnswerData({
      question: question,
      answer: answer,
    });

    setShowNext(true);
    setAnswerSelected(true);
  };

  const handleNextQuestion = async () => {
    setNextQuestionPressed(!nextQuestionPressed);
    if (testData.questions.length - 1 > currentQuestion) {
      effectRan.current = false;
      setCurrentQuestion(currentQuestion + 1);
      setShowNext(false);
      setAnswerSelected(false);
      return;
    }
    effectRan.current = false;
    timerRan.current = false;

    setShowNext(false);
    setFinishTest(true);
    setAnswerSelected(false);
  };

  const HandleTestStart = async () => {
    setStartTest(true);
    timerRan.current = true;
    socketRef.current = io.connect(`${baseUrl}/socket/test`, {
      withCredentials: true,
    });

    socketRef.current.emit("start_test", {
      test_id: id,
      test_type: props.type,
    });
  };

  const handleRestartTest = async () => {
    timerRef.current = false;

    setStartTest(false);
    setSelected(null);
    setShowNext(false);
    setAnswerSelected(false);

    socketRef.current.emit("restart_test", {
      test_id: id,
      test_type: props.type,
    });

    getTest(id, props.type);
  };

  const handleTimeEnd = async () => {
    effectRan.current = false;
    timerRan.current = false;

    setLoading(true);

    setFinishTest(true);
    setShowNext(false);
    setAnswerSelected(false);
  };

  useEffect(() => {
    if (!timerRef.current) {
      if (startTest && !finishTest) {
        if (timerRan.current) {
          intervalRef.current = setInterval(() => {
            setCurrentTime((time) => time + 1);
          }, 1000);
          timerRef.current = true;
        }
      }

      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [startTest, finishTest]);

  const isTimedOut = () => {
    return currentTime > deadline;
  };

  useEffect(() => {
    if (finishTest && testFinishedUpdated) {
      socketRef.current.emit("get_test_result", {
        socket_id: socketId,
        test_id: id,
        test_type: props.type,
      });
    }
  }, [finishTest, testFinishedUpdated]);

  useEffect(() => {
    if (effectRan.current === false) {
      if (finishTest && isTimedOut()) {
        socketRef.current.emit("finish_test", {
          test_id: id,
          test_type: props.type,
          test_last_time: currentTime > deadline ? deadline : currentTime,
        });
        setLoading(false);
      } else if (answerSelected) {
        if (testData.questions.length - 1 > currentQuestion) {
          socketRef.current.emit("test_answer_submit", {
            test_id: id,
            test_type: props.type,
            test_answer_data: answerData,
          });
        } else {
          timerRan.current = false;
          clearInterval(intervalRef.current);
          socketRef.current.emit("test_answer_submit", {
            test_id: id,
            test_type: props.type,
            test_answer_data: answerData,
            test_finish: true,
            test_last_time: currentTime,
          });
        }
      }
      effectRan.current = true;
    }
  }, [finishTest, answerSelected, nextQuestionPressed]);

  useEffect(() => {
    if (timerRan.current === true && currentTime < deadline) {
      socketRef.current.emit("update_test_time", {
        test_id: id,
        test_type: props.type,
        test_current_time: currentTime,
      });
    }

    if (currentTime > deadline) {
      handleTimeEnd();
    }
  }, [currentTime]);

  useEffect(() => {
    if (startTest) {
      if (typeof window?.MathJax !== "undefined") {
        window?.MathJax.typesetClear();
        window?.MathJax.typeset();
      }
    }
  }, [startTest, currentQuestion]);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on("connection_success", (data) => {
        setSocketId(data.id);
      });

      socketRef.current.on("get_test_result_success", (data) => {
        setResultData(data.result);
      });

      socketRef.current.on("finish_test_success", (data) => {
        setTestFinishedUpdated(data.test_finished);
      });

      socketRef.current.on("restart_test_error", () => {
        validateUser();
      });

      socketRef.current.on("update_test_time_error", () => {
        validateUser();
      });

      socketRef.current.on("test_answer_submit_error", () => {
        validateUser();
      });

      socketRef.current.on("finish_test_error", () => {
        validateUser();
      });

      socketRef.current.on("start_test_error", () => {
        validateUser();
      });

      socketRef.current.on("get_test_result_error", () => {
        validateUser();
      });

      socketRef.current.on("connect_error", () => {
        validateUser();
      });
    }
  }, [socketRef.current]);

  return (
    <div className="prevent-select">
      {error === null ? (
        <div>
          {loading ? (
            <div>
              <Helmet>
                <title>
                  Loading Test | MeroTests - Test Your Knowledge Online
                </title>
                <meta
                  name="description"
                  content="Please wait while we load your test. MeroTests - Test Your Knowledge Online with our comprehensive mock tests and exam preparations."
                />
                <meta
                  name="keywords"
                  content="MeroTests, loading test, online test, mock test, exam preparation"
                />
                <meta
                  property="og:title"
                  content="Loading Test | MeroTests - Test Your Knowledge Online"
                />
                <meta
                  property="og:description"
                  content="Please wait while we load your test. MeroTests - Test Your Knowledge Online with our comprehensive mock tests and exam preparations."
                />
                <meta
                  property="og:image"
                  content="https://i.imgur.com/nRManyy.png"
                />
              </Helmet>
              <>
                <Navbar />
                <div className="flex items-start justify-center w-full">
                  <div className="container grid grid-cols-1 lg:grid-cols-12 gap-10 py-8">
                    <div className="hidden lg:block col-span-3 relative">
                      <Sidebar />
                    </div>
                    <div className="col-span-1 lg:col-span-9 flex flex-col items-start justify-start w-full">
                      <div className="text-[15px] text-gray-500">
                        Loading test, please wait !
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          ) : (
            <div>
              <div>
                <Helmet>
                  <title>{`${testData.title} | MeroTests - Test Your Knowledge Online`}</title>
                  <meta
                    name="description"
                    content={`Participate in the ${testData.title} on MeroTests. Test your knowledge online with our comprehensive mock tests and exam preparations.`}
                  />
                  <meta
                    name="keywords"
                    content={`MeroTests, ${testData.title}, online test, mock test, exam preparation, ${testData.category.name} mock test`}
                  />
                  <meta
                    property="og:title"
                    content={`${testData.title} | MeroTests - Test Your Knowledge Online`}
                  />
                  <meta
                    property="og:description"
                    content={`Participate in the ${testData.title} on MeroTests. Test your knowledge online with our comprehensive mock tests and exam preparations.`}
                  />
                  <meta
                    property="og:image"
                    content="https://i.imgur.com/nRManyy.png"
                  />
                </Helmet>
                {startTest ? (
                  finishTest ? (
                    <TestFinished
                      testType={props.type}
                      resultData={resultData}
                      testData={testData}
                      testId={id}
                    />
                  ) : (
                    <TestActive
                      testData={testData}
                      currentQuestion={currentQuestion}
                      selected={selected}
                      handleAnswerSubmit={handleAnswerSubmit}
                      setSelected={setSelected}
                      showNext={showNext}
                      handleRestartTest={handleRestartTest}
                      handleNextQuestion={handleNextQuestion}
                      currentTime={currentTime}
                    />
                  )
                ) : (
                  <TestStart
                    testData={testData}
                    HandleTestStart={HandleTestStart}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Helmet>
            <title>
              Page Not Found | MeroTests - Test Your Knowledge Online
            </title>
            <meta
              name="description"
              content="The page you are looking for does not exist. Visit MeroTests to test your knowledge online with our comprehensive mock tests and exam preparations."
            />
            <meta
              name="keywords"
              content="MeroTests, 404, page not found, online test, mock test, exam preparation"
            />
            <meta
              property="og:title"
              content="Page Not Found | MeroTests - Test Your Knowledge Online"
            />
            <meta
              property="og:description"
              content="The page you are looking for does not exist. Visit MeroTests to test your knowledge online with our comprehensive mock tests and exam preparations."
            />
            <meta
              property="og:image"
              content="https://i.imgur.com/nRManyy.png"
            />
          </Helmet>
          The test was not found !
        </div>
      )}
    </div>
  );
};

export default ActiveTest;
