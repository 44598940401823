import { BrowserRouter } from "react-router-dom";
import RoutesConfig from "./Routes/RoutesConfig";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Validate from "./Components/Global/Validate/Validate";

function App() {
  return (
    <BrowserRouter>
      <Validate />
      <RoutesConfig />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </BrowserRouter>
  );
}

export default App;
