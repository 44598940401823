import React from "react";
import { Link } from "react-router-dom";
import loginBg from "../../Assets/img/auth/lbg.png";
import { useState } from "react";
import { useRequestResetPassword } from "../../Hooks/user/useRequestResetPassword";
import { useEffect } from "react";
import { toast } from "react-toastify";

const ForgotPasswordRequest = () => {
  const [email, setEmail] = useState("");

  const {
    requestResetPassword,
    loadingRequestResetPassword,
    errorRequestResetPassword,
    successRequestResetPassword,
  } = useRequestResetPassword();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email address !");
    }
    requestResetPassword(email);
  };

  useEffect(() => {
    if (successRequestResetPassword) {
      toast.success(successRequestResetPassword);
      setEmail("");
    }
  }, [successRequestResetPassword]);

  useEffect(() => {
    if (errorRequestResetPassword) {
      toast.error(errorRequestResetPassword);
    }
  }, [errorRequestResetPassword]);

  return (
    <div className="min-h-screen flex items-center justify-center w-full">
      <div className="container w-full">
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6 w-full">
          <div className="flex flex-col items-center justify-center w-full py-20">
            <Link
              to="/"
              className="flex flex-col items-start justify-center leading-7"
            >
              <div className="text-[40px] font-[600] text-primaryBlue">
                mero
                <span className="text-primaryDark">
                  test<span className="text-primaryGreen">s.</span>
                </span>
                <span className="px-[6px] py-[2px] text-[10px] bg-gradient-to-r from-primaryBlue to-primaryGreen font-[Poppins] text-white rounded-md ms-1">
                  BETA
                </span>
              </div>
              <div className="font-[Poppins] text-[10.5px] font-[500]">
                from{" "}
                <span className="bg-gradient-to-l from-[#48bdc3] to-[#0e62ac] text-white px-1 py-[2px] font-[600] rounded-[2px]">
                  Binary Entrance
                </span>
              </div>
            </Link>
            <form
              onSubmit={handleResetPassword}
              className="w-full flex flex-col items-center justify-center font-[Poppins]"
            >
              <div className="w-full lg:w-[75%] mt-10 relative">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full border-2 border-primaryLightest rounded-xl px-4 pt-[14px] pb-[14px] bg-transparent outline-none focus:border-primaryLight hover:border-primaryLighter transition-all ease-in-out duration-300"
                  placeholder="Email Address"
                />
              </div>
              <div className="w-full lg:w-[75%] mt-10 relative">
                <button
                  disabled={loadingRequestResetPassword}
                  className="bg-primaryBlue text-white px-5 py-[14px] rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter disabled:text-white hover:border-b-[0px] transition-all ease-in-out duration-300 text-[15px] font-[600] flex items-center justify-center gap-3 w-full"
                >
                  Reset Password
                </button>
              </div>
            </form>
            <div className="w-full lg:w-[75%] mt-4 relative">
              <div className="text-[#7A7A7A] font-[400] font-[Poppins] text-center w-full">
                Already have an account ?{" "}
                <Link to="/login" className="font-[600] text-[#1A1A1A]">
                  Sign In
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`hidden lg:flex flex-col bg-blue-500 min-h-[80vh] rounded-2xl bg-cover bg-no-repeat items-start justify-start pl-10 pt-8`}
            style={{ backgroundImage: `url(${loginBg})` }}
          >
            <div className="font-[600] text-[42px] max-w-[375px] leading-[54px]">
              Sharpen your{" "}
              <span className="text-primaryBlue">skills with us !</span>
            </div>
            <div className="max-w-[400px] font-[Poppins] font-[500] text-[#3A3A3A] text-[18px] mt-2">
              Join today and start your journey to master exams with confidence
              and achieve your goals!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordRequest;
