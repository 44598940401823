import React from "react";
import { Link } from "react-router-dom";
import heroImg from "../../Assets/img/hero/img.png";

const Hero = () => {
  return (
    <div className="flex w-full items-center justify-center">
      <div className="w-full">
        <div className="grid grid-cols-1 lg:grid-cols-2 w-full">
          <div className="flex items-center justify-center">
            <div className="flex flex-col items-start justify-center container py-10">
              <div className="text-[#242424] font-[700] text-[40px]">
                Empower Your Education: Prepare, Practice, & Prevail
              </div>
              <div className="text-[#7A7A7A] font-[Poppins] font-[400] text-[18px] mt-4">
                Enhance your study efficiency, master time management, and track
                your progress as you prepare to ace your exams with confidence.
              </div>
              <div className="mt-10 flex items-center justify-start gap-6">
                <Link
                  to="/dashboard"
                  className="flex bg-primaryBlue text-white px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] items-center justify-center gap-3 w-fit"
                >
                  Get Started For Free
                </Link>
                <div
                  onClick={() =>
                    window.scrollTo({
                      top: document.getElementById("whymerotests")?.offsetTop,
                      behavior: "smooth",
                    })
                  }
                  className="text-[#0266FF] font-[500] font-[Poppins] text-[16px] underline cursor-pointer"
                >
                  Why merotests?
                </div>
              </div>
              <div className="font-[Poppins] text-primaryLight mt-8">
                (Trusted by over thousands of students all over Nepal)
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative">
            <img src={heroImg} alt="hero" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
