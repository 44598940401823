import React from "react";
import { useLogout } from "../../Hooks/useLogout";

import coursesImg from "../../Assets/img/sidebar/2.png";
import profileImg from "../../Assets/img/sidebar/4.png";
import dashboardImg from "../../Assets/img/sidebar/1.png";
import leaderboardImg from "../../Assets/img/sidebar/3.png";
import logoutImg from "../../Assets/img/sidebar/5.png";
import { Link } from "react-router-dom";

const Phonebar = () => {
  const { logoutUser, isLoading } = useLogout();

  return (
    <div className="fixed block lg:hidden w-full bottom-0 left-0 bg-white py-5 border-t border-primaryLightest">
      <div className="grid grid-cols-5">
        <div className="flex items-center justify-center w-full">
          <Link to="/courses">
            <img src={coursesImg} alt="dashboard" className="w-[32px]" />
          </Link>
        </div>
        <div className="flex items-center justify-center w-full">
          <Link to="/leaderboard">
            <img src={leaderboardImg} alt="dashboard" className="w-[22px]" />
          </Link>
        </div>
        <div className="flex items-center justify-center w-full relative">
          <Link to="/dashboard" className="absolute">
            <img src={dashboardImg} alt="dashboard" className="w-[40px]" />
          </Link>
        </div>
        <div className="flex items-center justify-center w-full">
          <Link to="/profile">
            <img src={profileImg} alt="dashboard" className="w-[28px]" />
          </Link>
        </div>
        <div className="flex items-center justify-center w-full">
          <button onClick={() => logoutUser()} disabled={isLoading}>
            <img src={logoutImg} alt="dashboard" className="w-[28px]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Phonebar;
