import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { ArrowLeft, Eye } from "lucide-react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const TestFinished = ({ testData, resultData, testType, testId }) => {
  const data02 = [
    {
      name: "Group A",
      value: 2400,
    },
    {
      name: "Group B",
      value: 4567,
    },
    {
      name: "Group C",
      value: 1398,
    },
  ];

  const graphColors = ["#08BD80", "#BD0808", "#D1D3D2"];

  const navigate = useNavigate();

  const parseTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);

    const minutes = Math.floor((seconds % 3600) / 60);

    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes}:${remainingSeconds}`;
  };

  return (
    <>
      <Navbar />
      <div className="flex items-start justify-center w-full">
        <div className="container grid grid-cols-1 lg:grid-cols-12 gap-10 py-8">
          <div className="hidden lg:block col-span-3 relative">
            <Sidebar />
          </div>
          {testData && resultData && (
            <>
              <div className="col-span-1 lg:col-span-6 flex flex-col items-start justify-start w-full">
                <div className="flex items-start justify-center flex-col">
                  <div className="text-[##242424] font-[600] text-[18px]">
                    {testData.title}
                  </div>
                  <div className="text-[#7A7A7A] font-[Poppins] text-[14px] mt-1 flex items-center justify-start gap-1">
                    <Link
                      to="/dashboard"
                      className="hover:text-primaryDarker transition-all ease-in-out duration-300"
                    >
                      Dashboard
                    </Link>
                    &gt;
                    <Link
                      to="/courses"
                      className="hover:text-primaryDarker transition-all ease-in-out duration-300"
                    >
                      Courses
                    </Link>
                    &gt;
                    <Link
                      to={`/course/${testData.category.name}`}
                      className="uppercase hover:text-primaryDarker transition-all ease-in-out duration-300"
                    >
                      {testData.category.name}
                    </Link>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center w-full mt-10">
                  <div className="text-primaryGreen font-[600] text-[28px]">
                    Congratulations on Completing Your Test!
                  </div>
                  <div className="text-[#3A3A3A] font-[Poppins] font-[400] text-[14px] mt-3 leading-7">
                    Here’s a detailed breakdown of your performance, helping you
                    understand where you excelled and where there's room for
                    improvement.
                  </div>
                  <div className="mt-8 w-full border-b-2 border-b-primaryLightest rounded-[20px]">
                    <div className="border-2 border-primaryLightest rounded-2xl p-10">
                      <div className="text-[##242424] font-[600] text-[18px] w-full text-center">
                        Performance Analysis
                      </div>
                      <div className="grid grid-cols-1 2xl:grid-cols-5 gap-8 2xl:gap-0 w-full mt-6 2xl:mt-4">
                        <div className="flex items-center justify-evenly w-full 2xl:col-span-2">
                          <ResponsiveContainer
                            width="100%"
                            height="100%"
                            minHeight={250}
                            minWidth={200}
                          >
                            <PieChart>
                              <Pie
                                data={[
                                  {
                                    name: "Correct Answers",
                                    value: resultData.correctAnswers,
                                  },
                                  {
                                    name: "Wrong Answers",
                                    value:
                                      resultData.selectedAnswers.answers
                                        .length - resultData.correctAnswers,
                                  },
                                  {
                                    name: "Unanswered",
                                    value:
                                      testData.questions.length -
                                      resultData.selectedAnswers.answers.length,
                                  },
                                ]}
                                dataKey="value"
                                nameKey="name"
                                innerRadius={60}
                                outerRadius={120}
                                fill="#82ca9d"
                              >
                                {graphColors.map((color) => (
                                  <Cell fill={color} />
                                ))}
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </div>
                        <div className="flex items-center justify-center w-full 2xl:col-span-3">
                          <div className="flex flex-col items-start justify-center gap-6 font-[Poppins]">
                            <div className="flex items-center justify-start gap-2">
                              <div className="w-[20px] h-[20px] bg-primaryGreen"></div>
                              <span className="pt-1 text-[14px] font-[500]">
                                Correct Answers -{" "}
                                {(
                                  (resultData.correctAnswers /
                                    testData.questions.length) *
                                  100
                                ).toFixed(2)}
                                % ({resultData.correctAnswers} questions)
                              </span>
                              <span></span>
                            </div>
                            <div className="flex items-center justify-start gap-2">
                              <div className="w-[20px] h-[20px] bg-[#BD0808]"></div>
                              <span className="pt-1 text-[14px] font-[500]">
                                Wrong Answers -{" "}
                                {(
                                  ((resultData.selectedAnswers.answers.length -
                                    resultData.correctAnswers) /
                                    testData.questions.length) *
                                  100
                                ).toFixed(2)}
                                % (
                                {resultData.selectedAnswers.answers.length -
                                  resultData.correctAnswers}{" "}
                                questions)
                              </span>
                              <span></span>
                            </div>
                            <div className="flex items-center justify-start gap-2">
                              <div className="w-[20px] h-[20px] bg-[#D1D3D2]"></div>
                              <span className="pt-1 text-[14px] font-[500]">
                                Unanswered -{" "}
                                {(
                                  ((testData.questions.length -
                                    resultData.selectedAnswers.answers.length) /
                                    testData.questions.length) *
                                  100
                                ).toFixed(2)}
                                % (
                                {testData.questions.length -
                                  resultData.selectedAnswers.answers
                                    .length}{" "}
                                questions)
                              </span>
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-end mt-8 gap-5">
                    <div>
                      <button
                        onClick={() =>
                          navigate(`/course/${testData.category.name}`)
                        }
                        className="w-full border-b-[3px] border-b-primaryLightest rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300"
                      >
                        <div className="text-gray-500 px-5 py-3 rounded-2xl border-2 border-primaryLightest disabled:border-primaryLightest text-[14px] font-[600] flex items-center justify-center gap-3 w-full">
                          <ArrowLeft
                            size={16}
                            strokeWidth={3}
                            className="mb-1"
                          />
                          Go Back
                        </div>
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          navigate(`/${testType}_result/${testId}`)
                        }
                        className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full"
                      >
                        Review Answers
                        <Eye size={16} strokeWidth={3} className="mb-1" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 lg:col-span-3 flex flex-col items-start justify-start w-full">
                <div className="border-b-[2px] border-b-primaryLightest rounded-[20px] w-full">
                  <div className="border-2 border-primaryLightest rounded-2xl p-5 w-full">
                    <div className="flex flex-col w-full items-start justify-center">
                      <div className="text-[#242424] font-[600] text-[18px]">
                        Test Details :
                      </div>
                      <div className="flex flex-col items-start justify-center mt-4">
                        <div className="text-[#7A7A7A] font-[Poppins] text-[14px]">
                          Completion Duration
                        </div>
                        <div className="text-[#242424] font-[600] mt-2">
                          {parseTime(Math.trunc(resultData.lastTime))} hr
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-center mt-6">
                        <div className="text-[#7A7A7A] font-[Poppins] text-[14px]">
                          Completion Score
                        </div>
                        <div className="text-[#242424] font-[600] mt-2">
                          {resultData.lastScore.toFixed(2)} pts
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-center mt-6">
                        <div className="text-[#7A7A7A] font-[Poppins] text-[14px]">
                          Total Questions
                        </div>
                        <div className="text-[#242424] font-[600] mt-2">
                          {testData.questions.length} questions
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-center mt-6">
                        <div className="text-[#7A7A7A] font-[Poppins] text-[14px]">
                          Correct Answers
                        </div>
                        <div className="text-[#08BD80] font-[600] mt-2">
                          {resultData.correctAnswers} questions
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-center mt-6">
                        <div className="text-[#7A7A7A] font-[Poppins] text-[14px]">
                          Wrong Answer
                        </div>
                        <div className="text-[#BD0808] font-[600] mt-2">
                          {resultData.selectedAnswers.answers.length -
                            resultData.correctAnswers}{" "}
                          questions
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-center mt-6">
                        <div className="text-[#7A7A7A] font-[Poppins] text-[14px]">
                          Unanswered
                        </div>
                        <div className="text-gray-400 font-[600] mt-2">
                          {testData.questions.length -
                            resultData.selectedAnswers.answers.length}{" "}
                          questions
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TestFinished;
