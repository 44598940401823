import React, { useState } from "react";
import { createContext } from "react";

export const TestModalContexts = createContext();

const TestModalContext = ({ children }) => {
  const [redirectedFromTestModal, setRedirectedFromTestModal] = useState(false);
  return (
    <TestModalContexts.Provider
      value={{ redirectedFromTestModal, setRedirectedFromTestModal }}
    >
      {children}
    </TestModalContexts.Provider>
  );
};

export default TestModalContext;
