import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetCategories } from "../../Hooks/useGetCategories";
import { MapPin, Phone } from "lucide-react";

const Footer = () => {
  const { categories, loadingCategories, getCategories } = useGetCategories();

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="border-t border-primaryLightest">
      <div className="container pt-10 pb-5">
        <div className="grid col-span-2 lg:grid-cols-14 gap-12">
          <div className="col-span-2 lg:col-span-5 flex flex-col items-start justify-start">
            <div className="text-[30px] font-[600] text-primaryBlue">
              mero
              <span className="text-primaryDark">
                test<span className="text-primaryGreen">s.</span>
              </span>
              <span className="px-[6px] py-[2px] text-[10px] bg-gradient-to-r from-primaryBlue to-primaryGreen font-[Poppins] text-white rounded-md ms-1">
                BETA
              </span>
            </div>
            <div className="text-[15px] font-[Poppins] mt-2 leading-7">
              Mero Tests is an online exam practice platform for Bsc.CSIT, BCA,
              CMAT Entrance Exam and other different fields offering computer
              based free mock tests aimed at students who wants to pursue
              their career in respective fields.
            </div>
          </div>
          <div className="col-span-1 lg:col-span-3 flex flex-col items-start justify-start">
            <div className="font-[Poppins] font-[500] border-b-2 border-b-primaryDarker text-primaryDarker">
              Quick Links
            </div>
            <div className="mt-6 flex flex-col items-start justify-start gap-3 text-[15px] font-[Poppins]">
              <Link
                to="/dashboard"
                className="hover:text-primaryBlue transition-all ease-in-out duration-300"
              >
                Dashboard
              </Link>
              {!loadingCategories &&
                categories &&
                categories.slice(0, 3).map((category) => (
                  <Link
                    to={`/course/${category.name}`}
                    className="hover:text-primaryBlue transition-all ease-in-out duration-300"
                  >
                    <span className="uppercase">{category.name}</span> Tests
                  </Link>
                ))}
            </div>
          </div>
          <div className="col-span-1 lg:col-span-3 flex flex-col items-start justify-start">
            <div className="font-[Poppins] font-[500] border-b-2 border-b-primaryDarker text-primaryDarker">
              Contact Us
            </div>
            <div className="mt-6 flex flex-col items-start justify-center gap-3 font-[Poppins]">
              <div className="flex items-center justify-start gap-2">
                <Phone size={18} />
                <span>+977 9844762048, 9826939866</span>
              </div>
              <div className="flex items-center justify-start gap-2">
                <MapPin size={18} />
                <span>Putalisadak, Kathmandu</span>
              </div>
            </div>
          </div>
          <div className="col-span-1 lg:col-span-3 flex flex-col items-start justify-start">
            <div className="font-[Poppins] font-[500] border-b-2 border-b-primaryDarker text-primaryDarker">
              Coming soon !
            </div>
            <div className="mt-6">Section Wise Tests...</div>
          </div>
        </div>
        <div className="border-t border-t-primaryLightest mt-10 pt-5 flex flex-col lg:flex-row gap-2 lg:gap-0 items-center justify-center lg:justify-between w-full font-[Poppins] text-gray-400 text-[14px]">
          <span>© 2024 MeroTests | All Rights Reserved</span>
          <span>A Product by Binary Entrance</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
