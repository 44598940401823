import { useState } from "react";

export const useUpdateUserData = () => {
  const [loadingUpdateUserData, setLoadingUpdateUserData] = useState(false);
  const [errorUpdateUserData, setErrorUpdateUserData] = useState(null);
  const [updateUserDataMessage, setUpdateUserDataMessage] = useState(null);

  const updateUserData = async (name, phone, password, profilePic) => {
    try {
      setLoadingUpdateUserData(true);
      setErrorUpdateUserData(null);
      setUpdateUserDataMessage(null);

      const formData = new FormData();
      formData.append("name", name);
      formData.append("phone", phone);
      if (password) {
        formData.append("password", password);
      }
      if (profilePic) {
        formData.append("profile-picture", profilePic); // Append the profile picture file
      }

      const request = await fetch(`/api/update_user_data`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      const response = await request.json();

      if (!request.ok) {
        setLoadingUpdateUserData(false);
        setErrorUpdateUserData(response.msg);
      }
      if (request.ok) {
        setLoadingUpdateUserData(false);
        setErrorUpdateUserData(null);
        setUpdateUserDataMessage(response.msg);
      }
    } catch (err) {
      setLoadingUpdateUserData(false);
      setErrorUpdateUserData(err.message);
    }
  };

  return {
    loadingUpdateUserData,
    errorUpdateUserData,
    updateUserDataMessage,
    updateUserData,
  };
};
