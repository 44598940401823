import React, { useEffect } from "react";
import { useValidate } from "../../../Hooks/useValidate";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../../Hooks/useAuthContext";

const Validate = () => {
  const { validateUser } = useValidate();
  const { user } = useAuthContext();

  const location = useLocation();

  useEffect(() => {
    validateUser();
  }, [location]);
  return <></>;
};

export default Validate;
