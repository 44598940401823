import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import AuthProvider from "./Contexts/AuthProvider";
import TestModalContext from "./Contexts/TestModalContext";
import LoadingContext from "./Contexts/LoadingContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

window.addEventListener("visibilitychange", () => {
  if (
    window.location.pathname.startsWith("/public_test/") ||
    window.location.pathname.startsWith("/custom_test/")
  ) {
    window.location.href =
      "/dashboard?toastMsg=Changing tabs during exam session is not allowed !&toastType=error";
  }
});

window.addEventListener("blur", () => {
  if (
    window.location.pathname.startsWith("/public_test/") ||
    window.location.pathname.startsWith("/custom_test/")
  ) {
    window.location.href =
      "/dashboard?toastMsg=Changing tabs during exam session is not allowed !&toastType=error";
  }
});

window.addEventListener("offline", () => {
  if (
    window.location.pathname.startsWith("/public_test/") ||
    window.location.pathname.startsWith("/custom_test/")
  ) {
    window.location.href =
      "/dashboard?toastMsg=The test was paused since your device was offline !&toastType=error";
  }
});

root.render(
  <React.StrictMode>
    <AuthProvider>
      <TestModalContext>
        <LoadingContext>
          <App />
        </LoadingContext>
      </TestModalContext>
    </AuthProvider>
  </React.StrictMode>
);
