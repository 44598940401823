import React from "react";
import techMask from "../../Assets/img/ads/tech.png";
import sajhaMask from "../../Assets/img/ads/sajha.jpeg";
import techLogo from "../../Assets/img/ads/techlogo.png";
import sajhaLogo from "../../Assets/img/ads/sajhalogo.png";
import academiaLogo from "../../Assets/img/ads/academiaLogo.png";
import academiaMask from "../../Assets/img/ads/academia.jpg";
import { Link } from "react-router-dom";
import { useMemo } from "react";

const Advertisements = [
  {
    id: 1,
    title: "Techspire College",
    description:
      "The first IT College, operated by a leading IT Company Code Himalaya enhancing the IT education in Nepal.",
    logo: techLogo,
    image: techMask,
    overlayColor: "from-red-700 to-[rgba(0,0,0,0.2)]",
    textColor: "text-white",
    buttonLink: "https://techspire.edu.np/",
    buttonText: "Learn More",
    buttonTextColor: "text-red-700",
    buttonBorderColor: "border-b-[#cd8888]",
  },
  {
    id: 2,
    title: "Academia International College",
    description:
      "One of the premier institutions dedicated to providing quality education in a fully-integrated, multicultural environment.",
    logo: academiaLogo,
    image: academiaMask,
    overlayColor: "from-blue-600 to-[rgba(0,0,0,0.2)]",
    textColor: "text-white",
    buttonLink: "https://www.academiacollege.edu.np/",
    buttonText: "Learn More",
    buttonTextColor: "text-black",
    buttonBorderColor: "border-b-blue-500",
  },
  // {
  //   id: 2,
  //   title: "Sajha Entrance",
  //   description:
  //     "Best entrance preparation institute for best scholarships, as well as counselling from experts for deserving candidates.",
  //   logo: sajhaLogo,
  //   image: sajhaMask,
  //   overlayColor: "from-orange-600 to-[rgba(0,0,0,0.2)]",
  //   textColor: "text-white",
  //   buttonLink: "https://www.facebook.com/profile.php?id=100089786396817",
  //   buttonText: "Learn More",
  //   buttonTextColor: "text-orange-600",
  //   buttonBorderColor: "border-b-[#e8915f]",
  // },
];

const Advertisement = () => {
  const [randomIndex, setRandomIndex] = React.useState(0);

  useMemo(
    () =>
      setInterval(
        () => setRandomIndex((prev) => (prev + 1) % Advertisements.length),
        10000
      ),
    []
  );

  if (Advertisements.length === 0) return null;

  return (
    <div
      className="w-full rounded-2xl overflow-hidden bg-center bg-cover"
      style={{ backgroundImage: `url(${Advertisements[randomIndex].image})` }}
    >
      <div
        className={`w-full h-full bg-gradient-to-r ${Advertisements[randomIndex].overlayColor} backdrop-blur-[1px]`}
      >
        <div
          className={`py-5 flex flex-col items-start justify-start gap-3 ${Advertisements[randomIndex].textColor}`}
        >
          <div className="text-[24px] font-[600] font-[Poppins] px-5">
            {Advertisements[randomIndex].title}
          </div>
          <div className="text-[13.5px] leading-6 w-full flex items-center font-[500]">
            <div className="basis-[65%] ps-5">
              {Advertisements[randomIndex].description}
            </div>
            <div className="basis-[35%] pe-5">
              {Advertisements[randomIndex].logo && (
                <img
                  src={Advertisements[randomIndex].logo}
                  alt="college-logo"
                  className="drop-shadow-4xl"
                />
              )}
            </div>
          </div>
          <div className="mt-1 px-5">
            <Link
              target="_blank"
              to={Advertisements[randomIndex].buttonLink}
              className={`bg-white border-b-[3px] ${Advertisements[randomIndex].buttonBorderColor} ${Advertisements[randomIndex].buttonTextColor} px-4 py-[6px] rounded-lg font-[Poppins] text-[14px] font-[500] hover:border-0 transition-all ease-in-out duration-300`}
            >
              {Advertisements[randomIndex].buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advertisement;
