import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NewsletterModal from "../Components/NewsletterModal/NewsletterModal";
import Login from "../Pages/Login/Login";
import AuthRoutes from "./AuthRoutes";
import MainRoutes from "./MainRoutes";
import { useAuthContext } from "../Hooks/useAuthContext";
import CreateTestModal from "../Components/CreateTestModal/CreateTestModal";
import PurchaseModal from "../Components/PurchaseModal/PurchaseModal";
import SubscriptionModal from "../Components/SubscriptionModal/SubscriptionModal";

const RoutesConfig = () => {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;
  const purchaseData = location.state?.purchaseData;
  const { user } = useAuthContext();

  return (
    <>
      <Routes location={previousLocation || location}>
        {AuthRoutes()}
        {MainRoutes()}
      </Routes>
      {previousLocation && (
        <Routes>
          <Route
            path="/newsletter-config"
            element={user ? <NewsletterModal /> : <Login />}
          />
          <Route
            path="/purchase-details"
            element={
              user ? <PurchaseModal purchaseData={purchaseData} /> : <Login />
            }
          />
          <Route
            path="/purchase-subscription"
            element={user ? <SubscriptionModal /> : <Login />}
          />
          <Route
            path="/create-test"
            element={
              user ? (
                <CreateTestModal
                  category={previousLocation.pathname.split("/")[2]}
                />
              ) : (
                <Login />
              )
            }
          />
        </Routes>
      )}
    </>
  );
};

export default RoutesConfig;
