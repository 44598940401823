import React from "react";

import { Link, useLocation } from "react-router-dom";
import coursesImg from "../../Assets/img/sidebar/2.png";
import profileImg from "../../Assets/img/sidebar/4.png";
import dashboardImg from "../../Assets/img/sidebar/1.png";
import leaderboardImg from "../../Assets/img/sidebar/3.png";

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="flex items-center justify-center">
      <div className="flex items-start justify-center flex-col gap-3 w-[100%]">
        <Link
          to="/dashboard"
          className={`${currentPath === "/dashboard" && "!border-b-blueLight"
            } border-b-[3px] border-b-transparent hover:border-b-primaryLighter rounded-[20px] w-full transition-all ease-in-out duration-300 group`}
        >
          <div
            className={`${currentPath === "/dashboard" &&
              "!bg-blueLighter !border-blueLight !text-primaryBlue"
              } text-[#777777] font-[700] text-[13.5px] tracking-[0.025em] uppercase font-[Inter] flex items-center justify-start gap-4 group-hover:bg-primaryLightest group-hover:border-primaryLighter hover:text-w px-4 py-[8px] w-full rounded-2xl border-2 border-transparent transition-all ease-in-out duration-300`}
          >
            <img src={dashboardImg} alt="dashboard" className="w-[36px]" />
            Dashboard
          </div>
        </Link>
        <Link
          to="/courses"
          className={`${(currentPath.includes("course") ||
            currentPath.includes("public_test") ||
            currentPath.includes("custom_test")) &&
            "!border-b-blueLight"
            } border-b-[3px] border-b-transparent hover:border-b-primaryLighter rounded-[20px] w-full transition-all ease-in-out duration-300 group`}
        >
          <div
            className={`${(currentPath.includes("course") ||
              currentPath.includes("public_test") ||
              currentPath.includes("custom_test")) &&
              "!bg-blueLighter !border-blueLight !text-primaryBlue"
              } text-[#777777] font-[700] text-[13.5px] tracking-[0.025em] uppercase font-[Inter] flex items-center justify-start gap-4 group-hover:bg-primaryLightest group-hover:border-primaryLighter hover:text-w px-4 py-[8px] w-full rounded-2xl border-2 border-transparent transition-all ease-in-out duration-300`}
          >
            <img src={coursesImg} alt="courses" className="w-[36px]" />
            courses
          </div>
        </Link>
        <Link
          to="/leaderboard"
          className={`${currentPath === "/leaderboard" && "!border-b-blueLight"
            } border-b-[3px] border-b-transparent hover:border-b-primaryLighter rounded-[20px] w-full transition-all ease-in-out duration-300 group`}
        >
          <div
            className={`${currentPath === "/leaderboard" &&
              "!bg-blueLighter !border-blueLight !text-primaryBlue"
              } text-[#777777] font-[700] text-[13.5px] tracking-[0.025em] uppercase font-[Inter] flex items-center justify-start gap-4 group-hover:bg-primaryLightest group-hover:border-primaryLighter hover:text-w px-4 py-[8px] w-full rounded-2xl border-2 border-transparent transition-all ease-in-out duration-300`}
          >
            <img
              src={leaderboardImg}
              alt="leaderboard"
              className="w-[36px] p-[2px]"
            />
            leaderboard
          </div>
        </Link>
        <Link
          to="/profile"
          className={`${currentPath === "/profile" && "!border-b-blueLight"
            } border-b-[3px] border-b-transparent hover:border-b-primaryLighter rounded-[20px] w-full transition-all ease-in-out duration-300 group`}
        >
          <div
            className={`${currentPath === "/profile" &&
              "!bg-blueLighter !border-blueLight !text-primaryBlue"
              } text-[#777777] font-[700] text-[13.5px] tracking-[0.025em] uppercase font-[Inter] flex items-center justify-start gap-4 group-hover:bg-primaryLightest group-hover:border-primaryLighter hover:text-w px-4 py-[8px] w-full rounded-2xl border-2 border-transparent transition-all ease-in-out duration-300`}
          >
            <img src={profileImg} alt="profile" className="w-[36px]" />
            my profile
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
