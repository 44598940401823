import { Check, Dot, X } from "lucide-react";
import React, { useContext, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import curvedArrow from "../../Assets/img/dashboard/curved.png";
import { useGetSendPayment } from "../../Hooks/useGetSendPayment";
import { toast } from "react-toastify";
import AuthContext from "../../Contexts/AuthContext";

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "1050",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "16px",
    padding: "0px",
    maxWidth: "800px",
    overflow: "hidden",
    width: "85%",
  },
};

const SubscriptionModal = () => {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const [planType, setPlanType] = React.useState("monthly");

  const {
    getSendPayment,
    loadingSendPayment,
    errorSendPayment,
    sendPaymentData,
  } = useGetSendPayment();

  const handleSendPayment = () => {
    getSendPayment(
      planType === "monthly" ? "sub_monthly" : "sub_annually",
      planType === "monthly" ? 100 : 960,
      user.name,
      user.email,
      user.phone,
      "subscription"
    );
  };

  useEffect(() => {
    if (errorSendPayment) {
      toast.error(errorSendPayment);
    }
  }, [errorSendPayment]);

  useEffect(() => {
    if (sendPaymentData) {
      window.location.href = sendPaymentData.payment_url;
    }
  }, [sendPaymentData]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Modal
      isOpen={true}
      style={modalStyles}
      closeTimeoutMS={250}
      onRequestClose={() => navigate(-1)}
    >
      <div className="max-h-[90vh] overflow-scroll">
        <div className="p-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="hidden lg:block relative">
            <div>
              <X
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <div className="font-[600] text-[24px] text-[#242424] mt-6">
              Premium Plan for{" "}
              <span className="font-[700] gradient-text">Peak Performance</span>
            </div>
            <div className="text-[#3A3A3A] text-[16px] font-[400] mt-3">
              Unlock Premium Features for Enhanced Mock Test Mastery!
            </div>
            <div className="border-[1px] border-[rgba(122,122,122,0.4)] rounded-lg mt-6 p-4 flex flex-col items-start justify-center">
              <div className="text-[#3A3A3A] font-[Poppins] font-[500] text-[12px] bg-primaryLightest px-2 py-1 rounded-md">
                Your Plan
              </div>
              <div className="text-[24px] font-[600] text-[#242424] mt-4">
                Free
              </div>
              <div className="text-[#7A7A7A] font-[400] font-[Poppins] text-[16px] mt-1">
                NPR 0/month
              </div>
              <div className="mt-4 text-[#242424] font-[400] text-[14px] flex flex-col items-start justify-center gap-3">
                <div className="flex items-center justify-start gap-1">
                  <Check size={18} />
                  Regular Experience
                </div>
                <div className="flex items-center justify-start gap-1">
                  <Check size={18} />
                  Max 2 Custom Tests
                </div>
                <div className="flex items-center justify-start gap-1">
                  <Check size={18} />
                  Limited Access
                </div>
                <div className="flex items-center justify-start gap-1">
                  <Check size={18} />
                  Limited Support
                </div>
              </div>
              <div className="text-[#7A7A7A] font-[400] text-[14px] mt-4">
                + You currently have access to limited tests and features.
              </div>
            </div>
            <div className="absolute bottom-[50%] -right-14 translate-y-[60%]">
              <img src={curvedArrow} alt="curved arrow" className="h-[120px]" />
            </div>
          </div>
          <div className="bg-[rgba(2,103,255,0.05)] rounded-lg p-4 flex flex-col items-start justify-start w-full">
            <div className="text-[#242424] font-[600] text-[24px]">
              Pro Member
            </div>
            <div className="text-[#7A7A7A] font-[500] text-[14px] mt-2">
              Billing Options
            </div>
            <div className="w-full mt-6 flex flex-col items-start justify-start gap-4">
              <div
                className={`${
                  planType === "monthly" && "!border-b-blueLight"
                } border-b-[3px] border-b-primaryLight w-full rounded-[14px] hover:border-b-0 transition-all ease-in-out duration-300`}
              >
                <button
                  onClick={() => setPlanType("monthly")}
                  className={`${
                    planType === "monthly" ? "!border-primaryBlue" : ""
                  } flex items-center justify-start gap-4 w-full border-[1px] border-black px-4 py-3 rounded-xl`}
                >
                  <div
                    className={`${
                      planType === "monthly" &&
                      "!bg-primaryBlue !border-primaryBlue"
                    } border-[1px] border-primaryDark h-[25px] w-[25px] rounded-[50%] flex items-center justify-center`}
                  >
                    {planType === "monthly" && (
                      <Dot strokeWidth={8} color="#fff" />
                    )}
                  </div>
                  <div className="flex flex-col items-start justify-center">
                    <div className="text-[#242424] font-[Poppins] font-[500] text-[13px]">
                      Pay Monthly
                    </div>
                    <div className="text-[#7A7A7A] font-[Poppins] font-[500] text-[11px]">
                      NPR. 100 / month
                    </div>
                  </div>
                </button>
              </div>

              <div
                className={`${
                  planType === "annually" && "!border-b-blueLight"
                } border-b-[3px] border-b-primaryLight w-full rounded-[14px] hover:border-b-0 transition-all ease-in-out duration-300`}
              >
                <button
                  onClick={() => setPlanType("annually")}
                  className={`${
                    planType === "annually" ? "!border-primaryBlue" : ""
                  } flex items-center justify-start gap-4 w-full border-[1px] border-black px-4 py-3 rounded-xl`}
                >
                  <div
                    className={`${
                      planType === "annually" &&
                      "!bg-primaryBlue !border-primaryBlue"
                    } border-[1px] border-primaryDark h-[25px] w-[25px] rounded-[50%] flex items-center justify-center`}
                  >
                    {planType === "annually" && (
                      <Dot strokeWidth={8} color="#fff" />
                    )}
                  </div>
                  <div className="flex flex-col items-start justify-center">
                    <div className="text-[#242424] font-[Poppins] font-[500] text-[13px]">
                      Pay Annually
                    </div>
                    <div className="text-[#7A7A7A] font-[Poppins] font-[500] text-[11px]">
                      NPR. 80 / month
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="text-[#242424] font-[500] mt-5 font-[Poppins]">
              You Pay:{" "}
              <span className="font-[600]">
                NPR. {planType === "monthly" ? 100 : 80 * 12}
              </span>
            </div>
            <div className="w-full">
              <button
                onClick={() => handleSendPayment()}
                disabled={loadingSendPayment}
                className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-4"
              >
                Upgrade Plan
              </button>
            </div>
            <div className="mt-6 text-[#242424] font-[400] text-[14px] flex flex-col items-start justify-center gap-4">
              <div className="flex items-center justify-start gap-2">
                <div className="h-[20px] w-[20px] rounded-[50%] flex items-center justify-center border-[1px] border-primaryGreen">
                  <Check size={12} color="#08bd80" />
                </div>
                Tailored Experience
              </div>
              <div className="flex items-center justify-start gap-2">
                <div className="h-[20px] w-[20px] rounded-[50%] flex items-center justify-center border-[1px] border-primaryGreen">
                  <Check size={12} color="#08bd80" />
                </div>
                Premium Tests Access
              </div>
              <div className="flex items-center justify-start gap-2">
                <div className="h-[20px] w-[20px] rounded-[50%] flex items-center justify-center border-[1px] border-primaryGreen">
                  <Check size={12} color="#08bd80" />
                </div>
                Unlimited Custom Tests
              </div>
              <div className="flex items-center justify-start gap-2">
                <div className="h-[20px] w-[20px] rounded-[50%] flex items-center justify-center border-[1px] border-primaryGreen">
                  <Check size={12} color="#08bd80" />
                </div>
                Priority Support
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
