import React, { useEffect } from "react";
import { useGetCategories } from "../../Hooks/useGetCategories";

const Services = () => {
  const { getCategories, loadingCategories, categories } = useGetCategories();

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div id="courses" className="container py-12 lg:pt-20 lg:pb-12">
      <div className="flex flex-col items-center justify-center w-full">
        <div className="text-[#242424] font-[700] text-[36px] text-center">
          Achieve Mastery Through Testing !
        </div>
        <div className="text-[#7A7A7A] font-[Poppins] text-[18px] mt-2 text-center">
          Delve into tests tailored for different skill levels and subjects to
          find the perfect match for your educational goals.
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 mt-8">
          {!loadingCategories &&
            categories &&
            categories.map((category) => (
              <div className="border-b-primaryLightest border-b-2 rounded-[16px]">
                <div className="border-2 border-primaryLightest px-3 py-2 rounded-xl font-[Poppins] text-center">
                  {category.fullName}{" "}
                  <span className="uppercase">({category.name})</span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
