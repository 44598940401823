import { useState } from "react";

export const useGetLeaderBoardScore = () => {
  const [loadingLeaderBoardScore, setLoadingLeaderBoardScore] = useState(false);
  const [errorLeaderBoardScore, setErrorLeaderBoardScore] = useState(null);
  const [leaderBoardScore, setLeaderBoardScore] = useState([]);

  const getLeaderBoardScore = async () => {
    try {
      setLoadingLeaderBoardScore(true);
      setErrorLeaderBoardScore(null);
      setLeaderBoardScore([]);

      const response = await fetch(`/api/leaderboard_score`, {
        method: "GET",
      });

      const json = await response.json();

      if (response.ok) {
        setLeaderBoardScore(json.data);
      } else {
        setErrorLeaderBoardScore(json.msg);
      }

      setLoadingLeaderBoardScore(false);
    } catch (err) {
      setLoadingLeaderBoardScore(false);
      setErrorLeaderBoardScore(err.message);
    }
  };

  return {
    loadingLeaderBoardScore,
    errorLeaderBoardScore,
    leaderBoardScore,
    getLeaderBoardScore,
  };
};
