import { useState } from "react";

export const useGetUserPurchases = () => {
  const [userPurchases, setUserPurchases] = useState();
  const [loadingUserPurchases, setLoadingUserPurchases] = useState(true);
  const [errorUserPurchases, setErrorUserPurchases] = useState(null);

  const getUserPurchases = async () => {
    try {
      setUserPurchases(null);
      setErrorUserPurchases(null);
      setLoadingUserPurchases(true);

      const response = await fetch(`/api/get_user_purchases`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });

      const json = await response.json();

      if (response.ok) {
        setUserPurchases(json.data);
      } else {
        setErrorUserPurchases(json.msg);
      }

      setLoadingUserPurchases(false);
    } catch (err) {
      setErrorUserPurchases(err.message);
    }
  };

  return {
    userPurchases,
    loadingUserPurchases,
    errorUserPurchases,
    getUserPurchases,
  };
};
