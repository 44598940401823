import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { toast } from "react-toastify";

export const useLogout = () => {
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const logoutUser = async () => {
    setIsLoading(true);

    await fetch(`/api/logout`, {
      method: "POST",
      credentials: 'include',
      headers: { "Content-Type": "application/json" },
    });

    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
    toast.success("Logged out successfully !")
    setIsLoading(false);
  };

  return { logoutUser, isLoading };
};
