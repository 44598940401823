import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Hero from "../../Components/Hero/Hero";
import Services from "../../Components/Services/Services";
import ScholarshipBanner from "../../Components/ScholarshipBanner/ScholarshipBanner";
import Footer from "../../Components/Footer/Footer";
import WhyMeroTests from "../../Components/Home/WhyMeroTests";
import Pricing from "../../Components/Home/Pricing";
import FAQ from "../../Components/resuable/FAQ";
import InTouch from "../../Components/Home/InTouch";

const menuItem = [
  {
    question: "What types of mock tests are available on the platform?",
    answer: "We offer a wide range of mock tests covering entrance examinations for various fields such as information technology, management, engineering, medicine, and more in Nepal."
  },
  {
    question: "How do I register and start using the platform?",
    answer: "To begin, you need to register an account on our website. Once registered, you gain instant access to our mock tests and can start preparing for your desired examinations right away."
  },
  {
    question: "Can I access the tests from any device?",
    answer: "Absolutely! Our platform is designed to be compatible with all devices, whether you're using a desktop computer, laptop, tablet, or smartphone. As long as you have an internet connection, you can access our mock tests anytime, anywhere."
  },
  {
    question: "What makes your mock tests effective for exam preparation?",
    answer: "Our mock tests are crafted with precision to mimic the format, difficulty level, and timing constraints of the actual exams. By practicing with our mock tests, you can familiarize yourself with the exam environment, identify areas for improvement, and enhance your overall readiness for the real exam."
  },
  {
    question: "How can I track my progress on the platform?",
    answer: "We provide comprehensive tools to help you monitor your progress effectively. Through personalized dashboards and analytics features, you can track your performance across different courses along with test results, identify strengths and weaknesses, and make informed adjustments to your study strategy."
  },
  {
    question: "How is my privacy protected on your platform?",
    answer: "Protecting your privacy is paramount to us. We implement stringent security measures to safeguard your personal information. Our platform adheres to strict privacy policies and utilizes encryption protocols to ensure the confidentiality and integrity of your data."
  },
  {
    question: "What happens if I encounter a technical issue while using the platform?",
    answer: "In the rare event of encountering a technical issue, our dedicated support team is readily available to assist you. Simply reach out to us via our support channels, and we'll promptly address any technical issues you may encounter, ensuring a seamless user experience."
  },
];


const Homepage = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Services />
      <WhyMeroTests />
      <Pricing />
      <InTouch />
      <div>
        <FAQ menuItem={menuItem} />
      </div>
      {/* <div className="container my-4 my-md-5 px-4 px-md-3 px-xl-0">
        <ScholarshipBanner />
      </div> */}
      <Footer />
    </>
  );
};

export default Homepage;
