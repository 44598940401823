import React from "react";
import { Navigate, Route } from "react-router-dom";
import Login from "../Pages/Login/Login";
import { useAuthContext } from "../Hooks/useAuthContext";
import Verify from "../Pages/Verify/Verify";
import Register from "../Pages/Register/Register";
import ForgotPasswordRequest from "../Pages/ForgotPasswordRequest/ForgotPasswordRequest";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import Homepage from "../Pages/Homepage/Homepage";

const AuthRoutes = () => {
  const { user } = useAuthContext();
  return [
    <Route>
      <Route
        path="/login"
        element={user ? <Navigate to="/dashboard" /> : <Login />}
      />
      <Route
        path="/register"
        element={user ? <Navigate to="/dashboard" /> : <Register />}
      />
      <Route
        path="/forgot-password"
        element={
          user ? <Navigate to="/dashboard" /> : <ForgotPasswordRequest />
        }
      />
      <Route
        path="/reset-password/:token/:email"
        element={user ? <Navigate to="/dashboard" /> : <ForgotPassword />}
      />
      <Route
        path="/verify/:id/:token"
        element={
          <>
            <Homepage />
            <Verify />
          </>
        }
      />
    </Route>,
  ];
};

export default AuthRoutes;
