import React from "react";

const PurchaseCard = ({ purchaseData }) => {
  return (
    <div className="border-b-2 border-b-primaryLightest rounded-[20px] w-full">
      <div className="border-2 border-primaryLightest rounded-2xl p-5 w-full">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full">
          <div>
            <div className="font-[Poppins] text-[16px] font-[600] text-primaryDarker">
              {purchaseData.productType === "test"
                ? purchaseData.testData.title
                : purchaseData.pId === "sub_monthly"
                ? "MeroTests - Monthly Subscription"
                : "MeroTests - Annually Subscription"}
            </div>
            <div className="mt-2 text-[14px] font-[Poppins] font-[600]">
              NPR. {purchaseData.amount}
            </div>
            <div className="mt-2 text-[12px] text-gray-400 font-[Poppins]">
              Txn Id: {purchaseData.paymentId}
            </div>
            <div className="mt-2 text-[12px] font-[500] text-primaryBlue font-[Poppins]">
              {new Date(purchaseData.updatedAt).toDateString() +
                " " +
                new Date(purchaseData.updatedAt).toLocaleTimeString()}
            </div>
            <div className="mt-2 border-[1px] border-primaryLight w-fit px-2 py-1 rounded-lg text-primaryDark text-[12px] uppercase font-[Poppins] font-[500]">
              {purchaseData.productType}
            </div>
          </div>
          <div className="mt-4 lg:mt-0 bg-primaryBlue text-white px-3 py-[6px] rounded-lg text-[12px] font-[Poppins] capitalize">
            {purchaseData.status}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseCard;
