import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import Sidebar from "../../Components/Sidebar/Sidebar";
import TestCard from "../../Components/resuable/TestCard";
import { useGetUserData } from "../../Hooks/useGetUserData";
import trophyImg from "../../Assets/img/leaderboard/trophy.png";
import defaultAvatar from "../../Assets/img/leaderboard/avatar.png";
import { baseUrl } from "../../Utils/BaseUrl";

import { useDeleteCustomTest } from "../../Hooks/useDeleteCustomTest";
import { useAddBookmarkTest } from "../../Hooks/bookmark/useAddBookmarkTest";
import { useRemoveBookmarkTest } from "../../Hooks/bookmark/useRemoveBookmarkTest";
import { useGetLeaderBoardTime } from "../../Hooks/leaderboard/useGetLeaderBoardTime";
import { useGetLeaderBoardScore } from "../../Hooks/leaderboard/useGetLeaderBoardScore";
import { TestModalContexts } from "../../Contexts/TestModalContext";
import { LoadingContexts } from "../../Contexts/LoadingContext";

const Leaderboard = () => {
  const [activeTab, setActiveTab] = useState("score");

  const { getUserData, userData } = useGetUserData();

  let incompleteTestsCount = 0;

  const {
    getLeaderBoardScore,
    loadingLeaderBoardScore,
    errorLeaderBoardScore,
    leaderBoardScore,
  } = useGetLeaderBoardScore();

  const {
    getLeaderBoardTime,
    loadingLeaderBoardTime,
    errorLeaderBoardTime,
    leaderBoardTime,
  } = useGetLeaderBoardTime();

  const {
    addBookmarkTest,
    loadingAddBookmarkTest,
    errorAddBookmarkTest,
    successAddBookmarkTest,
  } = useAddBookmarkTest();

  const {
    removeBookmarkTest,
    loadingRemoveBookmarkTest,
    errorRemoveBookmarkTest,
    successRemoveBookmarkTest,
  } = useRemoveBookmarkTest();

  const {
    deleteCustomTest,
    loadingDeleteCustomTest,
    errorDeleteCustomTest,
    successDeleteCustomTest,
  } = useDeleteCustomTest();

  const { isLoadingSomething, setIsLoadingSomething } =
    useContext(LoadingContexts);

  useEffect(() => {
    if (successDeleteCustomTest) {
      toast.success(successDeleteCustomTest);
      getUserData();
    }
  }, [successDeleteCustomTest]);

  useEffect(() => {
    if (errorDeleteCustomTest) {
      toast.error(errorDeleteCustomTest);
    }
  }, [errorDeleteCustomTest]);

  useEffect(() => {
    if (successAddBookmarkTest) {
      toast.success(successAddBookmarkTest.msg);
      getUserData();
    }
  }, [successAddBookmarkTest]);

  useEffect(() => {
    if (errorAddBookmarkTest) {
      toast.error(errorAddBookmarkTest);
    }
  }, [errorAddBookmarkTest]);

  useEffect(() => {
    if (successRemoveBookmarkTest) {
      toast.success(successRemoveBookmarkTest);
      getUserData();
    }
  }, [successRemoveBookmarkTest]);

  useEffect(() => {
    if (errorRemoveBookmarkTest) {
      toast.error(errorRemoveBookmarkTest);
    }
  }, [errorRemoveBookmarkTest]);

  useEffect(() => {
    getUserData();
    getLeaderBoardScore();
    getLeaderBoardTime();
  }, []);

  const parseTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);

    const minutes = Math.floor((seconds % 3600) / 60);

    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes}:${remainingSeconds}`;
  };

  useEffect(() => {
    if (
      (loadingLeaderBoardScore ||
        loadingLeaderBoardTime ||
        loadingAddBookmarkTest ||
        loadingRemoveBookmarkTest ||
        loadingDeleteCustomTest) &&
      !isLoadingSomething
    ) {
      setIsLoadingSomething(true);
    }

    if (
      !loadingLeaderBoardScore &&
      !loadingLeaderBoardTime &&
      !loadingAddBookmarkTest &&
      !loadingRemoveBookmarkTest &&
      !loadingDeleteCustomTest &&
      isLoadingSomething
    ) {
      setIsLoadingSomething(false);
    }
  }, [
    loadingLeaderBoardScore,
    loadingLeaderBoardTime,
    loadingAddBookmarkTest,
    loadingRemoveBookmarkTest,
    loadingDeleteCustomTest,
  ]);

  return (
    <div className="flex items-start justify-center w-full pb-[80px] lg:pb-0">
      <div className="container grid grid-cols-1 lg:grid-cols-12 gap-10 py-8">
        <div className="hidden lg:block col-span-3 relative">
          <Sidebar />
        </div>
        <div className="col-span-1 lg:col-span-6 flex flex-col items-center justify-start w-full">
          <div>
            <img src={trophyImg} alt="trophy" className="w-[100px]" />
          </div>
          <div className="text-[#242424] font-[600] text-[28px] mt-2">
            LeaderBoard
          </div>
          <div className="text-center text-[#3A3A3A] font-[Poppins] font-[400] leading-7 max-w-[600px] mt-2">
            Compete and climb the ranks! Check out the leaderboard to see who is
            leading the pack in the community.
          </div>
          <div className="border-b-[3px] border-b-primaryDark rounded-[20px]">
            <div className="mt-6 grid grid-cols-2 gap-2 px-2 py-[6px] border-2 border-primaryDark rounded-2xl text-[#3A3A3A] font-[Poppins] font-[500] text-[13px]">
              <button
                onClick={() => setActiveTab("score")}
                className={`${
                  activeTab === "score" && "!bg-primaryDark !text-white"
                } hover:bg-primaryDark hover:text-white px-4 py-2 rounded-xl transition-all ease-in-out duration-300`}
              >
                Score
              </button>
              <button
                onClick={() => setActiveTab("time")}
                className={`${
                  activeTab === "time" && "!bg-primaryDark !text-white"
                } hover:bg-primaryDark hover:text-white px-4 py-2 rounded-xl transition-all ease-in-out duration-300`}
              >
                Time
              </button>
            </div>
          </div>
          <div className="mt-12 flex flex-col items-start justify-center w-full gap-6">
            {!loadingLeaderBoardScore &&
              !errorLeaderBoardScore &&
              activeTab === "score" &&
              leaderBoardScore &&
              leaderBoardScore.map((data, index) => (
                <div className="w-full grid grid-cols-12 gap-4" key={index}>
                  <div className="col-span-2 hidden lg:flex items-center justify-end w-full">
                    <div
                      className={`${index === 0 && "!border-b-blueLight"} ${
                        (index === 1 || index === 2) && "!border-b-blueLight"
                      } border-b-4  rounded-[20px] border-b-primaryLightest`}
                    >
                      <div
                        className={`${
                          index === 0 &&
                          "!border-primaryBlue !bg-primaryBlue !text-white"
                        } ${
                          (index === 1 || index === 2) && "!border-primaryBlue"
                        } border-2 border-primaryLighter flex items-center justify-center w-[60px] h-[60px] rounded-2xl font-[Poppins] font-[600] text-[20px]`}
                      >
                        #{index + 1}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${index === 0 && "!border-b-blueLight"} ${
                      (index === 1 || index === 2) && "!border-b-blueLight"
                    } border-b-primaryLightest col-span-12 lg:col-span-10 border-b-4 rounded-[20px] w-full`}
                  >
                    <div
                      className={`${
                        index === 0 &&
                        "!border-primaryBlue !bg-primaryBlue !text-white"
                      } ${
                        (index === 1 || index === 2) && "!border-primaryBlue"
                      } flex items-center border-primaryLighter justify-between border-2 rounded-2xl h-[60px] px-4 font-[Poppins] font-[600] w-full`}
                    >
                      <div className="flex items-center justify-center gap-3">
                        <div>
                          <img
                            src={
                              data.profilePic
                                ? `${baseUrl}/${data.profilePic}`
                                : defaultAvatar
                            }
                            alt="avatar"
                            className="w-[40px] h-[40px] rounded-[50%]"
                          />
                        </div>
                        <div>{data.name}</div>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <div>{Number(data.averageScore).toFixed(2)} pts</div>
                        <div className="text-[12px]">
                          {data.totalTests} test/s
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {!loadingLeaderBoardTime &&
              !errorLeaderBoardTime &&
              activeTab === "time" &&
              leaderBoardTime &&
              leaderBoardTime.map((data, index) => (
                <div className="w-full grid grid-cols-12 gap-4" key={index}>
                  <div className="col-span-2 hidden lg:flex items-center justify-end w-full">
                    <div
                      className={`${index === 0 && "!border-b-blueLight"} ${
                        (index === 1 || index === 2) && "!border-b-blueLight"
                      } border-b-4  rounded-[20px] border-b-primaryLightest`}
                    >
                      <div
                        className={`${
                          index === 0 &&
                          "!border-primaryBlue !bg-primaryBlue !text-white"
                        } ${
                          (index === 1 || index === 2) && "!border-primaryBlue"
                        } border-2 border-primaryLighter flex items-center justify-center w-[60px] h-[60px] rounded-2xl font-[Poppins] font-[600] text-[20px]`}
                      >
                        #{index + 1}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${index === 0 && "!border-b-blueLight"} ${
                      (index === 1 || index === 2) && "!border-b-blueLight"
                    } border-b-primaryLightest col-span-12 lg:col-span-10 border-b-4 rounded-[20px] w-full`}
                  >
                    <div
                      className={`${
                        index === 0 &&
                        "!border-primaryBlue !bg-primaryBlue !text-white"
                      } ${
                        (index === 1 || index === 2) && "!border-primaryBlue"
                      } flex items-center border-primaryLighter justify-between border-2 rounded-2xl h-[60px] px-4 font-[Poppins] font-[600] w-full`}
                    >
                      <div className="flex items-center justify-center gap-3">
                        <div>
                          <img
                            src={
                              data.profilePic
                                ? `${baseUrl}/${data.profilePic}`
                                : defaultAvatar
                            }
                            alt="avatar"
                            className="w-[40px] h-[40px] rounded-[50%]"
                          />
                        </div>
                        <div>{data.name}</div>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <div>
                          {parseTime(Math.trunc(data.averageTime))} hr/s
                        </div>
                        <div className="text-[12px]">
                          {data.totalTests} test/s
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="col-span-1 lg:col-span-3 flex items-start justify-start flex-col w-full gap-10">
          <div className="border-2 border-primaryLightest rounded-2xl p-5 w-full">
            <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[500]">
              What are leaderboards ?
            </div>
            <div className="text-[#242424] text-[18px] font-[600] mt-2">
              Challenge Champions !
            </div>
            <div className="text-[#3A3A3A] font-[Poppins] font-[400] text-[14px] mt-4">
              See where you stand! Compete with others based on time and marks.
              Ready to rise to the top?
            </div>
            <Link
              to={"/courses"}
              className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-6"
            >
              Start Now
            </Link>
          </div>
          <div>
            <div>
              {userData &&
                userData.activeCustomTests.length !== 0 &&
                userData.activeCustomTests.map((data, index) => {
                  if (
                    (data.currentTime !== 0 ||
                      data.currentScore !== 0 ||
                      data.currentQuestion !== 0) &&
                    incompleteTestsCount < 1
                  ) {
                    incompleteTestsCount++;
                    return (
                      <TestCard
                        testData={data}
                        userData={userData}
                        testType="custom"
                        addBookmarkTest={addBookmarkTest}
                        removeBookmarkTest={removeBookmarkTest}
                        loadingAddBookmarkTest={loadingAddBookmarkTest}
                        loadingRemoveBookmarkTest={loadingRemoveBookmarkTest}
                        deleteCustomTest={deleteCustomTest}
                        loadingDeleteCustomTest={loadingDeleteCustomTest}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              {userData &&
                userData.activePublicTests.length !== 0 &&
                userData.activePublicTests.map((data, index) => {
                  if (
                    (data.currentTime !== 0 ||
                      data.currentScore !== 0 ||
                      data.currentQuestion !== 0) &&
                    incompleteTestsCount < 1
                  ) {
                    incompleteTestsCount++;
                    return (
                      <TestCard
                        testData={data}
                        userData={userData}
                        testType="public"
                        addBookmarkTest={addBookmarkTest}
                        removeBookmarkTest={removeBookmarkTest}
                        loadingAddBookmarkTest={loadingAddBookmarkTest}
                        loadingRemoveBookmarkTest={loadingRemoveBookmarkTest}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
