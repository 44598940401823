"use client";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./accordion";

function FAQ({ menuItem }) {
  return (
    <section id="faq" class="px-4 lg:px-16 py-12 w-full lg:w-[75%] mx-auto">
      <div class="container mx-auto md:4/4">
        <div className="flex flex-col items-center justify-center w-full">
          <div className="text-[#242424] font-[700] text-[36px] text-center">
            Frequently Asked{" "}
            <span className="text-primaryBlue">Questions (FAQ) ?</span>
          </div>
          {/* <div className="text-[#7A7A7A] font-[Poppins] text-[18px] mt-2 text-center max-w-[1000px]">
          Unlock your full potential with our flexible pricing plans designed to
          fit your needs and budget. Whether you’re a casual learner or a
          serious test-taker, we have a plan that can help you excel.
        </div> */}
        </div>
        <div className="font-[Poppins] mt-8">
          <Accordion
            type="single"
            defaultValue={menuItem[0].question}
            collapsible
          >
            {menuItem.map((item, index) => (
              <AccordionItem value={item.question} className="text-start">
                <AccordionTrigger className="text-start">
                  {item.question}
                </AccordionTrigger>
                <AccordionContent className="AccordionContent text-[15px] leading-7">
                  <div dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
