import React from "react";
import { Route } from "react-router-dom";
import Homepage from "../Pages/Homepage/Homepage";
import Dashboard from "../Pages/Dashboard/Dashboard";
import { useAuthContext } from "../Hooks/useAuthContext";
import Login from "../Pages/Login/Login";
import Phonebar from "../Components/Phonebar/Phonebar";
import Navbar from "../Components/Navbar/Navbar";
import NotFound from "../Components/NotFound/NotFound";
import ActiveTest from "../Pages/ActiveTest/ActiveTest";
import Profile from "../Pages/Profile/Profile";
import Result from "../Pages/Result/Result";
import Leaderboard from "../Pages/Leaderboard/Leaderboard";
import Courses from "../Pages/Courses/Courses";
import Course from "../Pages/Course/Course";

const MainRoutes = () => {
  const { user } = useAuthContext();

  return [
    <Route>
      <Route
        path="/"
        element={
          <>
            <Homepage />
          </>
        }
      />
      {/* <Route
        path="/scholarship"
        element={
          <>
            <Navbar />
            <Scholarship />
            <Footer />
          </>
        }
      /> */}
      <Route
        path="/profile"
        element={
          user ? (
            <>
              <Navbar />
              <Profile />
              <Phonebar />
            </>
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/leaderboard"
        element={
          user ? (
            <>
              <Navbar />
              <Leaderboard />
              <Phonebar />
            </>
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/courses"
        element={
          user ? (
            <>
              <Navbar />
              <Courses />
              <Phonebar />
            </>
          ) : (
            <Login />
          )
        }
      />
      {/* <Route
        path="/notice/:slug"
        element={
          <>
            <Navbar />
            <Notice />
            <Footer />
          </>
        }
      />
      <Route
        path="/notices"
        element={
          <>
            <Navbar />
            <Notices />
            <Footer />
          </>
        }
      /> */}
      <Route
        path="/dashboard"
        element={
          user ? (
            <>
              <Navbar />
              <Dashboard />
              <Phonebar />
            </>
          ) : (
            <Login />
          )
        }
      />
      <Route
        path={`/course/:category`}
        element={
          user ? (
            <>
              <Navbar />
              <Course />
              <Phonebar />
            </>
          ) : (
            <Login />
          )
        }
        exact
      />
      <Route
        path="/public_test/:id"
        element={
          user ? (
            <>
              <ActiveTest type="public" />
              {/* <Phonebar /> */}
            </>
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/custom_test/:id"
        element={
          user ? (
            <>
              <ActiveTest type="custom" />
              {/* <Phonebar /> */}
            </>
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/public_result/:id"
        element={
          user ? (
            <>
              <Result type="public" />
              {/* <Phonebar /> */}
            </>
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/custom_result/:id"
        element={
          user ? (
            <>
              <Result type="custom" />
              {/* <Phonebar /> */}
            </>
          ) : (
            <Login />
          )
        }
      />
      <Route path="*" element={<NotFound />} />
    </Route>,
  ];
};

export default MainRoutes;
