import React, { useContext } from "react";
import primaryLogo from "../../Assets/img/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../../Hooks/useAuthContext";
// import defaultAvatar from "../../Assets/img/def_avt.jpeg";
import defaultAvatar from "../../Assets/img/leaderboard/avatar.png";

import { useLogout } from "../../Hooks/useLogout";
import { LogOut } from "lucide-react";
import premiumImg from "../../Assets/img/navbar/premium.png";
import { baseUrl } from "../../Utils/BaseUrl";
import { LoadingContexts } from "../../Contexts/LoadingContext";
import ClipLoader from "react-spinners/ClipLoader";

const Navbar = () => {
  const { user } = useAuthContext();
  const { logoutUser, isLoading } = useLogout();
  const { isLoadingSomething } = useContext(LoadingContexts);

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div
      className="w-full border-b-[1px] border-b-[rgba(0,0,0,0.05)] py-4"
      id="navbar"
    >
      <div className="container flex items-center justify-center lg:justify-between w-full">
        <div
          className={
            currentPath !== "/" && !currentPath.includes("/verify")
              ? "w-full lg:w-[25%] flex items-center justify-center pe-0 lg:pe-10"
              : ""
          }
        >
          <Link to="/" className="flex flex-col items-start justify-center">
            <div className="text-[30px] font-[600] text-primaryBlue leading-8">
              mero
              <span className="text-primaryDark">
                test<span className="text-primaryGreen">s.</span>
              </span>
              <span className="px-[6px] py-[2px] text-[10px] bg-gradient-to-r from-primaryBlue to-primaryGreen font-[Poppins] text-white rounded-md ms-1">
                BETA
              </span>
            </div>
            <div className="font-[Poppins] text-[10.5px] font-[500]">
              from{" "}
              <span className="bg-gradient-to-l from-[#48bdc3] to-[#0e62ac] text-white px-1 py-[2px] font-[600] rounded-[2px]">
                Binary Entrance
              </span>
            </div>
          </Link>
        </div>
        {(currentPath === "/" || currentPath.includes("/verify")) && (
          <div className="hidden lg:flex items-center justify-center gap-12 font-[Poppins] font-[500]">
            <div
              onClick={() => {
                window.scrollTo({
                  top: document.getElementById("courses")?.offsetTop,
                  behavior: "smooth",
                });
              }}
              className="hover:text-primaryBlue transition-all ease-in-out duration-300 cursor-pointer"
            >
              Courses
            </div>
            <div
              onClick={() => {
                window.scrollTo({
                  top: document.getElementById("whymerotests")?.offsetTop,
                  behavior: "smooth",
                });
              }}
              className="hover:text-primaryBlue transition-all ease-in-out duration-300 cursor-pointer"
            >
              Features
            </div>
            <div
              onClick={() => {
                window.scrollTo({
                  top: document.getElementById("pricing")?.offsetTop,
                  behavior: "smooth",
                });
              }}
              className="hover:text-primaryBlue transition-all ease-in-out duration-300 cursor-pointer"
            >
              Pricing
            </div>
            <div
              onClick={() => {
                window.scrollTo({
                  top: document.getElementById("faq")?.offsetTop,
                  behavior: "smooth",
                });
              }}
              className="hover:text-primaryBlue transition-all ease-in-out duration-300 cursor-pointer"
            >
              FAQs
            </div>
            <div
              onClick={() => {
                window.scrollTo({
                  top: document.getElementById("getintouch")?.offsetTop,
                  behavior: "smooth",
                });
              }}
              className="hover:text-primaryBlue transition-all ease-in-out duration-300 cursor-pointer"
            >
              Support
            </div>
          </div>
        )}
        {currentPath === "/" || currentPath.includes("/verify") ? (
          <Link
            to="/dashboard"
            className="hidden lg:flex bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] items-center justify-center gap-3 w-fit"
          >
            Get Started For Free
          </Link>
        ) : (
          <>
            {isLoadingSomething && (
              <div className="bg-blue-200 px-3 py-1.5 rounded-lg text-[13px] font-[500] text-blue-600 hidden lg:flex items-center justify-center gap-2">
                <ClipLoader color="#2563eb" size={16} />
                Some data are being loaded...
              </div>
            )}

            <div
              className={`${
                currentPath !== "/" ? "w-full lg:w-[25%]" : ""
              } hidden lg:flex items-center justify-center gap-10`}
            >
              {user && user.type === "free" && (
                <Link
                  to="/purchase-subscription"
                  state={{
                    previousLocation: location,
                  }}
                  className="gradient-text hover:text-[#1e78ff] font-[Inter] font-[700] text-[14px] uppercase flex items-center justify-center gap-2 tracking-[0.025em] transition-all ease-in-out duration-300"
                >
                  <img src={premiumImg} alt="premium" className="w-[40px]" />
                  Go Premium
                </Link>
              )}
              {user && user.type === "paid" && (
                <div className="invisible text-primaryBlue hover:text-[#1e78ff] font-[Inter] font-[700] text-[14px] uppercase flex items-center justify-center gap-2 tracking-[0.025em] transition-all ease-in-out duration-300">
                  <img src={premiumImg} alt="premium" className="w-[40px]" />
                  Go Premium
                </div>
              )}
              <Link
                to={"/profile"}
                className="w-[50px] h-[50px] flex items-center justify-center rounded-[50%] overflow-hidden hover:scale-105 transition-all ease-in-out duration-300"
              >
                <img
                  src={
                    user && user.profilePic
                      ? `${baseUrl}/${user.profilePic}`
                      : defaultAvatar
                  }
                  alt="profile"
                  className="min-w-full min-h-full flex-shrink-0"
                />
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;
