import React from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useGetNewsletter } from "../../Hooks/useGetNewsletter";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { X } from "lucide-react";

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "1050",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "16px",
    padding: "0px",
    maxWidth: "500px",
    overflow: "hidden",
    width: "85%",
  },
};

const NewsletterModal = () => {
  const navigate = useNavigate();
  const [updateButton, setUpdateButton] = useState(false);

  const {
    getNewsletter,
    newsletterData,
    newsletterLoading,
    setNewsletterLoading,
    newTestsState,
    setNewTestsState,
    alertsState,
    setAlertsState,
    noticesState,
    setNoticesState,
    newsState,
    setNewsState,
  } = useGetNewsletter();

  useEffect(() => {
    setNewsletterLoading(true);
    getNewsletter();
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleNewsletterUpdate = async () => {
    setUpdateButton(true);
    await fetch(`/api/update_newsletter`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        newsState,
        alertsState,
        noticesState,
        newTestsState,
      }),
    });
    toast.success("Your preferences are updated !");
    navigate(-1);
  };

  return (
    <Modal
      isOpen={true}
      style={modalStyles}
      closeTimeoutMS={250}
      onRequestClose={() => navigate(-1)}
    >
      <div className="max-h-[90vh] overflow-scroll">
        <div className="p-6 w-full flex flex-col items-center justify-center">
          <div className="w-full flex items-center justify-between">
            <div className="text-[#242424] font-[600] text-[24px]">
              Newsletter Preferences
            </div>
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="h-[30px] w-[30px] rounded-[50%] bg-primaryLightest flex items-center justify-center"
            >
              <X />
            </button>
          </div>
          {newsletterLoading ? (
            <div className="text-[15px] text-gray-500 mt-10">
              Loading preferenences, please wait !
            </div>
          ) : (
            <>
              {newsletterData != null ? (
                <>
                  <div className="w-full flex items-center justify-between mt-8">
                    <div className="flex items-start justify-start gap-3 w-full">
                      <input
                        type="checkbox"
                        checked={newsState}
                        onChange={() => setNewsState(!newsState)}
                        className="w-[15px] h-[15px] mt-1"
                      />
                      <div className="flex items-start justify-center flex-col w-full">
                        <div className="text-[14px] font-[Poppins] font-[500]">
                          News Updates
                        </div>
                        <div className="mt-2 text-[14px] text-primaryDark">
                          You will get regular news update regarding the
                          platform as well as faculty.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-between mt-8">
                    <div className="flex items-start justify-start gap-3 w-full">
                      <input
                        type="checkbox"
                        checked={alertsState}
                        onChange={() => setAlertsState(!alertsState)}
                        className="w-[15px] h-[15px] mt-1"
                      />
                      <div className="flex items-start justify-center flex-col w-full">
                        <div className="text-[14px] font-[Poppins] font-[500]">
                          Account Alerts
                        </div>
                        <div className="mt-2 text-[14px] text-primaryDark">
                          You will get updates regarding changes to your account
                          or account activity.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-between mt-8">
                    <div className="flex items-start justify-start gap-3 w-full">
                      <input
                        type="checkbox"
                        checked={noticesState}
                        onChange={() => setNoticesState(!noticesState)}
                        className="w-[15px] h-[15px] mt-1"
                      />
                      <div className="flex items-start justify-center flex-col w-full">
                        <div className="text-[14px] font-[Poppins] font-[500]">
                          Notices
                        </div>
                        <div className="mt-2 text-[14px] text-primaryDark">
                          You will get updates regarding notices issued by
                          university or other institutions.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-between mt-8">
                    <div className="flex items-start justify-start gap-3 w-full">
                      <input
                        type="checkbox"
                        checked={newTestsState}
                        onChange={() => setNewTestsState(!newTestsState)}
                        className="w-[15px] h-[15px] mt-1"
                      />
                      <div className="flex items-start justify-center flex-col w-full">
                        <div className="text-[14px] font-[Poppins] font-[500]">
                          New Tests
                        </div>
                        <div className="mt-2 text-[14px] text-primaryDark">
                          You will get notified when new tests are added in the
                          platform.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-center mt-8 gap-6">
                    <button
                      onClick={() => navigate(-1)}
                      className="w-full border-b-[3px] border-b-primaryLightest rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300"
                    >
                      <div className="text-gray-500 px-5 py-3 rounded-2xl border-2 border-primaryLightest disabled:border-primaryLightest text-[14px] font-[600] flex items-center justify-center gap-3 w-full">
                        Cancel
                      </div>
                    </button>
                    <button
                      onClick={handleNewsletterUpdate}
                      disabled={updateButton}
                      className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full"
                    >
                      Update
                    </button>
                  </div>
                </>
              ) : (
                <div className="text-[15px] text-gray-500 mt-10">
                  Your preferenences was not found. Please contact our support
                  team for further assistance !
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NewsletterModal;
