import { useState } from "react";

export const useGetSendPayment = () => {
  const [sendPaymentData, setSendPaymentData] = useState(null);
  const [loadingSendPayment, setLoadingSendPayment] = useState(false);
  const [errorSendPayment, setErrorSendPayment] = useState(null);

  const getSendPayment = async (pid, amount, name, email, phone, productType) => {
    try {
      setLoadingSendPayment(true);
      setErrorSendPayment(null);
      setSendPaymentData(null);

      const response = await fetch(`/api/initiate_payment`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          pid,
          amount,
          name,
          email,
          phone,
          productType
        }),
      });

      const json = await response.json();

      if (!response.ok) {
        setLoadingSendPayment(false);
        setErrorSendPayment(json.msg);
      }

      if (response.ok) {
        setErrorSendPayment(null);
        setSendPaymentData(json.data);
      }

      return json;
    } catch (err) {
      setLoadingSendPayment(false);
      setErrorSendPayment(err.message);
    }
  };

  return {
    getSendPayment,
    sendPaymentData,
    loadingSendPayment,
    errorSendPayment,
  };
};
