import React, { useState } from "react";
import { createContext } from "react";

export const LoadingContexts = createContext();

const LoadingContext = ({ children }) => {
  const [isLoadingSomething, setIsLoadingSomething] = useState(true);
  return (
    <LoadingContexts.Provider
      value={{ isLoadingSomething, setIsLoadingSomething }}
    >
      {children}
    </LoadingContexts.Provider>
  );
};

export default LoadingContext;
