import { useState } from "react";

export const useVerify = () => {
  const [errorVerifyData, setErrorVerifyData] = useState(null);
  const [verifyData, setVerifyData] = useState(null);
  const VerifyUser = async (id, token) => {
    const response = await fetch(`/api/verify-user/${id}/${token}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    const data = await response.json();

    if (!response.ok) {
      setVerifyData(null);
      setErrorVerifyData(data.msg);
      return;
    } else {
      setErrorVerifyData(null);
      setVerifyData(data.msg);
      return;
    }
  };

  return { VerifyUser, errorVerifyData, verifyData };
};
