import { useState } from "react";

export const useGetPublicSets = () => {
  const [publicTests, setPublicTests] = useState([]);
  const [publicUserData, setPublicUserData] = useState();
  const [publicError, setPublicError] = useState(null);
  const [loadingPublic, setLoadingPublic] = useState(true);

  const getPublicSets = async (category) => {
    setPublicError(null);
    setLoadingPublic(true);

    const response = await fetch(`/api/get_public_set?category=${category}`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });

    const json = await response.json();

    if (response.ok) {
      setPublicTests(json.publicsets);
      setPublicUserData(json.user);
    } else {
      setPublicError(json.msg);
    }

    setLoadingPublic(false);
  };
  return {
    getPublicSets,
    publicTests,
    publicUserData,
    publicError,
    loadingPublic,
    setLoadingPublic,
  };
};
