import { toast } from "react-toastify";
import { Bell, ChevronDown, Plus } from "lucide-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import blueMask from "../../Assets/img/blueMask.png";
import greenMask from "../../Assets/img/greenMask.png";
import Sidebar from "../../Components/Sidebar/Sidebar";
import TestCard from "../../Components/resuable/TestCard";
import { TestModalContexts } from "../../Contexts/TestModalContext";

import { useGetCustomSets } from "../../Hooks/useGetCustomSets";
import { useGetCategories } from "../../Hooks/useGetCategories";
import { useGetPublicSets } from "../../Hooks/useGetPublicSets";
import { useDeleteCustomTest } from "../../Hooks/useDeleteCustomTest";
import { useAddBookmarkTest } from "../../Hooks/bookmark/useAddBookmarkTest";
import { useRemoveBookmarkTest } from "../../Hooks/bookmark/useRemoveBookmarkTest";
import { LoadingContexts } from "../../Contexts/LoadingContext";

const Course = () => {
  const {
    getPublicSets,
    publicTests,
    publicUserData,
    publicError,
    loadingPublic,
    setLoadingPublic,
  } = useGetPublicSets();

  const {
    getCustomSets,
    customTests,
    customUserData,
    customError,
    loadingCustom,
    setLoadingCustom,
  } = useGetCustomSets();

  const { getCategories, loadingCategories, categories } = useGetCategories();

  const {
    addBookmarkTest,
    loadingAddBookmarkTest,
    errorAddBookmarkTest,
    successAddBookmarkTest,
  } = useAddBookmarkTest();

  const {
    removeBookmarkTest,
    loadingRemoveBookmarkTest,
    errorRemoveBookmarkTest,
    successRemoveBookmarkTest,
  } = useRemoveBookmarkTest();

  const {
    deleteCustomTest,
    loadingDeleteCustomTest,
    errorDeleteCustomTest,
    successDeleteCustomTest,
  } = useDeleteCustomTest();

  const { isLoadingSomething, setIsLoadingSomething } =
    useContext(LoadingContexts);

  const location = useLocation();

  const params = useParams();
  const category = params["category"];

  const toastTriggered = useRef(false);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [courseTab, setCourseTab] = useState("all");

  useEffect(() => {
    if (customError || publicError) {
      navigate("/courses");
    }
  }, [customError, publicError]);

  const { redirectedFromTestModal, setRedirectedFromTestModal } =
    useContext(TestModalContexts);

  const [testsExpanded, setTestsExpanded] = useState({
    public: false,
    custom: false,
  });

  useEffect(() => {
    if (successDeleteCustomTest) {
      toast.success(successDeleteCustomTest);
      setLoadingPublic(true);
      setLoadingCustom(true);

      getCustomSets(category);
      getPublicSets(category);
      getCategories();
    }
  }, [successDeleteCustomTest]);

  useEffect(() => {
    if (errorDeleteCustomTest) {
      toast.error(errorDeleteCustomTest);
    }
  }, [errorDeleteCustomTest]);

  useEffect(() => {
    if (successAddBookmarkTest) {
      toast.success(successAddBookmarkTest.msg);
      setLoadingPublic(true);
      setLoadingCustom(true);

      getCustomSets(category);
      getPublicSets(category);
      getCategories();
    }
  }, [successAddBookmarkTest]);

  useEffect(() => {
    if (errorAddBookmarkTest) {
      toast.error(errorAddBookmarkTest);
    }
  }, [errorAddBookmarkTest]);

  useEffect(() => {
    if (successRemoveBookmarkTest) {
      toast.success(successRemoveBookmarkTest);
      setLoadingPublic(true);
      setLoadingCustom(true);

      getCustomSets(category);
      getPublicSets(category);
      getCategories();
    }
  }, [successRemoveBookmarkTest]);

  useEffect(() => {
    if (errorRemoveBookmarkTest) {
      toast.error(errorRemoveBookmarkTest);
    }
  }, [errorRemoveBookmarkTest]);

  useEffect(() => {
    setLoadingPublic(true);
    setLoadingCustom(true);

    getCustomSets(category);
    getPublicSets(category);
    getCategories();
  }, [category]);

  useEffect(() => {
    if (!toastTriggered.current) {
      const toastMsg = searchParams.get("toastMsg");
      const toastType = searchParams.get("toastType")
        ? searchParams.get("toastType")
        : "info";
      if (toastMsg) {
        toast(toastMsg, {
          type: toastType,
        });
        navigate(`/course/${category}`);
      }
      toastTriggered.current = true;
    }
  }, [searchParams]);

  useEffect(() => {
    if (redirectedFromTestModal !== false) {
      setRedirectedFromTestModal(false);
      getCustomSets(category);
    }
  }, [redirectedFromTestModal]);

  useEffect(() => {
    if (
      (loadingPublic ||
        loadingCustom ||
        loadingCategories ||
        loadingAddBookmarkTest ||
        loadingRemoveBookmarkTest ||
        loadingDeleteCustomTest) &&
      !isLoadingSomething
    ) {
      setIsLoadingSomething(true);
    }

    if (
      !loadingPublic &&
      !loadingCustom &&
      !loadingCategories &&
      !loadingAddBookmarkTest &&
      !loadingRemoveBookmarkTest &&
      !loadingDeleteCustomTest &&
      isLoadingSomething
    ) {
      setIsLoadingSomething(false);
    }
  }, [
    loadingPublic,
    loadingCustom,
    loadingCategories,
    loadingAddBookmarkTest,
    loadingRemoveBookmarkTest,
    loadingDeleteCustomTest,
  ]);

  return (
    <div className="flex items-start justify-center w-full pb-[80px] lg:pb-0">
      <div className="container grid grid-cols-1 lg:grid-cols-12 gap-10 py-8">
        <div className="hidden lg:block col-span-3 relative">
          <Sidebar />
        </div>
        <div className="col-span-1 lg:col-span-9 flex flex-col items-start justify-start w-full">
          <div className="w-full flex items-center justify-between">
            <div className="flex items-start justify-center flex-col ms-2">
              <div className="text-[#242424] font-[600] text-[18px]">
                Current Public Tests
              </div>
              <div className="text-[#7A7A7A] font-[Poppins] text-[14px] mt-1 flex items-center justify-start gap-1">
                <Link
                  to="/dashboard"
                  className="hover:text-primaryDarker transition-all ease-in-out duration-300"
                >
                  Dashboard
                </Link>
                &gt;
                <Link
                  to={`/course/${category}`}
                  className="uppercase hover:text-primaryDarker transition-all ease-in-out duration-300"
                >
                  {category}
                </Link>
              </div>
            </div>
            <div className="flex items-center justify-start gap-3">
              <span className="hidden lg:block font-[Poppins] text-[14px] text-[#7A7A7A]">
                Choose Department:
              </span>
              <div className="border-2 border-primaryLightest rounded-xl text-[14px] font-[500] outline-none relative font-[Poppins]">
                <select
                  value={category}
                  className="border-none outline-none ps-3 py-2 pe-6 bg-transparent"
                  style={{
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    appearance: "none",
                  }}
                  onChange={(e) => navigate(`/course/${e.target.value}`)}
                >
                  {!loadingCategories &&
                    categories &&
                    categories.map((data, index) => (
                      <option value={`${data.name}`}>
                        {data.name.toUpperCase()}
                      </option>
                    ))}
                </select>
                <ChevronDown
                  size={16}
                  className="absolute right-1 top-[50%] -translate-y-[50%]"
                />
              </div>
            </div>
          </div>
          <div className="mt-10 ms-2 flex items-center justify-between w-full">
            <div className="flex items-center justify-center gap-4 text-[#3A3A3A] font-[Poppins] text-[14px]">
              <div
                onClick={() => setCourseTab("all")}
                className={`${
                  courseTab === "all" && "!border-b-primaryBlue"
                } border-b-[2px] border-b-transparent pb-1 cursor-pointer`}
              >
                All Tests
              </div>
              <div
                className={`${
                  courseTab === "section" && "!border-b-primaryBlue"
                } border-b-[2px] border-b-transparent pb-1 cursor-not-allowed`}
              >
                Section Wise Tests
                <span className="text-[10px] text-primaryBlue ps-1">
                  (Coming Soon...)
                </span>
              </div>
            </div>
            {!loadingPublic &&
              !publicError &&
              publicTests &&
              publicTests.length > 2 &&
              courseTab === "all" && (
                <div
                  onClick={() =>
                    setTestsExpanded({
                      ...testsExpanded,
                      public: !testsExpanded.public,
                    })
                  }
                  className="text-primaryBlue font-[Poppins] text-[14px] cursor-pointer"
                >
                  {testsExpanded.public ? "See less..." : "See all..."}
                </div>
              )}
          </div>
          {courseTab === "all" && (
            <>
              <div className="mt-6 w-full">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full">
                  {!loadingPublic &&
                    !publicError &&
                    publicTests &&
                    publicTests
                      .slice(0, testsExpanded.public ? publicTests.length : 2)
                      .map((data, index) => (
                        <TestCard
                          testData={data}
                          userData={publicUserData}
                          testType="public"
                          originalTestData={true}
                          addBookmarkTest={addBookmarkTest}
                          removeBookmarkTest={removeBookmarkTest}
                          loadingAddBookmarkTest={loadingAddBookmarkTest}
                          loadingRemoveBookmarkTest={loadingRemoveBookmarkTest}
                        />
                      ))}
                  <div className="border-b-[3px] border-b-blueLighter rounded-[18px] min-h-[450px]">
                    <div
                      className={`border-[1px] border-blueLightest bg-blueLightest p-[1.5rem] rounded-[1rem] h-full bg-no-repeat bg-right-top bg-[length:125px]`}
                      style={{ backgroundImage: `url(${blueMask})` }}
                    >
                      <div className="flex flex-col items-start justify-center h-full">
                        <div className="text-[#242424] text-[26px] font-[600]">
                          Get notified when new tests are added !
                        </div>
                        <div className="text-[#3A3A3A] font-[Poppins] mt-3 text-[15px] leading-6">
                          Sign up to our newsletter and get notified about
                          updates !
                        </div>
                        <Link
                          to="/newsletter-config"
                          state={{ previousLocation: location }}
                          className="border-b-[3px] border-b-blueLight w-full rounded-[18px] hover:border-b-0 transition-all ease-in-out duration-300 mt-8"
                        >
                          <div className="bg-transparent text-primaryBlue px-5 py-3 rounded-2xl border-[2px] border-primaryBlue text-[14px] font-[600] flex items-center justify-center gap-2 w-full">
                            <span>Subscribe Now</span>
                            <Bell size={16} strokeWidth={3} className="mb-1" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between w-full mt-10 lg:mt-14">
                <div className="flex items-start justify-center flex-col ms-2">
                  <div className="text-[#242424] font-[600] text-[18px]">
                    Custom Created Test
                  </div>
                  <div className="text-[#7A7A7A] font-[Poppins] font-[400] text-[14px]">
                    Your personalized practice playground.
                  </div>
                </div>
                {!loadingCustom &&
                  !customError &&
                  customTests &&
                  customTests.length > 2 && (
                    <div
                      onClick={() =>
                        setTestsExpanded({
                          ...testsExpanded,
                          custom: !testsExpanded.custom,
                        })
                      }
                      className="text-primaryBlue font-[Poppins] text-[14px] cursor-pointer"
                    >
                      {testsExpanded.custom ? "See less..." : "See all..."}
                    </div>
                  )}
              </div>
              <div className="mt-6 w-full">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 w-full">
                  {!loadingCustom &&
                    !customError &&
                    customTests &&
                    customTests
                      .slice(0, testsExpanded.custom ? customTests.length : 2)
                      .map((data, index) => (
                        <TestCard
                          testData={data}
                          userData={customUserData}
                          testType="custom"
                          originalTestData={true}
                          addBookmarkTest={addBookmarkTest}
                          removeBookmarkTest={removeBookmarkTest}
                          loadingAddBookmarkTest={loadingAddBookmarkTest}
                          loadingRemoveBookmarkTest={loadingRemoveBookmarkTest}
                          deleteCustomTest={deleteCustomTest}
                          loadingDeleteCustomTest={loadingDeleteCustomTest}
                        />
                      ))}
                  <div className="border-b-[3px] border-b-[rgba(8,189,129,0.2)] rounded-[18px] min-h-[450px]">
                    <div
                      className={`border-[1px] border-transparent bg-[rgba(8,189,129,0.1)] p-[1.5rem] rounded-[1rem] h-full bg-no-repeat bg-right-top bg-[length:125px]`}
                      style={{ backgroundImage: `url(${greenMask})` }}
                    >
                      <div className="flex flex-col items-start justify-center h-full">
                        <div className="text-[#242424] text-[26px] font-[600]">
                          Get your own tailored tests !
                        </div>
                        <div className="text-[#3A3A3A] font-[Poppins] mt-3 text-[15px] leading-6">
                          Tailor your quizzes, set your pace, and conquer your
                          goals. Let's study smarter together!
                        </div>
                        <Link
                          to="/create-test"
                          state={{ previousLocation: location }}
                          className="border-b-[3px] border-b-[rgba(8,189,129,0.5)] w-full rounded-[18px] hover:border-b-0 transition-all ease-in-out duration-300 mt-8"
                        >
                          <div className="bg-transparent text-greenDark px-5 py-3 rounded-2xl border-[2px] border-primaryGreen text-[14px] font-[600] flex items-center justify-center gap-2 w-full">
                            <span>Create Your Test</span>
                            <Plus size={16} strokeWidth={3} className="mb-1" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Course;
