import React from "react";
import { Link, useNavigate } from "react-router-dom";

import Navbar from "../../../Components/Navbar/Navbar";
import Sidebar from "../../../Components/Sidebar/Sidebar";

const TestStart = ({ testData, HandleTestStart }) => {
  const navigate = useNavigate();

  const parseTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);

    const minutes = Math.floor((seconds % 3600) / 60);

    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes}:${remainingSeconds}`;
  };

  return (
    <>
      <Navbar />
      <div className="flex items-start justify-center w-full">
        <div className="container grid grid-cols-1 lg:grid-cols-12 gap-10 py-8">
          <div className="hidden lg:block col-span-3 relative">
            <Sidebar />
          </div>
          <div className="col-span-1 lg:col-span-6 flex flex-col items-start justify-start w-full">
            <div className="flex items-start justify-center flex-col">
              <div className="text-[##242424] font-[600] text-[18px]">
                {testData.title}
              </div>
              <div className="text-[#7A7A7A] font-[Poppins] text-[14px] mt-1 flex items-center justify-start gap-1">
                <Link
                  to="/dashboard"
                  className="hover:text-primaryDarker transition-all ease-in-out duration-300"
                >
                  Dashboard
                </Link>
                &gt;
                <Link
                  to="/courses"
                  className="hover:text-primaryDarker transition-all ease-in-out duration-300"
                >
                  Courses
                </Link>
                &gt;
                <Link
                  to={`/course/${testData.category.name}`}
                  className="uppercase hover:text-primaryDarker transition-all ease-in-out duration-300"
                >
                  {testData.category.name}
                </Link>
              </div>
            </div>
            <div className="flex flex-col items-start justify-center w-full mt-10">
              <div className="text-[#242424] font-[600] text-[28px]">
                You are about to begin the Test !
              </div>
              <div className="text-[#3A3A3A] font-[Poppins] font-[400] text-[14px] mt-3">
                Before you begin, here’s everything you need to know to ensure
                you’re fully prepared and can maximize your performance.
              </div>
              <div className="mt-8">
                <div className="text-[#0266FF] font-[600] text-[18px]">
                  Rules & Guidelines :
                </div>
                <div className="text-[#3A3A3A] font-[400] font-[Poppins] text-[14px]">
                  <ul style={{ listStyle: "outside" }} className="ps-3 mt-6">
                    <li className="my-4">
                      The timer begins as soon as you click “Start Test” which
                      will be recorded on the server.
                    </li>
                    <li className="my-4">
                      You cannot change tabs or login from another device during
                      the test. Doing so will result in test being Aborted.
                    </li>
                    <li className="my-4">
                      Your stats will be recorded such as time elapsed question
                      number and score on the server side.
                    </li>
                    <li className="my-4">
                      You can view your result after the completion of the test
                      and before its started again from the test card of from
                      the finish screen.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full flex items-center justify-end mt-4 gap-5">
                <div>
                  <button
                    onClick={() => navigate(-1)}
                    className="w-full border-b-[3px] border-b-primaryLightest rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300"
                  >
                    <div className="text-gray-500 px-5 py-3 rounded-2xl border-2 border-primaryLightest disabled:border-primaryLightest text-[14px] font-[600] flex items-center justify-center gap-3 w-full">
                      Return Back
                    </div>
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      HandleTestStart();
                    }}
                    className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full"
                  >
                    Start Test
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 lg:col-span-3 flex flex-col items-start justify-start w-full">
            <div className="border-b-[2px] border-b-primaryLightest rounded-[20px] w-full">
              <div className="border-2 border-primaryLightest rounded-2xl p-5 w-full">
                <div className="flex flex-col w-full items-start justify-center">
                  <div className="text-[#242424] font-[600] text-[18px]">
                    Test Details :
                  </div>
                  <div className="flex flex-col items-start justify-center mt-4">
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px]">
                      Test Duration
                    </div>
                    <div className="text-[#242424] font-[600] mt-2">
                      {parseTime(Math.trunc(testData.time))} hr
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-center mt-6">
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px]">
                      Total Questions
                    </div>
                    <div className="text-[#242424] font-[600] mt-2">
                      {testData.questions.length} questions
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-center mt-6">
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px]">
                      Correct Answer
                    </div>
                    <div className="text-[#08BD80] font-[600] mt-2">
                      + {testData.correctPoints} pts
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-center mt-6">
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px]">
                      Wrong Answer
                    </div>
                    <div className="text-[#BD0808] font-[600] mt-2">
                      - {testData.wrongPoints} pts
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-center mt-6">
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px]">
                      Category
                    </div>
                    <div className="border-[1px] border-primaryLight mt-2 w-fit px-2 py-1 rounded-lg text-primaryDark text-[12px] uppercase font-[Poppins] font-[500]">
                      <span className="font-[700]">
                        {testData.category.name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestStart;
