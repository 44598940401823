import { useState } from "react";

export const useGetCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);

  const getCategories = async () => {
    const response = await fetch(`/api/get_categories`, {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });

    const json = await response.json();

    setCategories(json.categories);
    setLoadingCategories(false);
  };
  return {
    getCategories,
    categories,
    loadingCategories,
  };
};
