import { Check } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <div id="pricing" className="container py-12">
      <div className="flex flex-col items-center justify-center w-full">
        <div className="text-[#242424] font-[700] text-[36px] text-center">
          Straight Forward <span className="text-primaryBlue">Pricing !</span>
        </div>
        <div className="text-[#7A7A7A] font-[Poppins] text-[18px] mt-2 text-center max-w-[1000px]">
          Unlock your full potential with our flexible pricing plans designed to
          fit your needs and budget. Whether you’re a casual learner or a
          serious test-taker, we have a plan that can help you excel.
        </div>
        <div className="mt-16 flex items-center justify-center w-full">
          <div className="grid grid-cols-1 lg:grid-cols-2 max-w-full lg:max-w-[60%] w-full gap-8 lg:gap-16">
            <div className="border-b-2 border-b-primaryLightest rounded-[20px] w-full">
              <div className="border-2 border-primaryLightest rounded-2xl p-8 h-full flex flex-col items-start justify-start">
                <div className="text-[#242424] font-[600] text-[20px]">
                  Free Plan
                </div>
                <div className="text-[#393939] font-[Poppins] mt-4">
                  Kickstart Your Learning Journey: Free Access to Get You
                  Started!
                </div>
                <div className="mt-4 text-[#242424] font-[600] text-[24px]">
                  Rs.<span className="text-[48px] font-[700]">00</span>{" "}
                  <span className="font-[400]">/mo</span>
                </div>
                <div className="mt-4 text-[#242424] font-[400] text-[14px] flex flex-col items-start justify-center gap-4">
                  <div className="flex items-center justify-start gap-2">
                    <Check size={12} color="#242424" />
                    Regular Experience
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <Check size={12} color="#242424" />
                    Max 2 Custom Tests
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <Check size={12} color="#242424" />
                    Limited Access
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <Check size={12} color="#242424" />
                    Limited Support
                  </div>
                </div>
                <Link
                  to="/dashboard"
                  className="w-full border-b-2 border-b-primaryLightest rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300"
                >
                  <div className="text-gray-500 px-5 py-3 rounded-2xl border-2 border-primaryLightest disabled:border-primaryLightest text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-6">
                    Get Started Now
                  </div>
                </Link>
              </div>
            </div>
            <div className="border-b-4 border-b-blueLight rounded-[20px] w-full">
              <div className="border-2 border-primaryBlue bg-primaryBlue rounded-2xl p-8 h-full flex flex-col items-start justify-start relative">
                <div className="absolute -top-[2px] -right-[2px] bg-[#FFC800] px-2 py-2 rounded-tr-xl rounded-bl-xl text-[14px] font-[500] font-[Poppins]">
                  Most Popular Plan
                </div>
                <div className="text-[#ffffff] font-[600] text-[20px]">
                  Premium Plan
                </div>
                <div className="text-[#ffffff] font-[Poppins] mt-4">
                  Unlock Full Potential: Premium Features for Serious Achievers!
                </div>
                <div className="mt-4 text-[#ffffff] font-[600] text-[24px]">
                  Rs.<span className="text-[48px] font-[700]">100</span>{" "}
                  <span className="font-[400]">/mo</span>
                </div>
                <div className="mt-6 text-[#ffffff] font-[400] text-[14px] flex flex-col items-start justify-center gap-4">
                  <div className="flex items-center justify-start gap-2">
                    <div className="h-[20px] w-[20px] rounded-[50%] flex items-center justify-center border-[1px] border-white">
                      <Check size={12} color="#ffffff" />
                    </div>
                    Tailored Experience
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <div className="h-[20px] w-[20px] rounded-[50%] flex items-center justify-center border-[1px] border-white">
                      <Check size={12} color="#ffffff" />
                    </div>
                    Premium Tests Access
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <div className="h-[20px] w-[20px] rounded-[50%] flex items-center justify-center border-[1px] border-white">
                      <Check size={12} color="#ffffff" />
                    </div>
                    Unlimited Custom Tests
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <div className="h-[20px] w-[20px] rounded-[50%] flex items-center justify-center border-[1px] border-white">
                      <Check size={12} color="#ffffff" />
                    </div>
                    Priority Support
                  </div>
                </div>
                <Link
                  to="/dashboard"
                  className="w-full border-b-4 border-b-primaryLighter rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300"
                >
                  <div className="px-5 py-3 rounded-2xl border-2 bg-white border-white disabled:border-primaryLightest text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-6 text-primaryDark">
                    Get Premium Plan
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
