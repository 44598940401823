import { useState } from "react";

export const useGetTest = () => {
  const [testData, setTestData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [deadline, setDeadline] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getTest = async (test_id, test_type) => {
    const response = await fetch(`/api/get_${test_type}_test/${test_id}`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });

    try {
      const data = await response.json();
      if (response.ok) {
        setError(null);
        setTestData(data.updatedData);
        setCurrentQuestion(data.currentQuestion);
        setCurrentTime(data.currentTime);
        setDeadline(data.updatedData.time);
        setLoading(false);
      }

      if (!response.ok) {
        setError(data.msg);
        setLoading(false);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return {
    getTest,
    testData,
    currentQuestion,
    setCurrentQuestion,
    currentTime,
    setCurrentTime,
    deadline,
    loading,
    setLoading,
    error,
  };
};
