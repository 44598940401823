import { useState } from "react";

export const useGetPaidTestDetails = () => {
  const [loadingPaidTestDetails, setLoadingPaidTestDetails] = useState(false);
  const [errorPaidTestDetails, setErrorPaidTestDetails] = useState(null);
  const [paidTestDetails, setPaidTestDetails] = useState(null);

  const getPaidTestDetails = async (id) => {
    try {
      setLoadingPaidTestDetails(true);
      setErrorPaidTestDetails(null);
      setPaidTestDetails(null);

      const response = await fetch(`/api/get_test_details/${id}`, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });

      const json = await response.json();

      if (!response.ok) {
        setLoadingPaidTestDetails(false);
        setErrorPaidTestDetails(json.msg);
        setPaidTestDetails(null);
      } else {
        setLoadingPaidTestDetails(false);
        setErrorPaidTestDetails(null);
        setPaidTestDetails(json.data);
      }
    } catch (error) {
      setLoadingPaidTestDetails(false);
      setErrorPaidTestDetails(error.message);
      setPaidTestDetails(null);
    }
  };

  return {
    loadingPaidTestDetails,
    errorPaidTestDetails,
    paidTestDetails,
    getPaidTestDetails,
  };
};
