import React from "react";
import laptopImg from "../../Assets/img/hero/why.png";
import iconImg from "../../Assets/img/hero/ico.png";

const WhyMeroTests = () => {
  return (
    <div id="whymerotests" className="container py-12">
      <div className="flex flex-col items-center justify-center w-full">
        <div className="text-[#242424] font-[700] text-[36px] text-center">
          Why Choose Us ?
        </div>
        <div className="text-[#7A7A7A] font-[Poppins] text-[18px] mt-2 text-center">
          Discover why we're the preferred choice for effective and engaging
          test preparation!
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-16 gap-16">
        <div className="flex items-center justify-center ">
          <div className="max-w-[600px]">
            <img src={laptopImg} alt="why merotests" />
          </div>
        </div>
        <div className="w-full h-full rounded-[28px] border-b-4 border-b-blueLighter">
          <div className="bg-[rgba(2,103,255,0.08)] rounded-3xl p-10 flex flex-col items-start justify-between w-full gap-10 h-full">
            <div className="flex items-start justify-start w-full gap-4">
              <div className="min-w-[60px] w-[60px] min-h-[60px] h-[60px] flex items-center justify-center rounded-[50%] bg-primaryGreen p-[14px]">
                <img src={iconImg} alt="test" className="w-full h-full" />
              </div>
              <div className="flex flex-col w-auto items-start justify-center">
                <div className="text-[#242424] font-[600] text-[20px]">
                  Comprehensive Test Coverage
                </div>
                <div className="text-[#7A7A7A] font-[Poppins] text-[18px] mt-2">
                  Our tests cover all major subjects and certification exams,
                  designed by experts to mimic real exam conditions.
                </div>
              </div>
            </div>
            <div className="flex items-start justify-start w-full gap-4">
              <div className="min-w-[60px] w-[60px] min-h-[60px] h-[60px] flex items-center justify-center rounded-[50%] bg-primaryGreen p-[14px]">
                <img src={iconImg} alt="test" className="w-full h-full" />
              </div>
              <div className="flex flex-col w-auto items-start justify-center">
                <div className="text-[#242424] font-[600] text-[20px]">
                  Customizable Tests
                </div>
                <div className="text-[#7A7A7A] font-[Poppins] text-[18px] mt-2">
                  Our tests cover all major subjects and certification exams,
                  designed by experts to mimic real exam conditions.
                </div>
              </div>
            </div>
            <div className="flex items-start justify-start w-full gap-4">
              <div className="min-w-[60px] w-[60px] min-h-[60px] h-[60px] flex items-center justify-center rounded-[50%] bg-primaryGreen p-[14px]">
                <img src={iconImg} alt="test" className="w-full h-full" />
              </div>
              <div className="flex flex-col w-auto items-start justify-center">
                <div className="text-[#242424] font-[600] text-[20px]">
                  Instant Feedback and Analytics
                </div>
                <div className="text-[#7A7A7A] font-[Poppins] text-[18px] mt-2">
                  Receive immediate, detailed feedback on your test results to
                  understand your strengths and weaknesses.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyMeroTests;
