import { useState } from "react";

export const useRegister = () => {
  const [errorRegister, setErrorRegister] = useState(null);
  const [registerMessage, setRegisterMessage] = useState(null);
  const [isLoadingRegister, setIsLoadingRegister] = useState(null);

  const registerUser = async (name, email, password, phone) => {
    try {
      setIsLoadingRegister(true);
      setErrorRegister(null);
      setRegisterMessage(null);

      const response = await fetch(`/api/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email, password, phone }),
      });

      const data = await response.json();

      if (!response.ok) {
        setIsLoadingRegister(false);
        setRegisterMessage(null);
        setErrorRegister(data.msg);
      }
      if (response.ok) {
        setErrorRegister(null);
        setRegisterMessage(data.msg);
        setIsLoadingRegister(false);
      }
    } catch (err) {
      setErrorRegister(err.message);
      setIsLoadingRegister(false);
      setRegisterMessage(null);
    }
  };

  return { registerUser, isLoadingRegister, errorRegister, registerMessage };
};
