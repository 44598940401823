import { Check, CornerDownRight } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { ChevronLeft, Dot, RotateCcw, MoveRight } from "lucide-react";
import { baseUrl } from "../../../Utils/BaseUrl";

const TestActive = ({
  testData,
  currentQuestion,
  selected,
  handleAnswerSubmit,
  setSelected,
  showNext,
  handleRestartTest,
  handleNextQuestion,
  currentTime,
}) => {
  const navigate = useNavigate();

  const [range, setRange] = useState({
    startIndex: 0,
    endIndex: 20,
  });

  function checkRange(currentQuestion) {
    let startIndex, endIndex;
    if (currentQuestion < 20) {
      startIndex = 0;
      endIndex = 20;
    } else if (currentQuestion < 40) {
      startIndex = 20;
      endIndex = 40;
    } else if (currentQuestion < 60) {
      startIndex = 40;
      endIndex = 60;
    } else if (currentQuestion < 80) {
      startIndex = 60;
      endIndex = 80;
    } else if (currentQuestion < 100) {
      startIndex = 80;
      endIndex = 100;
    }
    return { startIndex, endIndex };
  }

  useEffect(() => {
    const { startIndex, endIndex } = checkRange(currentQuestion);
    setRange({ startIndex, endIndex });
  }, [currentQuestion]);

  useEffect(() => {
    const keyPress = (e) => {
      if (e.key === "1") {
        document.getElementById("answer-1").click();
      } else if (e.key === "2") {
        document.getElementById("answer-2").click();
      } else if (e.key === "3") {
        document.getElementById("answer-3").click();
      } else if (e.key === "4") {
        document.getElementById("answer-4").click();
      } else if (e.key === "r") {
        handleRestartTest();
      }
    };

    window.addEventListener("keypress", keyPress);

    return () => {
      window.removeEventListener("keypress", keyPress);
    };
  }, []);

  useEffect(() => {
    if (showNext) {
      window.addEventListener("keypress", (e) => {
        if (e.key === "Enter" && showNext) {
          handleNextQuestion();
        }
      });
    } else {
      window.removeEventListener("keypress", (e) => {
        if (e.key === "Enter" && showNext) {
          handleNextQuestion();
        }
      });
    }

    return () => {
      window.removeEventListener("keypress", (e) => {
        if (e.key === "Enter" && showNext) {
          handleNextQuestion();
        }
      });
    };
  }, [showNext]);

  return (
    <div>
      <div className="min-h-screen flex flex-col items-center justify-between">
        <div className="h-full flex flex-col items-center justify-evenly flex-grow w-full">
          <div className="container w-full mt-10 lg:mt-12 max-w-[1300px]">
            <div className="font-[Poppins] text-primaryGreen font-[600] text-[14px] text-center w-full mb-2">
              Answering question no. {currentQuestion + 1} out of{" "}
              {testData.questions.length}
            </div>
            <ProgressBar
              completed={
                ((currentQuestion + 1) / testData.questions.length) * 100
              }
              height="16px"
              bgColor="#08BD80"
              isLabelVisible={false}
            />
          </div>
          <div className="container grid grid-cols-5 w-full py-10 lg:py-12 gap-10 lg:gap-0 max-w-[1300px]">
            <div className="flex flex-col items-start justify-center col-span-5 lg:col-span-3">
              <button
                onClick={() => navigate(-1)}
                className="text-[#7A7A7A] font-[400] font-[Poppins] text-[14px] flex items-center justify-center gap-1 hover:text-primaryDark transition-all ease-in-out duration-300 group"
              >
                <ChevronLeft color="#7A7A7A" size={20} strokeWidth={1} />
                Return To Dashboard{" "}
                <span className="text-[12px] text-primaryLight group-hover:text-primaryDark transition-all ease-in-out duration-300">
                  (test will be paused)
                </span>
              </button>
              <div className="border-b-[4px] border-b-primaryLighter rounded-[18px] mt-8">
                <div className="text-[14px] font-[500] capitalize bg-primaryLight text-[#FFFFFF] px-4 py-[6px] rounded-2xl">
                  {testData?.questions[currentQuestion].linkedSubject?.name
                    .split("-")
                    .splice(1)
                    .join(" ")}
                </div>
              </div>
              <div className="text-[#242424] font-[600] text-[24px] mt-4 max-w-[650px]">
                {testData.questions[currentQuestion].questionTitle}
              </div>
              {testData.questions[currentQuestion].questionDiagram && (
                <div className="max-h-[200px] overflow-hidden my-3 w-full lg:max-w-[80%] flex items-center justify-start">
                  <img
                    src={`${baseUrl}/question/${testData.questions[currentQuestion].questionDiagram}`}
                    alt="question"
                    className="w-auto h-auto object-contain object-left"
                  />
                </div>
              )}
              <div className="mt-2 text-[14px]">
                ( please choose an answer from options below )
              </div>
              <div className="flex flex-col items-start justify-center gap-5 mt-8 w-full">
                {testData.questions[currentQuestion].answerOptions.map(
                  (data, index) => (
                    <div
                      key={data._id}
                      className={`${
                        selected === data && "!border-b-blueLight"
                      } group w-full border-b-[4px] border-b-primaryLightest rounded-[20px] hover:border-b-blueLight transition-all ease-in-out duration-200 focus-within:border-b-[0px] min-w-full lg:min-w-[500px] lg:max-w-[500px]`}
                    >
                      <button
                        id={`answer-${index + 1}`}
                        className={`w-full border-[2px] border-primaryLighter group-hover:text-primaryBlue group-hover:border-primaryBlue px-3 py-[10px] rounded-2xl font-[Poppins] text-[14px] font-[500] flex items-center justify-start gap-3 group transition-all ease-in-out duration-300 min-w-[200px] text-start ${
                          selected === data &&
                          "text-primaryBlue !border-primaryBlue"
                        }`}
                        onClick={() => {
                          handleAnswerSubmit(
                            testData.questions[currentQuestion]._id,
                            data._id
                          );
                          setSelected(data);
                        }}
                        disabled={showNext}
                      >
                        <span
                          className={`border-[1.5px] border-primaryLighter text-primaryLight group-hover:text-primaryBlue group-hover:border-primaryBlue px-3 py-1 rounded-xl font-[Poppins] flex items-center justify-center gap-3 text-[12px] transition-all ease-in-out duration-300 ${
                            selected === data &&
                            "!text-primaryBlue !border-primaryBlue"
                          }`}
                        >
                          {index + 1}
                        </span>
                        {data.answer}
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="border-b-[4px] border-b-primaryLightest col-span-5 lg:col-span-2 w-full rounded-[20px] flex items-end justify-center">
              <div className="p-10 rounded-2xl border-primaryLighter border-[2px] w-full flex flex-col items-start justify-start gap-6 h-fit">
                <div className="flex flex-col items-start justify-start gap-[6px]">
                  <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[500]">
                    Test Name:
                  </div>
                  <div className="text-[#242424] font-[600] text-[16px]">
                    {testData.title}
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-[6px]">
                  <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[500]">
                    Time Elapsed:
                  </div>
                  <div className="text-[#242424] font-[600] text-[16px]">
                    {new Date(1000 * currentTime).toISOString().substr(11, 8)}
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-[6px] w-full mt-4">
                  <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[500]">
                    Total Questions:
                  </div>
                  <div className="grid grid-cols-5 lg:grid-cols-8 gap-4 w-full">
                    {testData.questions
                      .slice(range.startIndex, range.endIndex)
                      .map((data, index) => (
                        <div
                          key={range.startIndex + index}
                          className={`${
                            currentQuestion > range.startIndex + index &&
                            "!border-b-greenDark"
                          } ${
                            currentQuestion === range.startIndex + index &&
                            "!border-b-primaryLight"
                          } border-b-primaryLightest border-b-[3px] rounded-[10px] w-fit`}
                        >
                          <div
                            className={`${
                              currentQuestion > range.startIndex + index &&
                              "!bg-primaryGreen text-white !border-primaryGreen"
                            } ${
                              currentQuestion === range.startIndex + index &&
                              "!border-primaryDark !text-primaryDark"
                            } border-[1.5px] border-primaryLighter flex items-center justify-center w-[40px] h-[40px] px-2 py-1 rounded-lg text-[#7A7A7A] font-[Poppins] font-[500]`}
                          >
                            {currentQuestion > range.startIndex + index ? (
                              <Check />
                            ) : (
                              range.startIndex + index + 1
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="flex flex-wrap items-start justify-start gap-8 mt-4 w-full">
                  <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[400] flex items-center justify-center gap-2">
                    <div className="border-b-[3px] border-b-greenDark rounded-[10px]">
                      <div className="bg-primaryGreen text-white border-primaryGreen border-[1.5px] flex items-center justify-center w-[25px] h-[25px] rounded-lg font-[Poppins] font-[500]">
                        <Check size={14} strokeWidth={3} />
                      </div>
                    </div>
                    Answered:{" "}
                    <span className="text-primaryDark font-[500]">
                      {currentQuestion}
                    </span>
                  </div>
                  <div className="text-[#7A7A7A] font-[Poppins] text-[14px] font-[400] flex items-center justify-center gap-2">
                    <div className="border-b-[3px] border-b-primaryLightest rounded-[10px]">
                      <div className="bg-transparent text-primaryLight border-primaryLight border-[1.5px] flex items-center justify-center w-[25px] h-[25px] rounded-lg font-[Poppins] font-[500]">
                        <Dot size={24} strokeWidth={3} />
                      </div>
                    </div>
                    Unanswered:{" "}
                    <span className="text-primaryDark font-[500]">
                      {testData.questions.length - currentQuestion}
                    </span>
                  </div>
                </div>
                <div className="flex flex-wrap items-start justify-start gap-8 mt-2 w-full">
                  <span className="text-[12px] text-primaryLight group-hover:text-primaryDark transition-all ease-in-out duration-300">
                    (the score and the answers will be shown after the test has
                    ended !)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-10 border-t-2 border-t-[rgba(0,0,0,0.05)] w-full flex items-center justify-center">
          <div className="container flex flex-col gap-6 lg:gap-0 lg:flex-row items-center justify-between w-full max-w-[1300px]">
            <button
              onClick={handleRestartTest}
              className="bg-primaryDark text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-primaryLight hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full lg:w-fit"
            >
              <span
                className={`border-[1.5px] border-primaryLighter text-white group-hover:text-primaryBlue group-hover:border-primaryBlue px-[10px] py-[2px] rounded-lg font-[Poppins] flex items-center justify-center gap-3 text-[12px] transition-all ease-in-out duration-300`}
              >
                r
              </span>
              Restart Test
              <RotateCcw size={18} strokeWidth={2.5} />
            </button>
            <button
              onClick={async () => {
                // if (window.innerWidth < 768) {
                //   console.log("mobile dev");
                //   window.scrollTo({
                //     top: 0,
                //     behavior: "smooth",
                //   });
                //   window.addEventListener("scroll", () => {

                //   })
                // }

                await handleNextQuestion();
              }}
              disabled={!showNext}
              className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter disabled:text-white hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full lg:w-fit"
            >
              <span
                className={`border-[1.5px] border-white text-white px-[10px] py-[6px] rounded-lg font-[Poppins] flex items-center justify-center gap-3 text-[12px] transition-all ease-in-out duration-300`}
              >
                <CornerDownRight size={11} strokeWidth={4} />
              </span>
              {testData.questions.length - 1 > currentQuestion
                ? "Next Question"
                : "Finish Test"}
              <MoveRight size={18} strokeWidth={2.5} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestActive;
