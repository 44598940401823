import { toast } from "react-toastify";
import { LogOut, Upload } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Sidebar from "../../Components/Sidebar/Sidebar";
import TestCard from "../../Components/resuable/TestCard";
import ellipseImg from "../../Assets/img/dashboard/e2.png";
import halfEllipseImg from "../../Assets/img/dashboard/e1.png";
import PurchaseCard from "../../Components/resuable/PurchaseCard";
import defaultAvatar from "../../Assets/img/leaderboard/avatar.png";
import { baseUrl } from "../../Utils/BaseUrl";
import { LoadingContexts } from "../../Contexts/LoadingContext";

import { useLogout } from "../../Hooks/useLogout";
import { useGetUserData } from "../../Hooks/useGetUserData";
import { useUpdateUserData } from "../../Hooks/useUpdateUserData";
import { useGetUserPurchases } from "../../Hooks/useGetUserPurchases";
import { useDeleteCustomTest } from "../../Hooks/useDeleteCustomTest";
import { useAddBookmarkTest } from "../../Hooks/bookmark/useAddBookmarkTest";
import { useRemoveBookmarkTest } from "../../Hooks/bookmark/useRemoveBookmarkTest";

const Profile = () => {
  const { logoutUser, isLoading: isLoadingLogout } = useLogout();

  const {
    addBookmarkTest,
    loadingAddBookmarkTest,
    errorAddBookmarkTest,
    successAddBookmarkTest,
  } = useAddBookmarkTest();

  const {
    removeBookmarkTest,
    loadingRemoveBookmarkTest,
    errorRemoveBookmarkTest,
    successRemoveBookmarkTest,
  } = useRemoveBookmarkTest();

  const {
    deleteCustomTest,
    loadingDeleteCustomTest,
    errorDeleteCustomTest,
    successDeleteCustomTest,
  } = useDeleteCustomTest();

  const {
    getUserPurchases,
    userPurchases,
    errorUserPurchases,
    loadingUserPurchases,
  } = useGetUserPurchases();

  const {
    getUserData,
    errorUserData,
    loadingUserData,
    userData: userDataHook,
  } = useGetUserData();

  const {
    updateUserData,
    loadingUpdateUserData,
    errorUpdateUserData,
    updateUserDataMessage,
  } = useUpdateUserData();

  const { isLoadingSomething, setIsLoadingSomething } =
    useContext(LoadingContexts);

  useEffect(() => {
    if (
      (isLoadingLogout ||
        loadingAddBookmarkTest ||
        loadingRemoveBookmarkTest ||
        loadingDeleteCustomTest ||
        loadingUserPurchases ||
        loadingUserData ||
        loadingUpdateUserData) &&
      !isLoadingSomething
    ) {
      setIsLoadingSomething(true);
    }

    if (
      !isLoadingLogout &&
      !loadingAddBookmarkTest &&
      !loadingRemoveBookmarkTest &&
      !loadingDeleteCustomTest &&
      !loadingUserPurchases &&
      !loadingUserData &&
      !loadingUpdateUserData &&
      isLoadingSomething
    ) {
      setIsLoadingSomething(false);
    }
  }, [
    isLoadingLogout,
    loadingAddBookmarkTest,
    loadingRemoveBookmarkTest,
    loadingDeleteCustomTest,
    loadingUserPurchases,
    loadingUserData,
    loadingUpdateUserData,
  ]);

  const [profileTab, setProfileTab] = useState("editProfile");

  const location = useLocation();

  useEffect(() => {
    if (successDeleteCustomTest) {
      toast.success(successDeleteCustomTest);
      getUserData();
    }
  }, [successDeleteCustomTest]);

  useEffect(() => {
    if (errorDeleteCustomTest) {
      toast.error(errorDeleteCustomTest);
    }
  }, [errorDeleteCustomTest]);

  useEffect(() => {
    if (successAddBookmarkTest) {
      toast.success(successAddBookmarkTest.msg);
      getUserData();
    }
  }, [successAddBookmarkTest]);

  useEffect(() => {
    if (errorAddBookmarkTest) {
      toast.error(errorAddBookmarkTest);
    }
  }, [errorAddBookmarkTest]);

  useEffect(() => {
    if (successRemoveBookmarkTest) {
      toast.success(successRemoveBookmarkTest);
      getUserData();
    }
  }, [successRemoveBookmarkTest]);

  useEffect(() => {
    if (errorRemoveBookmarkTest) {
      toast.error(errorRemoveBookmarkTest);
    }
  }, [errorRemoveBookmarkTest]);

  const [userData, setUserData] = useState({
    name: "",
    phone: "",
    password: "",
    confirmPassword: "",
    profilePic: null,
  });

  const [selectedProfilePic, setSelectedProfilePic] = useState(null);
  const [previewProfilePic, setPreviewProfilePic] = useState(null);

  useEffect(() => {
    if (!selectedProfilePic) {
      setPreviewProfilePic(undefined);
      return;
    }

    let objectUrl;

    if (
      selectedProfilePic.type === "image/png" ||
      selectedProfilePic.type === "image/jpeg" ||
      selectedProfilePic.type === "image/jpg"
    ) {
      objectUrl = URL.createObjectURL(selectedProfilePic);
      setPreviewProfilePic(objectUrl);
    } else {
      toast.error("Only image files are allowed !");
    }

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedProfilePic]);

  const HandleSelectProfilePicture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedProfilePic(undefined);
      return;
    }

    setSelectedProfilePic(e.target.files[0]);
    setUserData({ ...userData, profilePic: e.target.files[0] });
  };

  useEffect(() => {
    if (userDataHook) {
      setUserData({
        name: userDataHook.name,
        phone: userDataHook.phone,
        password: "",
        confirmPassword: "",
      });
    }
  }, [userDataHook]);

  const [validationError, setValidationError] = useState(null);

  const navigate = useNavigate();

  const validatePhone = (phone) => {
    return String(phone).match(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    );
  };

  const validateName = (name) => {
    return String(name).match(/^[a-zA-Z]+(?: [a-zA-Z]+)*(?: [a-zA-Z]+)$/);
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    setValidationError(null);

    if (!validateName(userData.name)) {
      setValidationError("Please enter a valid name !");
      return;
    }

    if (!validatePhone(userData.phone)) {
      setValidationError("Please enter a valid phone number !");
      return;
    }

    if (
      userData.name === userDataHook.name &&
      userData.phone === userDataHook.phone &&
      userData.password === "" &&
      userData.confirmPassword === "" &&
      !userData.profilePic
    ) {
      return toast.info("No data has been changed !");
    }

    if (userData.password !== userData.confirmPassword) {
      setValidationError("Passwords do not match !");
      return;
    }

    if (userData.password === "" && userData.confirmPassword === "") {
      if (userData.profilePic) {
        return await updateUserData(
          userData.name,
          userData.phone,
          null,
          userData.profilePic
        );
      }
      return await updateUserData(userData.name, userData.phone, null);
    }

    if (userData.profilePic) {
      return await updateUserData(
        userData.name,
        userData.phone,
        userData.password,
        userData.profilePic
      );
    }

    return await updateUserData(
      userData.name,
      userData.phone,
      userData.password
    );
  };

  useEffect(() => {
    if (validationError) toast.error(validationError);
    if (errorUpdateUserData) toast.error(errorUpdateUserData);
  }, [validationError, errorUpdateUserData]);

  useEffect(() => {
    if (updateUserDataMessage) {
      toast.success(updateUserDataMessage);
      setUserData({
        ...userData,
        password: "",
        confirmPassword: "",
      });
      navigate("/profile");
    }
  }, [updateUserDataMessage]);

  useEffect(() => {
    getUserPurchases();
    getUserData();
  }, []);

  const parseTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);

    const minutes = Math.floor((seconds % 3600) / 60);

    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes}:${remainingSeconds}`;
  };

  const resetData = () => {
    setUserData({
      name: userDataHook.name,
      phone: userDataHook.phone,
      password: "",
      confirmPassword: "",
    });

    URL.revokeObjectURL(previewProfilePic);
    setPreviewProfilePic(undefined);
    setSelectedProfilePic(undefined);
  };

  return (
    <div className="flex items-start justify-center w-full pb-[80px] lg:pb-0">
      <div className="container grid grid-cols-1 lg:grid-cols-12 gap-10 py-8">
        <div className="hidden lg:block col-span-3 relative">
          <Sidebar />
        </div>
        <div className="col-span-1 lg:col-span-6 order-2 lg:order-1">
          {!loadingUserData && userDataHook && (
            <div className="flex flex-col items-start justify-center w-full gap-10">
              <div className="bg-primaryGreen flex flex-col items-center justify-between rounded-2xl relative overflow-hidden w-full">
                <div className="flex items-center justify-start p-5 gap-4 w-full z-[2]">
                  <div className="w-[75px] h-[75px] rounded-[50%] flex items-center justify-center overflow-hidden">
                    <img
                      alt="profile"
                      src={
                        userDataHook.profilePic
                          ? `${baseUrl}/${userDataHook.profilePic}`
                          : defaultAvatar
                      }
                      style={{
                        minWidth: "100%",
                        minHeight: "100%",
                        flexShrink: 0,
                      }}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-center">
                    <div className="font-[600] text-[20px] text-gray-800">
                      {userDataHook.name}
                    </div>
                    <div className="font-[Poppins] text-gray-800 font-[400] text-[14px]">
                      {userDataHook.email}
                    </div>
                  </div>
                </div>
                <div className="h-[1px] bg-gray-800 w-[90%]"></div>
                <div className="grid grid-cols-2 gap-6 lg:gap-0 lg:grid-cols-4 p-5 w-full z-[2]">
                  <div className="flex flex-col items-center justify-center gap-2">
                    <div className="text-gray-800 font-[Poppins] font-[500] text-[12px]">
                      Average Score
                    </div>
                    <div className="text-gray-800 font-[600] text-[20px]">
                      {Number(userDataHook.averageScore).toFixed(2)} pts
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-2">
                    <div className="text-gray-800 font-[Poppins] font-[500] text-[12px]">
                      Average Time
                    </div>
                    <div className="text-gray-800 font-[600] text-[20px]">
                      {parseTime(Math.trunc(userDataHook.averageTime))} hr/s
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-2">
                    <div className="text-gray-800 font-[Poppins] font-[500] text-[12px]">
                      Tests Taken
                    </div>
                    <div className="text-gray-800 font-[600] text-[20px]">
                      {userDataHook.averageTestsCount} test/s
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-2">
                    <div className="text-gray-800 font-[Poppins] font-[500] text-[12px]">
                      User Type
                    </div>
                    <div
                      className={`${
                        userDataHook.type === "paid" && "!text-primaryBlue"
                      } text-gray-800 font-[600] text-[20px]`}
                    >
                      {userDataHook.type === "free" ? "Regular" : "Premium"}
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-0 right-0 max-w-[200px] z-[1]">
                  <img src={halfEllipseImg} alt="" className="w-full h-full" />
                </div>
                <div className="absolute bottom-3 left-0 max-w-[125px] z-[1] opacity-75">
                  <img src={ellipseImg} alt="" className="w-full h-full" />
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-start gap-6 text-[15px] font-[400] lg:ms-1 font-[Poppins]">
                <div
                  onClick={() => setProfileTab("editProfile")}
                  className={`${
                    profileTab === "editProfile" &&
                    "!border-b-primaryBlue !text-primaryDarker"
                  } border-b-2 border-b-transparent cursor-pointer text-[#7A7A7A] hover:text-primaryDarker transition-all ease-in-out duration-300 py-1`}
                >
                  Edit Profile
                </div>
                <div
                  onClick={() => setProfileTab("purchases")}
                  className={`${
                    profileTab === "purchases" &&
                    "!border-b-primaryBlue !text-primaryDarker"
                  } border-b-2 border-b-transparent cursor-pointer text-[#7A7A7A] hover:text-primaryDarker transition-all ease-in-out duration-300 py-1`}
                >
                  Recent Purchases
                </div>
                <div
                  onClick={() => setProfileTab("bookmarkedTests")}
                  className={`${
                    profileTab === "bookmarkedTests" &&
                    "!border-b-primaryBlue !text-primaryDarker"
                  } border-b-2 border-b-transparent cursor-pointer text-[#7A7A7A] hover:text-primaryDarker transition-all ease-in-out duration-300 py-1`}
                >
                  Bookmarked Tests
                </div>
              </div>
              {profileTab === "editProfile" && (
                <div className="w-full font-[Poppins]">
                  <div className="lg:ms-1">
                    <div className="text-[#242424] font-[600] text-[18px]">
                      Edit Your Profile
                    </div>
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px] mt-1">
                      Update your personal details here !
                    </div>
                  </div>
                  <div className="mt-8 w-full">
                    <div className="flex items-center justify-start gap-6">
                      <div className="w-[100px] h-[100px] flex items-center justify-center overflow-hidden rounded-[50%]">
                        <img
                          alt="profile"
                          src={
                            previewProfilePic
                              ? previewProfilePic
                              : userDataHook.profilePic
                              ? `${baseUrl}/${userDataHook.profilePic}`
                              : defaultAvatar
                          }
                          style={{
                            minWidth: "100%",
                            minHeight: "100%",
                            flexShrink: "0",
                          }}
                        />
                      </div>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                          HandleSelectProfilePicture(e);
                        }}
                        name="profile-picture"
                        style={{ display: "none" }}
                        id="selectedFile"
                      />
                      <button
                        onClick={() =>
                          document.getElementById("selectedFile").click()
                        }
                        className="w-fit border-b-2 border-b-primaryLightest rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300"
                      >
                        <div className="text-gray-500 px-5 py-3 rounded-2xl border-2 border-primaryLightest disabled:border-primaryLightest text-[14px] font-[600] flex items-center justify-center gap-3 w-fit">
                          Choose Picture
                          <Upload size={18} strokeWidth={3} />
                        </div>
                      </button>
                    </div>
                    <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 w-full gap-6">
                      <div className="flex flex-col items-start justify-center w-full">
                        <span className="text-[#7A7A7A] font-[500] text-[14px] font-[Poppins] ms-1">
                          Your Email:
                        </span>
                        <input
                          type="text"
                          value={userDataHook.email}
                          disabled
                          className="border-2 border-primaryLightest px-3 py-2 rounded-xl mt-1 text-[14px] w-full bg-transparent focus:bg-[#f6f6f6] disabled:bg-primaryLightest focus:border-primaryLight outline-none transition-all ease-in-out duration-300"
                        />
                      </div>
                    </div>
                    <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 w-full gap-6">
                      <div className="flex flex-col items-start justify-center w-full">
                        <span className="text-[#7A7A7A] font-[500] text-[14px] font-[Poppins] ms-1">
                          Your Name:
                        </span>
                        <input
                          type="text"
                          value={userData.name}
                          onChange={(e) =>
                            setUserData({ ...userData, name: e.target.value })
                          }
                          className="border-2 border-primaryLightest px-3 py-2 rounded-xl mt-1 text-[14px] w-full bg-transparent focus:bg-[#f6f6f6] disabled:bg-primaryLightest focus:border-primaryLight hover:border-primaryLighter outline-none transition-all ease-in-out duration-300"
                        />
                      </div>
                      <div className="flex flex-col items-start justify-center w-full">
                        <span className="text-[#7A7A7A] font-[500] text-[14px] font-[Poppins] ms-1">
                          Your Phone:
                        </span>
                        <input
                          type="number"
                          value={userData.phone}
                          onChange={(e) =>
                            setUserData({ ...userData, phone: e.target.value })
                          }
                          className="border-2 border-primaryLightest px-3 py-2 rounded-xl mt-1 text-[14px] w-full bg-transparent focus:bg-[#f6f6f6] disabled:bg-primaryLightest focus:border-primaryLight hover:border-primaryLighter outline-none transition-all ease-in-out duration-300"
                        />
                      </div>
                    </div>
                    <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 w-full gap-6">
                      <div className="flex flex-col items-start justify-center w-full">
                        <span className="text-[#7A7A7A] font-[500] text-[14px] font-[Poppins] ms-1">
                          Change Password:
                        </span>
                        <input
                          type="password"
                          placeholder="Leave it blank if you don't want to change it"
                          value={userData.password}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              password: e.target.value,
                            })
                          }
                          className="border-2 border-primaryLightest px-3 py-2 rounded-xl mt-1 text-[14px] w-full bg-transparent focus:bg-[#f6f6f6] disabled:bg-primaryLightest focus:border-primaryLight hover:border-primaryLighter outline-none transition-all ease-in-out duration-300"
                        />
                      </div>
                      <div className="flex flex-col items-start justify-center w-full">
                        <span className="text-[#7A7A7A] font-[500] text-[14px] font-[Poppins] ms-1">
                          Confirm Password:
                        </span>
                        <input
                          type="password"
                          placeholder="Leave it blank if you don't want to change it"
                          value={userData.confirmPassword}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              confirmPassword: e.target.value,
                            })
                          }
                          className="border-2 border-primaryLightest px-3 py-2 rounded-xl mt-1 text-[14px] w-full bg-transparent focus:bg-[#f6f6f6] disabled:bg-primaryLightest focus:border-primaryLight hover:border-primaryLighter outline-none transition-all ease-in-out duration-300"
                        />
                      </div>
                    </div>
                    <div className="mt-8 flex items-center justify-start gap-4 w-full">
                      <button
                        onClick={() => resetData()}
                        className="w-fit border-b-2 border-b-primaryLightest rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300"
                      >
                        <div className="text-gray-500 px-5 py-3 rounded-2xl border-2 border-primaryLightest disabled:border-primaryLightest text-[14px] font-[600] flex items-center justify-center gap-3 w-fit">
                          Reset Data
                        </div>
                      </button>
                      <button
                        onClick={(e) => handleUpdateProfile(e)}
                        disabled={loadingUpdateUserData}
                        className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-fit"
                      >
                        Update Profile
                      </button>
                    </div>
                    <div className="mt-8">
                      <button
                        onClick={() => logoutUser()}
                        disabled={isLoadingLogout}
                        className="mt-4 bg-red-500 text-white px-5 py-3 rounded-2xl border-b-[4px] border-b-red-300 disabled:bg-red-300 disabled:border-b-red-200 hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-fit"
                      >
                        Logout User
                        <LogOut size={18} strokeWidth={3} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {profileTab === "purchases" && (
                <div className="w-full">
                  <div className="lg:ms-1">
                    <div className="text-[#242424] font-[600] text-[18px]">
                      Your Recent Purchases
                    </div>
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px] mt-1">
                      Find all of your purchases here !
                    </div>
                  </div>
                  <div className="mt-8 w-full flex flex-col items-center justify-center gap-6">
                    {userPurchases &&
                      userPurchases.map((purchase) => (
                        <PurchaseCard purchaseData={purchase} />
                      ))}
                    {userPurchases && userPurchases.length === 0 && (
                      <div className="text-[15px] text-gray-500 lg:ps-1 w-full">
                        No recent purchases was found !
                      </div>
                    )}
                  </div>
                </div>
              )}
              {profileTab === "bookmarkedTests" && (
                <div className="w-full">
                  <div className="lg:ms-1">
                    <div className="text-[#242424] font-[600] text-[18px]">
                      Your Bookmarked Tests
                    </div>
                    <div className="text-[#7A7A7A] font-[Poppins] text-[14px] mt-1">
                      Find all of your bookmarked tests here !
                    </div>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full mt-8">
                    {userDataHook &&
                      userDataHook.bookmarkedTests.map((test) => (
                        <TestCard
                          testData={test}
                          userData={userDataHook}
                          testType={
                            test.testType === "publicset" ? "public" : "custom"
                          }
                          addBookmarkTest={addBookmarkTest}
                          removeBookmarkTest={removeBookmarkTest}
                          loadingAddBookmarkTest={loadingAddBookmarkTest}
                          loadingRemoveBookmarkTest={loadingRemoveBookmarkTest}
                          deleteCustomTest={deleteCustomTest}
                          loadingDeleteCustomTest={loadingDeleteCustomTest}
                        />
                      ))}
                    {userDataHook &&
                      userDataHook.bookmarkedTests.length === 0 && (
                        <div className="text-[15px] text-gray-500 col-span-2 lg:ms-1">
                          No bookmarked tests found,{" "}
                          <Link
                            to="/courses"
                            className="text-primaryDarker text-[14px] underline"
                          >
                            browse available tests !
                          </Link>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="col-span-1 lg:col-span-3 flex flex-col items-start justify-start w-full gap-10 order-1 lg:order-2">
          {userDataHook && userDataHook.type === "free" && (
            <div className="border-b-[2px] border-b-primaryLightest rounded-[20px] w-full">
              <div className="border-2 border-primaryLightest rounded-2xl p-5 w-full">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="bg-gradient-to-r from-[#268AFF] to-[#FC55FF] w-fit px-3 py-[6px] text-white font-[600] font-[Poppins] text-[13px] rounded-lg flex items-center justify-center">
                      <span className="pt-[2px]">Go Premium</span>
                    </div>
                    <div className="text-[#242424] text-[18px] font-[600] mt-6 lg:max-w-[300px]">
                      Maximize Your Study Potential!
                    </div>
                    <div className="text-[#3A3A3A] font-[Poppins] font-[400] text-[14px] mt-4 lg:max-w-[300px] leading-6">
                      Access to unlimited tests, detailed result analytics, and
                      exclusive content awaits you !
                    </div>
                  </div>
                </div>
                <Link
                  to="/purchase-subscription"
                  state={{
                    previousLocation: location,
                  }}
                  className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-6"
                >
                  Upgrade Account
                </Link>
              </div>
            </div>
          )}
          {userDataHook && userDataHook.type === "paid" && (
            <div className="border-b-[2px] border-b-primaryLightest rounded-[20px] w-full">
              <div className="border-2 border-primaryLightest rounded-2xl p-5 w-full">
                <div className="flex flex-col items-center justify-between">
                  <div>
                    <div className="bg-gradient-to-r from-[#268AFF] to-[#FC55FF] w-fit px-3 py-[6px] text-white font-[600] font-[Poppins] text-[13px] rounded-lg flex items-center justify-center">
                      <span className="pt-[2px]">Premium Active</span>
                    </div>
                    <div className="text-[#242424] text-[18px] font-[600] mt-6 lg:max-w-[300px]">
                      Enjoy Exclusive Benefits !
                    </div>
                    <div className="text-[#3A3A3A] font-[Poppins] font-[400] text-[14px] mt-4 lg:max-w-[300px] leading-6">
                      You have access to unlimited tests, detailed result
                      analytics, and exclusive content !
                    </div>
                  </div>
                </div>
                <Link
                  to="/courses"
                  className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-6"
                >
                  Explore Courses
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
