import React from "react";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useGetPaidTestDetails } from "../../Hooks/useGetPaidTestDetails";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useGetSendPayment } from "../../Hooks/useGetSendPayment";
import { toast } from "react-toastify";
import khaltiLogo from "../../Assets/img/khalti-logo.png";
import { X } from "lucide-react";

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "1050",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "16px",
    padding: "0px",
    maxWidth: "500px",
    overflow: "hidden",
    width: "85%",
  },
};

const PurchaseModal = ({ purchaseData }) => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const {
    paidTestDetails,
    loadingPaidTestDetails,
    errorPaidTestDetails,
    getPaidTestDetails,
  } = useGetPaidTestDetails();

  const {
    sendPaymentData,
    loadingSendPayment,
    errorSendPayment,
    getSendPayment,
  } = useGetSendPayment();

  useEffect(() => {
    getPaidTestDetails(purchaseData.pId);

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const HandleTestPurchase = () => {
    getSendPayment(
      paidTestDetails._id,
      paidTestDetails.price,
      user.name,
      user.email,
      user.phone,
      "test"
    );
  };

  useEffect(() => {
    if (sendPaymentData) {
      window.location.href = sendPaymentData.payment_url;
    }
  }, [sendPaymentData]);

  useEffect(() => {
    if (errorSendPayment) {
      toast.error(errorSendPayment);
    }
  }, [errorSendPayment]);

  return (
    <Modal
      isOpen={true}
      style={modalStyles}
      closeTimeoutMS={250}
      onRequestClose={() => navigate(-1)}
    >
      {paidTestDetails && !loadingPaidTestDetails && !errorPaidTestDetails && (
        <div className="max-h-[90vh] overflow-scroll">
          <div className="p-6 w-full flex flex-col items-center justify-center">
            <div className="w-full flex items-center justify-between">
              <div className="text-[#242424] font-[600] text-[24px]">
                Purchase Details
              </div>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="h-[30px] w-[30px] rounded-[50%] bg-primaryLightest flex items-center justify-center"
              >
                <X />
              </button>
            </div>
            <div className="w-full flex items-center justify-between mt-8">
              <div className="text-[#7A7A7A] font-[Poppins] font-[500] text-[14px]">
                Product
              </div>
              <div className="text-[#242424] font-[600]">
                {paidTestDetails.title}
              </div>
            </div>
            <div className="w-full flex items-center justify-between mt-5">
              <div className="text-[#7A7A7A] font-[Poppins] font-[500] text-[14px]">
                Price
              </div>
              <div className="text-[#242424] font-[600]">
                Rs. {paidTestDetails.price}
              </div>
            </div>
            <div className="w-full flex items-center justify-between mt-5">
              <div className="text-[#7A7A7A] font-[Poppins] font-[500] text-[14px]">
                Category
              </div>
              <div className="text-[#242424] font-[600] uppercase">
                {paidTestDetails.category.name}
              </div>
            </div>
            <div className="w-full flex items-center justify-between mt-5">
              <div className="text-[#7A7A7A] font-[Poppins] font-[500] text-[14px]">
                Product Type
              </div>
              <div className="text-[#242424] font-[600]">Test</div>
            </div>
            <div className="w-full flex items-center justify-between mt-5">
              <div className="text-[#7A7A7A] font-[Poppins] font-[500] text-[14px]">
                Payment Method
              </div>
              <div className="text-[#242424] font-[600]">
                <img src={khaltiLogo} alt="khalti" className="w-[100px]" />
              </div>
            </div>
            <div className="mt-6 w-full bg-[rgba(234,90,12,0.125)] text-orange-600 p-3 rounded-xl text-[12px] font-[Poppins]">
              <span className="font-[600]">Note:</span> For alternative payment
              methods, please{" "}
              <Link
                to="mailto:7hS3h@example.com"
                className="underline font-[600]"
              >
                contact us
              </Link>{" "}
              for more information. We’re here to assist you!
            </div>
            <div className="w-full flex items-center justify-between gap-8">
              <button
                onClick={() => navigate(-1)}
                className="w-full border-b-2 border-b-primaryLightest rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300"
              >
                <div className="text-gray-500 px-5 py-3 rounded-2xl border-2 border-primaryLightest disabled:border-primaryLightest text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-6">
                  Cancel
                </div>
              </button>
              <button
                onClick={() => HandleTestPurchase()}
                disabled={loadingSendPayment}
                className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full mt-6"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PurchaseModal;
