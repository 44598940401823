import { useState } from "react";

export const useDeleteCustomTest = () => {
  const [loadingDeleteCustomTest, setLoadingDeleteCustomTest] = useState(false);
  const [errorDeleteCustomTest, setErrorDeleteCustomTest] = useState(null);
  const [successDeleteCustomTest, setSuccessDeleteCustomTest] = useState(null);

  const deleteCustomTest = async (id) => {
    try {
      setLoadingDeleteCustomTest(true);
      setErrorDeleteCustomTest(null);
      setSuccessDeleteCustomTest(null);

      const request = await fetch(`/api/delete_custom_set/${id}`, {
        method: "DELETE",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });

      const response = await request.json();

      if (!request.ok) {
        setErrorDeleteCustomTest(response.msg);
        setSuccessDeleteCustomTest(null);
        setLoadingDeleteCustomTest(false);
      } else {
        setSuccessDeleteCustomTest(response.msg);
        setErrorDeleteCustomTest(null);
        setLoadingDeleteCustomTest(false);
      }
    } catch (err) {
      setLoadingDeleteCustomTest(false);
      setErrorDeleteCustomTest(err.message);
      setSuccessDeleteCustomTest(null);
    }
  };

  return {
    deleteCustomTest,
    loadingDeleteCustomTest,
    errorDeleteCustomTest,
    successDeleteCustomTest,
  };
};
