import { useState } from "react";

export const useGetCategory = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [loadingCategoryData, setLoadingCategoryData] = useState(true);

  const getCategory = async (category) => {
    const response = await fetch(`/api/get_category/${category}`, {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });

    const json = await response.json();

    setCategoryData(json.category);
    setLoadingCategoryData(false);
  };
  return {
    getCategory,
    categoryData,
    loadingCategoryData,
  };
};
