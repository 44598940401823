import { useState } from "react";

export const useAddBookmarkTest = () => {
  const [loadingAddBookmarkTest, setLoadingAddBookmarkTest] = useState(false);
  const [errorAddBookmarkTest, setErrorAddBookmarkTest] = useState(null);
  const [successAddBookmarkTest, setSuccessAddBookmarkTest] = useState(null);

  const addBookmarkTest = async (id, type) => {
    try {
      setLoadingAddBookmarkTest(true);
      setErrorAddBookmarkTest(null);
      setSuccessAddBookmarkTest(null);

      const request = await fetch(`/api/add_bookmark`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_type: type,
          test_id: id,
        }),
      });

      const response = await request.json();

      if (!request.ok) {
        setLoadingAddBookmarkTest(false);
        setErrorAddBookmarkTest(response.msg);
        setSuccessAddBookmarkTest(null);
      } else {
        setLoadingAddBookmarkTest(false);
        setErrorAddBookmarkTest(null);
        setSuccessAddBookmarkTest(response);
      }
    } catch (err) {
      setLoadingAddBookmarkTest(false);
      setErrorAddBookmarkTest(err.message);
      setSuccessAddBookmarkTest(null);
    }
  };

  return {
    loadingAddBookmarkTest,
    errorAddBookmarkTest,
    successAddBookmarkTest,
    addBookmarkTest,
  };
};
