import { useState } from "react";

export const useCreateCustomTest = () => {
  const [loadingCreateCustomTest, setLoadingCreateCustomTest] = useState(null);
  const [messageCreateCustomTest, setMessageCreateCustomTest] = useState(null);
  const [errorCreateCustomTest, setErrorCreateCustomTest] = useState(null);

  const createCustomTest = async (testData) => {
    setLoadingCreateCustomTest(true);

    setErrorCreateCustomTest(null);
    setMessageCreateCustomTest(null);

    const response = await fetch(`/api/create_custom_test`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: testData.name,
        description: testData.description,
        category: testData.category,
        questions: testData.questions,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      setLoadingCreateCustomTest(false);
      setMessageCreateCustomTest(data.msg);
      setErrorCreateCustomTest(null);
    } else {
      setLoadingCreateCustomTest(false);
      setMessageCreateCustomTest(null);
      setErrorCreateCustomTest(data.msg);
    }
  };
  return {
    createCustomTest,
    loadingCreateCustomTest,
    messageCreateCustomTest,
    errorCreateCustomTest,
  };
};
