import React, { useEffect, useState } from "react";
import {
  Bookmark,
  BookmarkCheck,
  Clock,
  CircleHelp,
  Play,
  Lock,
  Eye,
  RotateCcw,
  Trash,
} from "lucide-react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Link, useLocation } from "react-router-dom";

const TestCard = ({
  testData,
  testType,
  userData,
  originalTestData,
  addBookmarkTest,
  removeBookmarkTest,
  loadingAddBookmarkTest,
  loadingRemoveBookmarkTest,
  deleteCustomTest,
  loadingDeleteCustomTest,
}) => {
  const location = useLocation();
  const [testDataToAccess, setTestDataToAccess] = useState(null);

  useEffect(() => {
    if (testData) {
      const testDataPrefix = originalTestData ? null : "testId";
      if (testDataPrefix) {
        setTestDataToAccess(testData[testDataPrefix]);
      } else {
        setTestDataToAccess(testData);
      }
    }
  }, [testData, originalTestData]);
  if (!testDataToAccess) return null;

  return (
    <div className="border-b-2 border-b-primaryLightest rounded-[18px]">
      <div
        className={`border-[1px] border-primaryLightest p-[1.5rem] rounded-[1rem] h-full`}
      >
        <div className="flex flex-col items-start justify-start h-full w-full">
          <div className="flex flex-col items-start justify-start h-full w-full">
            <div className="flex items-center justify-between w-full">
              <div
                className={`${
                  testType === "public"
                    ? testDataToAccess.type === "free"
                      ? "bg-primaryGreen"
                      : "bg-gradient-to-r from-[#268AFF] to-[#FC55FF]"
                    : "bg-primaryBlue text-white"
                } w-fit px-2 py-1 rounded-lg text-white text-[12px] capitalize font-[Poppins] font-[500]`}
              >
                {testType === "public"
                  ? testDataToAccess.type === "free"
                    ? "free"
                    : "premium"
                  : "Custom"}
              </div>
              <div className="flex items-center justify-between gap-4">
                {testType !== "public" &&
                  testDataToAccess.createdBy === userData._id && (
                    <button
                      onClick={() => {
                        deleteCustomTest(testDataToAccess._id);
                      }}
                      disabled={loadingDeleteCustomTest}
                      className="border-b-[3px] border-b-red-300 hover:border-b-0 bg-red-600 text-white rounded-[50%] transition-all ease-in-out duration-300"
                    >
                      <div className="w-[35px] h-[35px] flex items-center justify-center rounded-[50%]">
                        <Trash size={18} />
                      </div>
                    </button>
                  )}
                <div
                  className={`${
                    userData.bookmarkedTests.some(
                      (bm) => bm.testId._id === testDataToAccess._id
                    )
                      ? "!border-b-blueLight !border-b-[3px] !bg-primaryBlue"
                      : ""
                  } border-b-2 border-b-primaryDarker rounded-[50%] hover:border-b-0 transition-all ease-in-out duration-300`}
                >
                  <button
                    onClick={() => {
                      if (
                        userData.bookmarkedTests.some(
                          (bm) => bm.testId._id === testDataToAccess._id
                        )
                      ) {
                        removeBookmarkTest(testDataToAccess._id, testType);
                      } else {
                        addBookmarkTest(testDataToAccess._id, testType);
                      }
                    }}
                    disabled={
                      loadingAddBookmarkTest || loadingRemoveBookmarkTest
                    }
                    className={`${
                      userData.bookmarkedTests.some(
                        (bm) => bm.testId._id === testDataToAccess._id
                      )
                        ? "!border-primaryBlue bg-primaryBlue"
                        : "bg-transparent"
                    } w-[35px] h-[35px] border-[1px] border-primaryDark flex items-center justify-center rounded-[50%]`}
                  >
                    {userData.bookmarkedTests.some(
                      (bm) => bm.testId._id === testDataToAccess._id
                    ) ? (
                      <BookmarkCheck size={20} color="#fff" />
                    ) : (
                      <Bookmark size={20} />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="text-[#242424] font-[500] text-[24px] mt-2 truncate max-w-[275px] font-[Poppins]">
              {testDataToAccess.title}
            </div>
            <div className="text-gray-500 font-[500] text-[14px] mt-2 truncate max-w-[200px] font-[Poppins]">
              {testDataToAccess.description}
            </div>
            <div className="mt-5 flex flex-row items-center justify-start w-full gap-4 font-[Poppins] font-[500] text-[14px]">
              <div className="flex items-center justify-start gap-2">
                <Clock size={22} color="#0266ff" />
                <span>
                  {new Date(1000 * testDataToAccess.time)
                    .toISOString()
                    .substr(11, 8)}{" "}
                  hr
                </span>
              </div>
              <div className="flex items-center justify-start gap-2">
                <CircleHelp size={22} color="#08bd80" />
                <span>{testDataToAccess.questions.length} question/s</span>
              </div>
            </div>
            <div className="border-[1px] border-primaryLight mt-5 w-fit px-2 py-1 rounded-lg text-primaryDark text-[12px] uppercase font-[Poppins] font-[500]">
              COURSE:{" "}
              <span className="font-[700]">
                {testDataToAccess.category.name}
              </span>
            </div>
            <div className="h-[1px] border-b-[1px] w-full my-5 "></div>
          </div>
          <div className="flex flex-col items-start justify-end h-full w-full">
            {testType === "public"
              ? userData &&
                !userData.activePublicTests.some(
                  (el) =>
                    el.testId._id === testDataToAccess._id &&
                    (el.currentTime !== 0 ||
                      el.currentQuestion !== 0 ||
                      el.currentScore !== 0)
                ) && (
                  <div className="w-full flex items-center justify-between">
                    <div className="flex flex-col items-start justify-center gap-1">
                      <div
                        className={
                          "text-[#7A7A7A] font-[Poppins] text-[12px] font-[400]"
                        }
                      >
                        Last Score / Time
                      </div>
                      <div className="text-[#3A3A3A] font-[600] text-[18px]">
                        {testType === "public"
                          ? userData.activePublicTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                            ? userData.activePublicTests
                                .find(
                                  (el) => el.testId._id === testDataToAccess._id
                                )
                                .lastScore.toFixed(2) + " pts"
                            : Number(0).toFixed(2) + " pts"
                          : userData.activeCustomTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                          ? userData.activeCustomTests
                              .find(
                                (el) => el.testId._id === testDataToAccess._id
                              )
                              .lastScore.toFixed(2) + " pts"
                          : Number(0).toFixed(2) + " pts"}
                      </div>
                      <div className="text-[#3A3A3A] font-[500] text-[14px]">
                        {testType === "public"
                          ? userData.activePublicTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                            ? new Date(
                                1000 *
                                  userData.activePublicTests.find(
                                    (el) =>
                                      el.testId._id === testDataToAccess._id
                                  ).lastTime
                              )
                                .toISOString()
                                .substr(11, 8) + " hr/s"
                            : "00:00:00 hr/s"
                          : userData.activeCustomTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                          ? new Date(
                              1000 *
                                userData.activeCustomTests.find(
                                  (el) => el.testId._id === testDataToAccess._id
                                ).lastTime
                            )
                              .toISOString()
                              .substr(11, 8) + " hr/s"
                          : "00:00:00 hr/s"}
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-center gap-1">
                      <div
                        className={
                          "text-[#7A7A7A] font-[Poppins] text-[12px] font-[400]"
                        }
                      >
                        Best Score / Time
                      </div>
                      <div className="text-[#3A3A3A] font-[600] text-[18px]">
                        {testType === "public"
                          ? userData.activePublicTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                            ? userData.activePublicTests
                                .find(
                                  (el) => el.testId._id === testDataToAccess._id
                                )
                                .bestScore.toFixed(2) + " pts"
                            : Number(0).toFixed(2) + " pts"
                          : userData.activeCustomTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                          ? userData.activeCustomTests
                              .find(
                                (el) => el.testId._id === testDataToAccess._id
                              )
                              .bestScore.toFixed(2) + " pts"
                          : Number(0).toFixed(2) + " pts"}
                      </div>
                      <div className="text-[#3A3A3A] font-[500] text-[14px]">
                        {testType === "public"
                          ? userData.activePublicTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                            ? new Date(
                                1000 *
                                  userData.activePublicTests.find(
                                    (el) =>
                                      el.testId._id === testDataToAccess._id
                                  ).bestTime
                              )
                                .toISOString()
                                .substr(11, 8) + " hr/s"
                            : "00:00:00 hr/s"
                          : userData.activeCustomTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                          ? new Date(
                              1000 *
                                userData.activeCustomTests.find(
                                  (el) => el.testId._id === testDataToAccess._id
                                ).bestTime
                            )
                              .toISOString()
                              .substr(11, 8) + " hr/s"
                          : "00:00:00 hr/s"}
                      </div>
                    </div>
                  </div>
                )
              : userData &&
                !userData.activeCustomTests.some(
                  (el) =>
                    el.testId._id === testDataToAccess._id &&
                    (el.currentTime !== 0 ||
                      el.currentQuestion !== 0 ||
                      el.currentScore !== 0)
                ) && (
                  <div className="w-full flex items-center justify-between">
                    <div className="flex flex-col items-start justify-center gap-1">
                      <div
                        className={
                          "text-[#7A7A7A] font-[Poppins] text-[12px] font-[400]"
                        }
                      >
                        Last Score / Time
                      </div>
                      <div className="text-[#3A3A3A] font-[600] text-[18px]">
                        {testType === "public"
                          ? userData.activePublicTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                            ? userData.activePublicTests
                                .find(
                                  (el) => el.testId._id === testDataToAccess._id
                                )
                                .lastScore.toFixed(2) + " pts"
                            : Number(0).toFixed(2) + " pts"
                          : userData.activeCustomTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                          ? userData.activeCustomTests
                              .find(
                                (el) => el.testId._id === testDataToAccess._id
                              )
                              .lastScore.toFixed(2) + " pts"
                          : Number(0).toFixed(2) + " pts"}
                      </div>
                      <div className="text-[#3A3A3A] font-[500] text-[14px]">
                        {testType === "public"
                          ? userData.activePublicTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                            ? new Date(
                                1000 *
                                  userData.activePublicTests.find(
                                    (el) =>
                                      el.testId._id === testDataToAccess._id
                                  ).lastTime
                              )
                                .toISOString()
                                .substr(11, 8) + " hr/s"
                            : "00:00:00 hr/s"
                          : userData.activeCustomTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                          ? new Date(
                              1000 *
                                userData.activeCustomTests.find(
                                  (el) => el.testId._id === testDataToAccess._id
                                ).lastTime
                            )
                              .toISOString()
                              .substr(11, 8) + " hr/s"
                          : "00:00:00 hr/s"}
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-center gap-1">
                      <div
                        className={
                          "text-[#7A7A7A] font-[Poppins] text-[12px] font-[400]"
                        }
                      >
                        Best Score / Time
                      </div>
                      <div className="text-[#3A3A3A] font-[600] text-[18px]">
                        {testType === "public"
                          ? userData.activePublicTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                            ? userData.activePublicTests
                                .find(
                                  (el) => el.testId._id === testDataToAccess._id
                                )
                                .bestScore.toFixed(2) + " pts"
                            : Number(0).toFixed(2) + " pts"
                          : userData.activeCustomTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                          ? userData.activeCustomTests
                              .find(
                                (el) => el.testId._id === testDataToAccess._id
                              )
                              .bestScore.toFixed(2) + " pts"
                          : Number(0).toFixed(2) + " pts"}
                      </div>
                      <div className="text-[#3A3A3A] font-[500] text-[14px]">
                        {testType === "public"
                          ? userData.activePublicTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                            ? new Date(
                                1000 *
                                  userData.activePublicTests.find(
                                    (el) =>
                                      el.testId._id === testDataToAccess._id
                                  ).bestTime
                              )
                                .toISOString()
                                .substr(11, 8) + " hr/s"
                            : "00:00:00 hr/s"
                          : userData.activeCustomTests.some(
                              (el) => el.testId._id === testDataToAccess._id
                            )
                          ? new Date(
                              1000 *
                                userData.activeCustomTests.find(
                                  (el) => el.testId._id === testDataToAccess._id
                                ).bestTime
                            )
                              .toISOString()
                              .substr(11, 8) + " hr/s"
                          : "00:00:00 hr/s"}
                      </div>
                    </div>
                  </div>
                )}
            {testType === "public" ? (
              <>
                {userData.activePublicTests.some(
                  (el) =>
                    el.testId._id === testDataToAccess._id &&
                    el.currentTime !== 0
                ) && (
                  <div className="w-full px-1">
                    <div className="text-[12px] text-gray-600 mb-3">
                      <b>This test won’t finish itself… or will it?</b> Don’t
                      wait to find out, wrap it up!
                    </div>
                    <div className="flex items-center justify-between w-full text-[#242424] text-[12px] font-[Poppins] font-[500] mb-1">
                      <div>
                        Completed :{" "}
                        {Math.abs(
                          (userData.activePublicTests.find(
                            (el) => el.testId._id === testDataToAccess._id
                          ).currentQuestion /
                            testDataToAccess.questions.length) *
                            100
                        ).toFixed(2)}
                        %
                      </div>
                      <div>
                        {
                          userData.activePublicTests.find(
                            (el) => el.testId._id === testDataToAccess._id
                          ).currentQuestion
                        }
                        /{testDataToAccess.questions.length}
                      </div>
                    </div>
                    <ProgressBar
                      completed={Math.abs(
                        (userData.activePublicTests.find(
                          (el) => el.testId._id === testDataToAccess._id
                        ).currentQuestion /
                          testDataToAccess.questions.length) *
                          100
                      )}
                      height="10px"
                      bgColor="#0266ff"
                      isLabelVisible={false}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {userData.activeCustomTests.some(
                  (el) =>
                    el.testId._id === testDataToAccess._id &&
                    el.currentTime !== 0
                ) && (
                  <div className="w-full px-1">
                    <div className="text-[12px] text-gray-600 mb-3">
                      <b>This test won’t finish itself… or will it?</b> Don’t
                      wait to find out, wrap it up!
                    </div>
                    <div className="flex items-center justify-between w-full text-[#242424] text-[12px] font-[Poppins] font-[500] mb-1">
                      <div>
                        Completed :{" "}
                        {Math.abs(
                          (userData.activeCustomTests.find(
                            (el) => el.testId._id === testDataToAccess._id
                          ).currentQuestion /
                            testDataToAccess.questions.length) *
                            100
                        ).toFixed(2)}
                        %
                      </div>
                      <div>
                        {
                          userData.activeCustomTests.find(
                            (el) => el.testId._id === testDataToAccess._id
                          ).currentQuestion
                        }
                        /{testDataToAccess.questions.length}
                      </div>
                    </div>
                    <ProgressBar
                      completed={Math.abs(
                        (userData.activeCustomTests.find(
                          (el) => el.testId._id === testDataToAccess._id
                        ).currentQuestion /
                          testDataToAccess.questions.length) *
                          100
                      )}
                      height="10px"
                      bgColor="#0266ff"
                      isLabelVisible={false}
                    />
                  </div>
                )}
              </>
            )}

            {testType === "public" ? (
              <>
                {testDataToAccess.type === "free" && (
                  <>
                    {userData.activePublicTests.some(
                      (el) =>
                        el.testId._id === testDataToAccess._id &&
                        el.currentTime !== 0
                    ) ? (
                      <Link
                        to={`/public_test/${testDataToAccess._id}`}
                        className="border-b-[4px] border-b-blueLight w-full rounded-[18px] hover:border-b-0 transition-all ease-in-out duration-300 mt-6"
                      >
                        <div className="bg-transparent text-primaryBlue px-5 py-3 rounded-2xl border-[2px] border-primaryBlue text-[14px] font-[600] flex items-center justify-center gap-2 w-full">
                          <span>Resume Test</span>
                          <Play size={16} strokeWidth={3} className="mb-1" />
                        </div>
                      </Link>
                    ) : userData.activePublicTests.some(
                        (el) =>
                          el.testId._id === testDataToAccess._id &&
                          el.selectedAnswers.finished === true
                      ) ? (
                      <div className="flex items-center justify-between w-full gap-4">
                        <Link
                          to={`/public_result/${testDataToAccess._id}`}
                          className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-2 w-full mt-6 flex-grow"
                        >
                          <span>View Result</span>
                          <Eye size={16} strokeWidth={3} className="mb-1" />
                        </Link>
                        <Link
                          to={`/public_test/${testDataToAccess._id}`}
                          className="border-b-4 border-blueLight rounded-[18px] hover:border-b-0 transition-all ease-in-out duration-300"
                        >
                          <div className="flex items-center justify-center py-3 px-3 mt-6 border-2 border-primaryBlue rounded-2xl">
                            <RotateCcw size={20} color="#0266ff" />
                          </div>
                        </Link>
                      </div>
                    ) : (
                      <Link
                        to={`/public_test/${testDataToAccess._id}`}
                        className="bg-primaryGreen text-white px-5 py-3 rounded-2xl border-b-[4px] border-b-greenDark hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-2 w-full mt-6"
                      >
                        <span>Start Test</span>
                        <Play size={16} strokeWidth={3} className="mb-1" />
                      </Link>
                    )}
                  </>
                )}
                {testDataToAccess.type === "paid" && (
                  <>
                    {userData.type === "paid" ? (
                      <>
                        {userData.activePublicTests.some(
                          (el) =>
                            el.testId._id === testDataToAccess._id &&
                            el.currentTime !== 0
                        ) ? (
                          <Link
                            to={`/public_test/${testDataToAccess._id}`}
                            className="border-b-[4px] border-b-blueLight w-full rounded-[18px] hover:border-b-0 transition-all ease-in-out duration-300 mt-6"
                          >
                            <div className="bg-transparent text-primaryBlue px-5 py-3 rounded-2xl border-[2px] border-primaryBlue text-[14px] font-[600] flex items-center justify-center gap-2 w-full">
                              <span>Resume Test</span>
                              <Play
                                size={16}
                                strokeWidth={3}
                                className="mb-1"
                              />
                            </div>
                          </Link>
                        ) : userData.activePublicTests.some(
                            (el) =>
                              el.testId._id === testDataToAccess._id &&
                              el.selectedAnswers.finished === true
                          ) ? (
                          <div className="flex items-center justify-between w-full gap-4">
                            <Link
                              to={`/public_result/${testDataToAccess._id}`}
                              className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-2 w-full mt-6 flex-grow"
                            >
                              <span>View Result</span>
                              <Eye size={16} strokeWidth={3} className="mb-1" />
                            </Link>
                            <Link
                              to={`/public_test/${testDataToAccess._id}`}
                              className="border-b-4 border-blueLight rounded-[18px] hover:border-b-0 transition-all ease-in-out duration-300"
                            >
                              <div className="flex items-center justify-center py-3 px-3 mt-6 border-2 border-primaryBlue rounded-2xl">
                                <RotateCcw size={20} color="#0266ff" />
                              </div>
                            </Link>
                          </div>
                        ) : (
                          <Link
                            to={`/public_test/${testDataToAccess._id}`}
                            className="bg-primaryGreen text-white px-5 py-3 rounded-2xl border-b-[4px] border-b-greenDark hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-2 w-full mt-6"
                          >
                            <span>Start Test</span>
                            <Play size={16} strokeWidth={3} className="mb-1" />
                          </Link>
                        )}
                      </>
                    ) : (
                      <>
                        {userData.purchasedTests.some(
                          (test) => test._id === testDataToAccess._id
                        ) ? (
                          <>
                            {userData.activePublicTests.some(
                              (el) =>
                                el.testId._id === testDataToAccess._id &&
                                el.currentTime !== 0
                            ) ? (
                              <Link
                                to={`/public_test/${testDataToAccess._id}`}
                                className="border-b-[4px] border-b-blueLight w-full rounded-[18px] hover:border-b-0 transition-all ease-in-out duration-300 mt-6"
                              >
                                <div className="bg-transparent text-primaryBlue px-5 py-3 rounded-2xl border-[2px] border-primaryBlue text-[14px] font-[600] flex items-center justify-center gap-2 w-full">
                                  <span>Resume Test</span>
                                  <Play
                                    size={16}
                                    strokeWidth={3}
                                    className="mb-1"
                                  />
                                </div>
                              </Link>
                            ) : userData.activePublicTests.some(
                                (el) =>
                                  el.testId._id === testDataToAccess._id &&
                                  el.selectedAnswers.finished === true
                              ) ? (
                              <div className="flex items-center justify-between w-full gap-4">
                                <Link
                                  to={`/public_result/${testDataToAccess._id}`}
                                  className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-2 w-full mt-6 flex-grow"
                                >
                                  <span>View Result</span>
                                  <Eye
                                    size={16}
                                    strokeWidth={3}
                                    className="mb-1"
                                  />
                                </Link>
                                <Link
                                  to={`/public_test/${testDataToAccess._id}`}
                                  className="border-b-4 border-blueLight rounded-[18px] hover:border-b-0 transition-all ease-in-out duration-300"
                                >
                                  <div className="flex items-center justify-center py-3 px-3 mt-6 border-2 border-primaryBlue rounded-2xl">
                                    <RotateCcw size={20} color="#0266ff" />
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              <Link
                                to={`/public_test/${testDataToAccess._id}`}
                                className="bg-primaryGreen text-white px-5 py-3 rounded-2xl border-b-[4px] border-b-greenDark hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-2 w-full mt-6"
                              >
                                <span>Start Test</span>
                                <Play
                                  size={16}
                                  strokeWidth={3}
                                  className="mb-1"
                                />
                              </Link>
                            )}
                          </>
                        ) : (
                          <Link
                            to="/purchase-details"
                            state={{
                              previousLocation: location,
                              purchaseData: {
                                pId: testDataToAccess._id,
                              },
                            }}
                            className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-2 w-full mt-6"
                          >
                            <span>Purchase Test</span>
                            <Lock size={16} strokeWidth={2.5} className="mb-1" />
                          </Link>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {userData.activeCustomTests.some(
                  (el) =>
                    el.testId._id === testDataToAccess._id &&
                    el.currentTime !== 0
                ) ? (
                  <Link
                    to={`/custom_test/${testDataToAccess._id}`}
                    className="border-b-[4px] border-b-blueLight w-full rounded-[18px] hover:border-b-0 transition-all ease-in-out duration-300 mt-6"
                  >
                    <div className="bg-transparent text-primaryBlue px-5 py-3 rounded-2xl border-[2px] border-primaryBlue text-[14px] font-[600] flex items-center justify-center gap-2 w-full">
                      <span>Resume Test</span>
                      <Play size={16} strokeWidth={3} className="mb-1" />
                    </div>
                  </Link>
                ) : userData.activeCustomTests.some(
                    (el) =>
                      el.testId._id === testDataToAccess._id &&
                      el.selectedAnswers.finished === true
                  ) ? (
                  <div className="flex items-center justify-between w-full gap-4">
                    <Link
                      to={`/custom_result/${testDataToAccess._id}`}
                      className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-2 w-full mt-6 flex-grow"
                    >
                      <span>View Result</span>
                      <Eye size={16} strokeWidth={3} className="mb-1" />
                    </Link>
                    <Link
                      to={`/custom_test/${testDataToAccess._id}`}
                      className="border-b-4 border-blueLight rounded-[18px] hover:border-b-0 transition-all ease-in-out duration-300"
                    >
                      <div className="flex items-center justify-center py-3 px-3 mt-6 border-2 border-primaryBlue rounded-2xl">
                        <RotateCcw size={20} color="#0266ff" />
                      </div>
                    </Link>
                  </div>
                ) : (
                  <Link
                    to={`/custom_test/${testDataToAccess._id}`}
                    className="bg-primaryGreen text-white px-5 py-3 rounded-2xl border-b-[4px] border-b-greenDark hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-2 w-full mt-6"
                  >
                    <span>Start Test</span>
                    <Play size={16} strokeWidth={3} className="mb-1" />
                  </Link>
                )}
              </>
            )}
            {/* {testType === "public"
              ? userData.activePublicTests.some(
                  (el) =>
                    el.selectedAnswers.finished === true &&
                    el.testId._id === testDataToAccess._id
                ) && (
                  <Link
                    to={`/public_result/${testDataToAccess._id}`}
                    className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-2 w-full mt-3"
                  >
                    <span>View Results</span>
                    <Eye size={16} className="mb-1" />
                  </Link>
                )
              : userData.activeCustomTests.some(
                  (el) =>
                    el.selectedAnswers.finished === true &&
                    el.testId._id === testDataToAccess._id
                ) && (
                  <Link
                    to={`/custom_result/${testDataToAccess._id}`}
                    className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-2 w-full mt-3"
                  >
                    <span>View Results</span>
                    <Eye size={16} className="mb-1" />
                  </Link>
                )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestCard;
