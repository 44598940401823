import React from "react";
import { Link } from "react-router-dom";

const InTouch = () => {
  return (
    <div id="getintouch" className="container py-12">
      <div className="flex flex-col items-center justify-center w-full">
        <div className="text-[#242424] font-[700] text-[36px] text-center">
          Get in Touch with Us !
        </div>
        <div className="text-[#7A7A7A] font-[Poppins] text-[18px] mt-2 text-center max-w-[1000px]">
          Get your message across and make your customers come back for more
          with a simple, fast, and integrable bulk sms service provider.
        </div>
      </div>
      <div className="flex items-center justify-center gap-4 mt-4">
        <Link
          to="https://www.facebook.com/profile.php?id=100089786396817"
          className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-fit mt-6"
        >
          Send Message
        </Link>
        <Link
          to="mailto:sajhaentrance659@gmail.com"
          className="w-fit border-b-2 border-b-primaryLightest rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300"
        >
          <div className="text-gray-500 px-5 py-3 rounded-2xl border-2 border-primaryLightest disabled:border-primaryLightest text-[14px] font-[600] flex items-center justify-center gap-3 w-fit mt-6">
            Send Email
          </div>
        </Link>
      </div>
    </div>
  );
};

export default InTouch;
