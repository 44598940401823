import { Link } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { useGetCategories } from "../../Hooks/useGetCategories";
import { baseUrl } from "../../Utils/BaseUrl";
import { LoadingContexts } from "../../Contexts/LoadingContext";

const Courses = () => {
  const { getCategories, loadingCategories, categories } = useGetCategories();

  const { isLoadingSomething, setIsLoadingSomething } =
    useContext(LoadingContexts);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (loadingCategories && !isLoadingSomething) {
      setIsLoadingSomething(true);
    }

    if (!loadingCategories && isLoadingSomething) {
      setIsLoadingSomething(false);
    }
  }, [loadingCategories]);

  return (
    <div className="flex items-start justify-center w-full pb-[80px] lg:pb-0">
      <div className="container grid grid-cols-1 lg:grid-cols-12 gap-10 py-8">
        <div className="hidden lg:block col-span-3 relative">
          <Sidebar />
        </div>
        <div className="col-span-1 lg:col-span-9 flex flex-col items-start justify-start w-full">
          <div className="w-full text-center mb-8 font-[600] text-[28px]">
            Choose a Course to Begin !
          </div>
          {loadingCategories ? (
            <></>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-10">
              {categories &&
                categories.map((category) => (
                  <Link
                    to={`/course/${category.name}`}
                    className="border-b-2 border-b-primaryLightest rounded-[20px] group hover:border-b-primaryLighter transition-all ease-in-out duration-300"
                  >
                    <div className="p-4 rounded-2xl border-2 border-primaryLightest group-hover:border-primaryLighter transition-all ease-in-out duration-300 flex flex-col items-center justify-center uppercase text-[15px] font-[500] font-[Poppins] gap-2 h-full">
                      <div className="max-w-[75px] max-h-[75px]">
                        <img
                          src={`${baseUrl}/category/icon/${category.categoryImg}`}
                          className="w-full h-full"
                        />
                      </div>
                      <div className="text-center normal-case mt-1">
                        {category.fullName}{" "}
                        <span className="uppercase">({category.name})</span>
                      </div>
                      <div className="text-[#7A7A7A] text-[12px] font-[500] font-[Poppins] capitalize mt-1">
                        {category.testCount !== 0 ? category.testCount - 1 : 0}+
                        Mock Tests
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Courses;
