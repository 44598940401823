import { useState } from "react";

export const useGetLeaderBoardTime = () => {
  const [loadingLeaderBoardTime, setLoadingLeaderBoardTime] = useState(false);
  const [errorLeaderBoardTime, setErrorLeaderBoardTime] = useState(null);
  const [leaderBoardTime, setLeaderBoardTime] = useState([]);

  const getLeaderBoardTime = async () => {
    try {
      setLoadingLeaderBoardTime(true);
      setErrorLeaderBoardTime(null);
      setLeaderBoardTime([]);

      const response = await fetch(`/api/leaderboard_time`, {
        method: "GET",
      });

      const json = await response.json();

      if (response.ok) {
        setLeaderBoardTime(json.data);
      } else {
        setErrorLeaderBoardTime(json.msg);
      }

      setLoadingLeaderBoardTime(false);
    } catch (err) {
      setLoadingLeaderBoardTime(false);
      setErrorLeaderBoardTime(err.message);
    }
  };

  return {
    loadingLeaderBoardTime,
    errorLeaderBoardTime,
    leaderBoardTime,
    getLeaderBoardTime,
  };
};
