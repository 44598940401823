import { useState } from "react";

export const useRemoveBookmarkTest = () => {
  const [loadingRemoveBookmarkTest, setLoadingRemoveBookmarkTest] =
    useState(false);
  const [errorRemoveBookmarkTest, setErrorRemoveBookmarkTest] = useState(null);
  const [successRemoveBookmarkTest, setSuccessRemoveBookmarkTest] =
    useState(null);

  const removeBookmarkTest = async (id, type) => {
    try {
      setLoadingRemoveBookmarkTest(true);
      setErrorRemoveBookmarkTest(null);
      setSuccessRemoveBookmarkTest(null);

      const request = await fetch(`/api/remove_bookmark`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_type: type,
          test_id: id,
        }),
      });

      const response = await request.json();

      if (!request.ok) {
        setLoadingRemoveBookmarkTest(false);
        setErrorRemoveBookmarkTest(response.msg);
        setSuccessRemoveBookmarkTest(null);
      } else {
        setLoadingRemoveBookmarkTest(false);
        setErrorRemoveBookmarkTest(null);
        setSuccessRemoveBookmarkTest(response.msg);
      }
    } catch (err) {
      setLoadingRemoveBookmarkTest(false);
      setErrorRemoveBookmarkTest(err.message);
      setSuccessRemoveBookmarkTest(null);
    }
  };

  return {
    loadingRemoveBookmarkTest,
    errorRemoveBookmarkTest,
    successRemoveBookmarkTest,
    removeBookmarkTest,
  };
};
