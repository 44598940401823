import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useGetCategory } from "../../Hooks/useGetCategory";
import { useCreateCustomTest } from "../../Hooks/useCreateCustomTest";

import { TestModalContexts } from "../../Contexts/TestModalContext";
import { X } from "lucide-react";

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "1050",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "16px",
    padding: "0px",
    maxWidth: "500px",
    overflow: "hidden",
    width: "85%",
  },
};

const CreateTestModal = (props) => {
  const navigate = useNavigate();

  const { setRedirectedFromTestModal } = useContext(TestModalContexts);

  const { categoryData, getCategory, loadingCategoryData } = useGetCategory();
  const {
    createCustomTest,
    loadingCreateCustomTest,
    messageCreateCustomTest,
    errorCreateCustomTest,
  } = useCreateCustomTest();

  const [customData, setCustomData] = useState({
    name: "",
    description: "",
    category: "",
    questions: [],
  });

  useEffect(() => {
    getCategory(props.category);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (!loadingCategoryData) {
      setCustomData((prev) => ({
        ...prev,
        category: categoryData._id,
      }));

      categoryData.subjects.map((subject) => {
        setCustomData((prev) => ({
          ...prev,
          questions: [
            ...prev.questions,
            {
              subject: subject._id,
              number: 0,
            },
          ],
        }));
      });
    }
  }, [loadingCategoryData]);

  const handleCreateCustomTest = async () => {
    if (!customData.name) {
      return toast.info("Title is required !");
    }

    if (!customData.description) {
      return toast.info("Description is required !");
    }

    var questionCount = 0;

    customData.questions.map((question) => {
      questionCount += question.number;
    });

    if (questionCount < 25) {
      return toast.info("Minimum 25 questions required !");
    } else if (questionCount > 100) {
      return toast.info("Maximum 100 questions allowed !");
    }

    await createCustomTest(customData);
  };

  useEffect(() => {
    if (errorCreateCustomTest) {
      toast.info(errorCreateCustomTest);
    }

    if (messageCreateCustomTest) {
      toast.info(messageCreateCustomTest);
      setRedirectedFromTestModal(true);
      navigate(-1);
    }
  }, [errorCreateCustomTest, messageCreateCustomTest]);

  return (
    <Modal
      isOpen={true}
      style={modalStyles}
      closeTimeoutMS={250}
      onRequestClose={() => navigate(-1)}
    >
      <div className="max-h-[90vh] overflow-scroll">
        <div className="p-6 w-full flex flex-col items-center justify-center">
          <div className="w-full flex items-center justify-between">
            <div className="text-[#242424] font-[600] text-[24px]">
              Create Custom Test
            </div>
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="h-[30px] w-[30px] rounded-[50%] bg-primaryLightest flex items-center justify-center"
            >
              <X />
            </button>
          </div>
          {loadingCategoryData ? (
            <div className="text-[15px] text-gray-500 mt-10">
              Loading preferenences, please wait !
            </div>
          ) : (
            <div className="w-full mt-6">
              <div className="grid grid-cols-2 gap-6 font-[Poppins]">
                <div className="col-span-2">
                  <span className="text-[#7A7A7A] font-[500] text-[14px] font-[Poppins] ms-1">
                    Test Title:
                  </span>
                  <input
                    type="text"
                    placeholder="Add a title for your test..."
                    onChange={(e) =>
                      setCustomData({ ...customData, name: e.target.value })
                    }
                    className="border-2 border-primaryLightest px-3 py-2 rounded-xl mt-1 text-[14px] w-full bg-transparent focus:bg-[#f6f6f6] disabled:bg-primaryLightest focus:border-primaryLight hover:border-primaryLighter outline-none transition-all ease-in-out duration-300 placeholder:text-[#b7b7b7]"
                  />
                </div>
                <div className="col-span-2">
                  <span className="text-[#7A7A7A] font-[500] text-[14px] font-[Poppins] ms-1">
                    Test Description:
                  </span>
                  <input
                    type="text"
                    placeholder="Add a description about the test..."
                    onChange={(e) =>
                      setCustomData({
                        ...customData,
                        description: e.target.value,
                      })
                    }
                    className="border-2 border-primaryLightest px-3 py-2 rounded-xl mt-1 text-[14px] w-full bg-transparent focus:bg-[#f6f6f6] disabled:bg-primaryLightest focus:border-primaryLight hover:border-primaryLighter outline-none transition-all ease-in-out duration-300 placeholder:text-[#b7b7b7]"
                  />
                </div>
                <div>
                  <span className="text-[#7A7A7A] font-[500] text-[14px] font-[Poppins] ms-1">
                    Category:
                  </span>
                  <input
                    type="text"
                    disabled
                    value={props.category}
                    className="border-2 border-primaryLightest px-3 py-2 rounded-xl mt-1 text-[14px] w-full bg-transparent focus:bg-[#f6f6f6] disabled:bg-primaryLightest focus:border-primaryLight hover:border-primaryLighter outline-none transition-all ease-in-out duration-300 placeholder:text-[#b7b7b7] uppercase"
                  />
                </div>
                {categoryData.subjects.map((subject, index) => (
                  <div>
                    <span className="text-[#7A7A7A] font-[500] text-[14px] font-[Poppins] ms-1">
                      No of{" "}
                      <span className="capitalize">
                        {subject.name.split("-").splice(1).join(" ")}
                      </span>{" "}
                      Qstn:
                    </span>
                    <input
                      type="number"
                      placeholder="Enter 0-100"
                      onChange={(e) => {
                        const temp = customData.questions;
                        temp[index].number = parseInt(e.target.value);
                        setCustomData({ ...customData, questions: temp });
                      }}
                      className="border-2 border-primaryLightest px-3 py-2 rounded-xl mt-1 text-[14px] w-full bg-transparent focus:bg-[#f6f6f6] disabled:bg-primaryLightest focus:border-primaryLight hover:border-primaryLighter outline-none transition-all ease-in-out duration-300 placeholder:text-[#b7b7b7]"
                    />
                  </div>
                ))}
              </div>
              <div className="mt-8 w-full bg-[rgba(234,90,12,0.125)] text-orange-600 p-3 rounded-xl text-[12px] font-[Poppins]">
                <span className="font-[600]">Note:</span> The total number of
                questions must be greater than 25 and cannot exceed 100 !
              </div>
              <div className="flex items-center justify-center w-full mt-6 gap-6">
                <button
                  onClick={() => navigate(-1)}
                  className="w-full border-b-2 border-b-primaryLightest rounded-[20px] hover:border-b-[0px] transition-all ease-in-out duration-300"
                >
                  <div className="text-gray-500 px-5 py-3 rounded-2xl border-2 border-primaryLightest disabled:border-primaryLightest text-[14px] font-[600] flex items-center justify-center gap-3 w-full">
                    Cancel
                  </div>
                </button>
                <button
                  onClick={() => handleCreateCustomTest()}
                  disabled={loadingCreateCustomTest}
                  className="bg-primaryBlue text-gray-200 px-5 py-3 rounded-2xl border-b-[4px] border-b-blueLight disabled:bg-blueLight disabled:border-b-blueLighter hover:border-b-[0px] transition-all ease-in-out duration-300 text-[14px] font-[600] flex items-center justify-center gap-3 w-full"
                >
                  Create Test
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CreateTestModal;
