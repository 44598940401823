import { useState } from "react";

export const useGetResult = () => {
  const [resultData, setResultData] = useState(null);
  const [loadingResult, setLoadingResult] = useState(true);
  const [errorResult, setErrorResult] = useState(null);

  const getResult = async (id, type) => {
    try {
      setLoadingResult(true);
      setErrorResult(null);
      setResultData(null);

      const request = await fetch(`/api/get_result/${id}`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          type: type,
        }),
      });

      const response = await request.json();

      if (!request.ok) {
        setErrorResult(response.msg);
        setResultData(null);
        setLoadingResult(false);
      } else {
        setResultData(response);
        setErrorResult(null);
        setLoadingResult(false);
      }
    } catch (err) {
      setErrorResult(err.message);
      setLoadingResult(false);
    }
  };

  return { getResult, resultData, loadingResult, errorResult };
};
